import { Grid, Card, MenuItem } from '@mui/material'
import { FieldArray } from 'formik'
import { FormikArrayField } from '../../components/Form/FormikArrayFeild'

export const WorkOrderItems = ({
    formik,
    disableAll = false,
}: {
    formik: any
    disableAll?: boolean
}): ReactNode => {
    return (
        <Grid container item spacing={2}>
            <FieldArray name="orderAdminChs">
                {() => (
                    <Grid container item spacing={1}>
                        {formik.values?.orderAdminChs?.map(
                            (quoteItem: any, index: number) => (
                                <Grid
                                    item
                                    container
                                    key={quoteItem.id?.toString() || index}
                                >
                                    <Card variant="outlined">
                                        <Grid
                                            item
                                            container
                                            alignItems="start"
                                            spacing={2}
                                            sx={{ py: 2, px: 2 }}
                                        >
                                            <Grid item sm={12} md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="A or C"
                                                    name={`orderAdminChs[${index}].typeAorC`}
                                                    value={
                                                        quoteItem.typeAorC || ''
                                                    }
                                                    select
                                                >
                                                    <MenuItem value="A">
                                                        A
                                                    </MenuItem>
                                                    <MenuItem value="C">
                                                        C
                                                    </MenuItem>
                                                </FormikArrayField>
                                            </Grid>
                                            <Grid item sm={12} md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="L or B"
                                                    name={`orderAdminChs[${index}].typeLorB`}
                                                    value={
                                                        quoteItem.typeLorB || ''
                                                    }
                                                    select
                                                >
                                                    <MenuItem value="L">
                                                        L
                                                    </MenuItem>
                                                    <MenuItem value="B">
                                                        B
                                                    </MenuItem>
                                                </FormikArrayField>
                                            </Grid>
                                            <Grid item sm={12} md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="Quantity"
                                                    name={`orderAdminChs[${index}].quantity`}
                                                    value={quoteItem.quantity}
                                                />
                                            </Grid>
                                            <Grid item sm={12} md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="Grade"
                                                    name={`orderAdminChs[${index}].grade`}
                                                    value={quoteItem.grade}
                                                />
                                            </Grid>
                                            <Grid item sm={12} md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="Start Size"
                                                    name={`orderAdminChs[${index}].startSize`}
                                                    value={quoteItem.startSize}
                                                    type="number"
                                                />
                                            </Grid>

                                            <Grid item sm={12} md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="F.S High"
                                                    name={`orderAdminChs[${index}].finishSizeHigh`}
                                                    value={
                                                        quoteItem.finishSizeHigh
                                                    }
                                                    type="number"
                                                />
                                            </Grid>
                                            <Grid item sm={12} md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="F.S Low"
                                                    name={`orderAdminChs[${index}].finishSizeLow`}
                                                    value={
                                                        quoteItem.finishSizeLow
                                                    }
                                                    type="number"
                                                />
                                            </Grid>

                                            <Grid item md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="Feet"
                                                    name={`orderAdminChs[${index}].feet`}
                                                    value={quoteItem.feet}
                                                />
                                            </Grid>
                                            <Grid item md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="Inches"
                                                    name={`orderAdminChs[${index}].inches`}
                                                    value={quoteItem.inches}
                                                />
                                            </Grid>

                                            <Grid item sm={12} md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="RMS"
                                                    name={`orderAdminChs[${index}].rms`}
                                                    value={quoteItem.rms}
                                                    type="number"
                                                />
                                            </Grid>
                                            <Grid item sm={12} md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="Inventory ID"
                                                    name={`orderAdminChs[${index}].inventoryNo`}
                                                    value={
                                                        quoteItem.inventoryNo
                                                    }
                                                />
                                            </Grid>

                                            <Grid item sm={12} md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="Heat No"
                                                    name={`orderAdminChs[${index}].heatNo`}
                                                    value={quoteItem.heatNo}
                                                    type="string"
                                                />
                                            </Grid>
                                            <Grid item sm={12} md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="UM"
                                                    name={`orderAdminChs[${index}].um`}
                                                    value={quoteItem.um}
                                                    type="string"
                                                />
                                            </Grid>

                                            <Grid item sm={12} md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="Part No"
                                                    name={`orderAdminChs[${index}].partNumber`}
                                                    value={quoteItem.partNumber}
                                                />
                                            </Grid>
                                            <Grid item sm={12} md={2}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="Due Date"
                                                    name={`orderAdminChs[${index}].dueDate`}
                                                    value={quoteItem.dueDate}
                                                    type="string"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            )
                        )}
                    </Grid>
                )}
            </FieldArray>
        </Grid>
    )
}
