import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Avatar, Box, Grid, TextField, Typography, Theme } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { useContext, useEffect, useState } from 'react'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { useLazyQuery } from '@apollo/client'
import { auth } from '../../config/firebase-config.js'
import { IMessageContext, MessageContext } from '../../context/MessageContext'
import { queryGetUserByEmail } from '../../common/queries'
import { UserContext, IUserContext } from '../../context/UserContext'
import { APP_NAME } from '../../config/config'

const Login = (): ReactNode => {
    const { setUserData } = useContext(UserContext) as IUserContext
    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const navigate = useNavigate()
    const [email, setEmail] = useState<string>('')

    const [getDBUser, { data }] = useLazyQuery(queryGetUserByEmail)

    useEffect(() => {
        if (data && data?.getUserByEmail) {
            setUserData(data?.getUserByEmail)
            if (data?.getUserByEmail.role === 'ADMIN')
                navigate('/dashboard', { replace: true })
            if (data?.getUserByEmail.role === 'SHOP')
                navigate('/shop/work-orders', { replace: true })
            showSnackbar('Login Sucessful')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
            password: Yup.string()
                .min(8, 'Password is too short - should be 8 chars minimum.')
                .required('Password is required'),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true)
                await signInWithEmailAndPassword(
                    auth,
                    values.email,
                    values.password
                )
                await getDBUser({
                    variables: {
                        email,
                    },
                })
            } catch (error) {
                setSubmitting(false)
                showSnackbar(error.message, true)
            } finally {
                setSubmitting(false)
            }
        },
    })

    return (
        <>
            <Helmet>
                <title>Login | {APP_NAME}</title>
            </Helmet>
            <Grid
                container
                component="main"
                alignItems="center"
                justifyItems="center"
                justifyContent="center"
                textAlign="center"
                sx={{
                    height: '90vh',
                    p: (theme: Theme) => theme.spacing(2),
                }}
            >
                <Grid item xs={12} sm={6} md={5} lg={3}>
                    <Avatar
                        src="/logo.png"
                        sx={{
                            width: 100,
                            height: 100,
                            margin: (theme: Theme) =>
                                `${theme.spacing(2)} auto`,
                        }}
                    />

                    <Typography component="h1" variant="h4">
                        SIGN IN
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={formik.handleSubmit}
                        noValidate
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            error={!!formik.errors.email}
                            helperText={formik.errors.email}
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            onChange={(event) => {
                                formik.values.email = event.target.value
                                setEmail(event.target.value)
                            }}
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            error={!!formik.errors.password}
                            helperText={formik.errors.password}
                            margin="normal"
                            required
                            fullWidth
                            onChange={(event) => {
                                formik.values.password = event.target.value
                            }}
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />

                        <LoadingButton
                            loadingPosition="end"
                            type="submit"
                            size="large"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            loading={formik.isSubmitting}
                            disabled={formik.isSubmitting}
                            fullWidth
                        >
                            {formik.isSubmitting
                                ? 'Attempting Sign In'
                                : 'Sign In'}
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default Login
