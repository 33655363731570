import { Grid, Button, TextField } from '@mui/material'
import { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { queryDownloadFileFromCloud } from '../../common/queries'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const UploadImagesList = ({ imageFiles }: any): ReactNode => {
    const [cloudFile, { data: url }] = useLazyQuery(queryDownloadFileFromCloud)

    useEffect(() => {
        if (url && url?.downloadFile) {
            window.open(url?.downloadFile, '_blank')
        }
    }, [url])

    const downloadFile = async (file): Promise<void> => {
        await cloudFile({
            variables: {
                bucketFileName: file.bucketFileName,
            },
        })
    }
    return (
        <>
            <Grid item container spacing={2}>
                {imageFiles?.map((image: any, index: number) => (
                    <Grid
                        key={image.id?.toString() || index}
                        item
                        container
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item sm={12} md={2.5}>
                            <TextField
                                label="File Name"
                                name="fileName"
                                fullWidth
                                size="small"
                                value={image.fileName}
                                type="text"
                                disabled
                            />
                        </Grid>
                        <Grid item sm={12} md={7}>
                            <TextField
                                label="Description"
                                size="small"
                                multiline
                                name="description"
                                value={image.description}
                                fullWidth
                                type="text"
                                disabled
                            />
                        </Grid>

                        <Grid item sm={12} md={1}>
                            <Button
                                color="info"
                                variant="outlined"
                                size="small"
                                onClick={() => {
                                    downloadFile(image)
                                }}
                            >
                                Download
                            </Button>
                        </Grid>
                        {/* <Grid item sm={12} md={1}>
                            <Button
                                color="error"
                                variant="contained"
                                size="small"
                            >
                                Delete
                            </Button>
                        </Grid> */}
                    </Grid>
                ))}
            </Grid>
        </>
    )
}
