import { Remove, Add } from '@mui/icons-material'
import { Grid, Typography, IconButton, Button } from '@mui/material'
import { FieldArray } from 'formik'
import { FormikArrayField } from '../../components/Form/FormikArrayFeild'

export const SecondaryAddresses = ({ formik }: { formik: any }): ReactNode => {
    return (
        <Grid item container spacing={2}>
            <Grid item>
                <Typography variant="h6">Secondary Addresses</Typography>
            </Grid>
            <FieldArray name="secondaryAddresses">
                {({ remove, push }) => (
                    <>
                        {formik.values.secondaryAddresses.map(
                            (secondaryAddress: any, index: number) => (
                                <Grid
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index.toString()}
                                    item
                                    container
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Grid item xs="auto">
                                        <IconButton
                                            color="error"
                                            onClick={() => remove(index)}
                                        >
                                            <Remove />
                                        </IconButton>
                                    </Grid>

                                    <Grid item sm={12} md={4}>
                                        <FormikArrayField
                                            formik={formik}
                                            label="Name"
                                            name={`secondaryAddresses[${index}].name`}
                                            value={secondaryAddress.name}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md>
                                        <FormikArrayField
                                            formik={formik}
                                            label="Address"
                                            name={`secondaryAddresses[${index}].address`}
                                            value={secondaryAddress.address}
                                        />
                                    </Grid>
                                </Grid>
                            )
                        )}
                        <Grid item xs={12}>
                            <Button
                                startIcon={<Add />}
                                disabled={formik.isSubmitting}
                                onClick={() =>
                                    push({
                                        name: '',
                                        address: '',
                                    })
                                }
                            >
                                Add Address
                            </Button>
                        </Grid>
                    </>
                )}
            </FieldArray>
        </Grid>
    )
}
