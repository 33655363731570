import PropTypes from 'prop-types'
import { Box, Button, ListItem } from '@mui/material'
import { useLocation, Link as RouterLink } from 'react-router-dom'

interface INavItem {
    href: string
    icon: ReactNode
    title: string
}

export const NavItem = (props: INavItem): ReactNode => {
    const { href, icon, title, ...others } = props
    const router = useLocation()
    const active = href ? router.pathname === href : false

    return (
        <ListItem
            disableGutters
            sx={{
                display: 'flex',
                mb: 0.5,
                py: 0,
                px: 2,
            }}
            {...others}
        >
            <Button
                component={RouterLink}
                to={href}
                startIcon={icon}
                disableRipple
                sx={{
                    backgroundColor: active
                        ? 'rgba(255,255,255, 0.08)'
                        : undefined,
                    borderRadius: 1,
                    color: active ? 'secondary.main' : 'neutral.300',
                    fontWeight: active ? 'fontWeightBold' : undefined,
                    justifyContent: 'flex-start',
                    px: 3,
                    textAlign: 'left',
                    textTransform: 'none',
                    width: '100%',
                    '& .MuiButton-startIcon': {
                        color: active ? 'secondary.main' : 'neutral.400',
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(255,255,255, 0.08)',
                    },
                }}
            >
                <Box sx={{ flexGrow: 1 }}>{title}</Box>
            </Button>
        </ListItem>
    )
}

NavItem.propTypes = {
    href: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
}
