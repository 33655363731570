import { Grid, Button, Box, Card, Typography, TextField } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { useContext, useState } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client'
import moment from 'moment-timezone'
import { useDebouncedCallback } from 'use-debounce'
import { FormikTextField } from '../../components/Form/FormikTextField'
import { MessageContext, IMessageContext } from '../../context/MessageContext'
import { queryGetEmployeeId } from '../../common/queries'
import { MutationAddAttendance } from '../../common/Mutations'

export const AddAttendance = (): ReactNode => {
    const [getEmployeeById, { data: EmployeeData }] = useLazyQuery(
        queryGetEmployeeId
    )
    const [employeeId, setEmployeeId] = useState<string>('')
    const [addAttendance] = useMutation(MutationAddAttendance)

    const { showSnackbar } = useContext(MessageContext) as IMessageContext

    const handleAddAttendance = async (
        values,
        setSubmitting,
        resetForm
    ): Promise<boolean> => {
        try {
            setSubmitting(true)
            await addAttendance({
                variables: values,
            })

            setSubmitting(false)
            showSnackbar('Success !! Action updated')
            resetForm()
            setEmployeeId('')
            return true
        } catch (error) {
            setSubmitting(false)
            showSnackbar(error.message, true)
            return false
        }
    }

    const formikConfig = {
        enableReinitialize: true,
        initialValues: {
            employeeId,
            attendanceStatus: '',
            notes: '',
        },
        validationSchema: Yup.object({
            employeeId: Yup.string().required('required'),
        }),
    }

    const debounced = useDebouncedCallback((value) => {
        getEmployeeById({
            variables: {
                id: value,
            },
        })
    }, 800)
    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                py: 8,
                px: 4,
            }}
        >
            <Card>
                <Typography variant="h4" sx={{ my: 2, mx: 2 }}>
                    Add Attendance
                </Typography>
                <br />
                <Box>
                    <Formik
                        {...formikConfig}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            handleAddAttendance(
                                values,
                                setSubmitting,
                                resetForm
                            )
                        }}
                    >
                        {(formik) => (
                            <Form
                                onSubmit={(e) => {
                                    formik.handleSubmit(e)
                                }}
                            >
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ py: 2, px: 2 }}
                                >
                                    <Grid
                                        container
                                        spacing={2}
                                        justifyContent="center"
                                        sx={{ py: 2, px: 2 }}
                                    >
                                        <Grid item xs={6} md={2.5}>
                                            <Typography
                                                color="textSecondary"
                                                gutterBottom
                                                variant="overline"
                                                style={{
                                                    fontSize: '16px',
                                                }}
                                            >
                                                Empolyee ID
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={5}>
                                            <Field
                                                as={TextField}
                                                label="Employee ID"
                                                name="employeeId"
                                                error={Boolean(
                                                    formik.touched.employeeId &&
                                                        formik.errors.employeeId
                                                )}
                                                fullWidth
                                                helperText={
                                                    formik.touched.employeeId &&
                                                    formik.errors.employeeId
                                                }
                                                onBlur={formik.handleBlur}
                                                onChange={(event) => {
                                                    debounced(
                                                        event.target.value
                                                    )
                                                    setEmployeeId(
                                                        event.target.value
                                                    )
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        spacing={2}
                                        justifyContent="center"
                                        sx={{ py: 2, px: 2 }}
                                    >
                                        <Grid item xs={6} md={2.5}>
                                            <Typography
                                                color="textSecondary"
                                                gutterBottom
                                                variant="overline"
                                                style={{
                                                    fontSize: '16px',
                                                }}
                                            >
                                                Empolyee Name
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={5}>
                                            <TextField
                                                label="Employee Name"
                                                value={
                                                    EmployeeData?.getEmployee
                                                        ?.name || ''
                                                }
                                                fullWidth
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        spacing={2}
                                        justifyContent="center"
                                        sx={{ py: 2, px: 2 }}
                                    >
                                        <Grid item xs={6} md={2.5}>
                                            <Typography
                                                color="textSecondary"
                                                gutterBottom
                                                variant="overline"
                                                style={{
                                                    fontSize: '16px',
                                                }}
                                            >
                                                Date Time
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={5}>
                                            <TextField
                                                label="Date"
                                                value={moment().format('llll')}
                                                fullWidth
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        spacing={2}
                                        justifyContent="center"
                                        sx={{ py: 2, px: 2 }}
                                    >
                                        <Grid item xs={6} md={2.5}>
                                            <Typography
                                                color="textSecondary"
                                                gutterBottom
                                                variant="overline"
                                                style={{
                                                    fontSize: '16px',
                                                }}
                                            >
                                                Notes
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={5}>
                                            <FormikTextField
                                                formik={formik}
                                                label="Notes (optional)"
                                                name="notes"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent="center"
                                    >
                                        <Grid item xs={6} sm={4} md={3}>
                                            <Button
                                                color="success"
                                                disabled={
                                                    EmployeeData?.getEmployee
                                                        ?.attendanceStatus ===
                                                    'CHECK_IN'
                                                }
                                                size="large"
                                                variant="outlined"
                                                fullWidth
                                                onClick={() => {
                                                    formik.setFieldValue(
                                                        'attendanceStatus',
                                                        'CHECK_IN'
                                                    )
                                                    formik.handleSubmit()
                                                }}
                                            >
                                                {formik.isSubmitting
                                                    ? 'Loading'
                                                    : 'CHECK IN'}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={3}>
                                            <Button
                                                color="info"
                                                disabled={
                                                    EmployeeData?.getEmployee
                                                        ?.attendanceStatus ===
                                                        'CHECK_OUT' ||
                                                    EmployeeData?.getEmployee
                                                        ?.attendanceStatus ===
                                                        null
                                                }
                                                size="large"
                                                onClick={() => {
                                                    formik.setFieldValue(
                                                        'attendanceStatus',
                                                        'CHECK_OUT'
                                                    )
                                                    formik.handleSubmit()
                                                }}
                                                variant="contained"
                                                fullWidth
                                            >
                                                {formik.isSubmitting
                                                    ? 'Loading'
                                                    : 'CHECK OUT'}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
                <br />
            </Card>
        </Box>
    )
}
