import { Grid, Button, Box, Card, Typography, TextField } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { LoadingButton } from '@mui/lab'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useLazyQuery } from '@apollo/client'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Checkbox from '@mui/material/Checkbox'
import { useDebouncedCallback } from 'use-debounce'
import { FormikTextField } from '../../components/Form/FormikTextField'
import { MessageContext, IMessageContext } from '../../context/MessageContext'
import { queryGetOrderAdminById } from '../../common/queries'
import { MutationAddShipping } from '../../common/Mutations'

const CONTAINER_OPTIONS = [
    {
        value: 'allTogether',
        label: 'PARTIAL',
    },
    {
        value: 'packSeperately',
        label: 'COMPLETE',
    },
]

export const AddShipping = (): ReactNode => {
    const [getOrderById, { data: orderData }] = useLazyQuery(
        queryGetOrderAdminById
    )

    const [orderId, setOrderId] = useState<string>('')
    const formikConfig = {
        enableReinitialize: true,
        initialValues: {
            orderId,
            shippingCharges: 0,
            shipVia: orderData?.getOrderAdminById?.customer?.shipVia || '',
            quantity: 0,
            containerOptions: 'allTogether',
            hasCustomerBilled: false,
            notes: '',
        },
        validationSchema: Yup.object({
            orderId: Yup.string().required('required'),
        }),
    }

    const [addShipping] = useMutation(MutationAddShipping)

    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const navigate = useNavigate()

    const createShippingSubmit = async (
        values,
        setSubmitting
    ): Promise<boolean> => {
        try {
            setSubmitting(true)
            await addShipping({
                variables: values,
            })
            setSubmitting(false)
            showSnackbar('Success !! New Shipping Created')
            navigate('/dashboard/shipping')
            return true
        } catch (error) {
            setSubmitting(false)
            showSnackbar(error.message, true)
            return false
        }
    }
    const debounced = useDebouncedCallback((value) => {
        getOrderById({
            variables: {
                id: value,
            },
        })
    }, 800)

    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                py: 8,
                px: 4,
            }}
        >
            <Card>
                <Typography variant="h4" sx={{ my: 2, mx: 2 }}>
                    Create Shipping
                </Typography>
                <Box>
                    <Formik
                        {...formikConfig}
                        onSubmit={(values, { setSubmitting }) => {
                            createShippingSubmit(values, setSubmitting)
                        }}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ py: 2, px: 2 }}
                                >
                                    <Grid item xs={12} sm={6} md={2}>
                                        <Field
                                            error={Boolean(
                                                formik.touched.orderId &&
                                                    formik.errors.orderId
                                            )}
                                            helperText={
                                                formik.touched.orderId &&
                                                formik.errors.orderId
                                            }
                                            as={TextField}
                                            label="Order ID"
                                            name="orderId"
                                            fullWidth
                                            onChange={(event) => {
                                                debounced(event.target.value)
                                                setOrderId(event.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            fullWidth
                                            label="Customer Name"
                                            value={
                                                orderData?.getOrderAdminById
                                                    ?.customer?.companyName ||
                                                ''
                                            }
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                        <TextField
                                            fullWidth
                                            label="PO Number"
                                            value={
                                                orderData?.getOrderAdminById
                                                    ?.pONumber || ''
                                            }
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3.5}>
                                        <TextField
                                            fullWidth
                                            label="Company Address"
                                            value={
                                                orderData?.getOrderAdminById
                                                    ?.shipToCustomerAddress ||
                                                ''
                                            }
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={1.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Shipping Charges"
                                            name="shippingCharges"
                                            type="number"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Ship Via"
                                            name="shipVia"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Quantity"
                                            name="quantity"
                                            type="number"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormLabel>Container Options</FormLabel>
                                        <Field
                                            as={RadioGroup}
                                            name="containerOptions"
                                            defaultValue={
                                                formik.values.containerOptions
                                            }
                                        >
                                            {CONTAINER_OPTIONS.map((item) => (
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            value={item.value}
                                                        />
                                                    }
                                                    label={item.label}
                                                />
                                            ))}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <FormControlLabel
                                            control={
                                                <Field
                                                    as={Checkbox}
                                                    name="hasCustomerBilled"
                                                    defaultChecked={
                                                        formik.values
                                                            .hasCustomerBilled
                                                    }
                                                />
                                            }
                                            label="Billed To Customer"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Notes"
                                            name="notes"
                                            multiline
                                            rows="3"
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={6} sm={4} md={3}>
                                            <Button
                                                color="error"
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                variant="outlined"
                                                fullWidth
                                                onClick={() => {
                                                    formik.resetForm()
                                                    navigate(
                                                        '/dashboard/shipping'
                                                    )
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={3}>
                                            <LoadingButton
                                                color="success"
                                                loadingPosition="end"
                                                loading={formik.isSubmitting}
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                fullWidth
                                            >
                                                {formik.isSubmitting
                                                    ? 'Submitting'
                                                    : 'Submit'}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Card>
        </Box>
    )
}
