import { Grid, Button, Box, Card, Typography, TextField } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { LoadingButton } from '@mui/lab'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useLazyQuery } from '@apollo/client'
import moment from 'moment-timezone'
import { useDebouncedCallback } from 'use-debounce'
import { FormikTextField } from '../../components/Form/FormikTextField'
import { MessageContext, IMessageContext } from '../../context/MessageContext'
import { OrderAdminItems } from './OrderAdminItems'
import { queryGetCustomerById } from '../../common/queries'
import { MutationAddOrderAdmin } from '../../common/Mutations'
import { NumberFormatter } from '../../utils/NumberFormatter'
import { FormikDateField } from '../../components/Form/FormikDateField'

export const CreateOrderAdmin = (): ReactNode => {
    const [getCustomerById, { data: customerData }] = useLazyQuery(
        queryGetCustomerById
    )
    const [customerId, setCustomerId] = useState<string>('')

    const [addOrderAdmin] = useMutation(MutationAddOrderAdmin)

    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const navigate = useNavigate()

    const handleOrderAdminSubmit = async (
        values,
        setSubmitting
    ): Promise<boolean> => {
        try {
            const gTotal = values.orderAdminChs.reduce(
                (n, item) => n + item.quantity * item.unitPrice || 0,
                0
            )
            values.grandTotal = gTotal
            values.id = Number(values.id || 0)
            values.shipDate = moment(values.shipDate).format('DD-MM-YYYY') // TODO: send date with timezone

            values.orderAdminChs.forEach((item, index, array) => {
                array[index].dueDate = moment(array[index].dueDate).format(
                    'DD-MM-YYYY'
                )

                if (item.typeAorC === 'C') {
                    array[index].inventoryNo = array[index].inventory
                    delete array[index].inventory
                }
            })
            setSubmitting(true)
            await addOrderAdmin({
                variables: values,
            })

            setSubmitting(false)
            showSnackbar('Success !! New Order Created')
            navigate('/dashboard/orders-admin')

            return true
        } catch (error) {
            setSubmitting(false)
            showSnackbar(error.message, true)
            return false
        }
    }

    const formikConfig = {
        enableReinitialize: true,
        initialValues: {
            customerId,
            shipToCustomerName: customerData?.getCustomer?.companyName || '',
            shipToCustomerAddress:
                customerData?.getCustomer?.primaryAddress || '',
            pONumber: '',
            buyerName: '',
            shipDate: new Date(),
            invoiceNo: '',
            orderAdminChs: [{}] as IOrderAdminChs[],
            // imageFile: [],
        },
        validationSchema: Yup.object({
            customerId: Yup.string().required('required'),

            orderAdminChs: Yup.array().of(
                Yup.object({
                    quantity: Yup.number().required('required'),
                    finishSizeHigh: Yup.string().required('required'),
                    finishSizeLow: Yup.string().required('required'),
                    unitPrice: Yup.string().required('required'),
                    grade: Yup.string().required('required'),
                    feet: Yup.string().required('required'),
                })
            ),
        }),
    }

    const debounced = useDebouncedCallback((value) => {
        getCustomerById({
            variables: {
                id: value,
            },
        })
    }, 800)

    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                py: 8,
                px: 4,
            }}
        >
            <Card>
                <Typography variant="h4" sx={{ my: 2, mx: 2 }}>
                    Create Order
                </Typography>
                <Box>
                    <Formik
                        {...formikConfig}
                        onSubmit={(values, { setSubmitting }) => {
                            handleOrderAdminSubmit(values, setSubmitting)
                        }}
                    >
                        {(formik) => (
                            <Form
                                onSubmit={(e) => {
                                    formik.handleSubmit(e)
                                }}
                            >
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ py: 2, px: 2 }}
                                >
                                    <Grid item xs={12} sm={6} md={1.5}>
                                        <Field
                                            as={TextField}
                                            label="Customer ID"
                                            name="customerId"
                                            error={Boolean(
                                                formik.touched.customerId &&
                                                    formik.errors.customerId
                                            )}
                                            fullWidth
                                            helperText={
                                                formik.touched.customerId &&
                                                formik.errors.customerId
                                            }
                                            onBlur={formik.handleBlur}
                                            onChange={(event) => {
                                                debounced(event.target.value)
                                                setCustomerId(
                                                    event.target.value
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2.5}>
                                        <TextField
                                            label="Customer Name"
                                            value={
                                                customerData?.getCustomer
                                                    ?.companyName || ''
                                            }
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            label="Customer Address"
                                            value={
                                                customerData?.getCustomer
                                                    ?.primaryAddress || ''
                                            }
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Ship To Customer Name"
                                            name="shipToCustomerName"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Ship To Customer Address"
                                            name="shipToCustomerAddress"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <FormikTextField
                                            formik={formik}
                                            label="PO Number"
                                            name="pONumber"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Buyer Name"
                                            name="buyerName"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <FormikDateField
                                            formik={formik}
                                            label="Ship Date"
                                            name="shipDate"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Invoice Number"
                                            name="invoiceNo"
                                        />
                                    </Grid>

                                    <OrderAdminItems formik={formik} />

                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                fullWidth
                                                label="Grand Total"
                                                name="grandTotal"
                                                disabled
                                                value={NumberFormatter(
                                                    formik.values.orderAdminChs.reduce(
                                                        (n, item) =>
                                                            n +
                                                                item.quantity *
                                                                    item.unitPrice ||
                                                            0,
                                                        0
                                                    )
                                                )}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={6} sm={4} md={3}>
                                            <Button
                                                color="error"
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                variant="outlined"
                                                fullWidth
                                                onClick={() => {
                                                    formik.resetForm()
                                                    navigate(
                                                        '/dashboard/orders-admin'
                                                    )
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={3}>
                                            <LoadingButton
                                                color="success"
                                                loadingPosition="end"
                                                loading={formik.isSubmitting}
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                fullWidth
                                            >
                                                {formik.isSubmitting
                                                    ? 'Submitting'
                                                    : 'Submit'}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Card>
        </Box>
    )
}
