import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material'
import CountUp from 'react-countup'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const TopCard = (props): ReactNode => {
    const { heading, value, icon, iconBgColor } = props
    return (
        <Card sx={{ height: '100%' }}>
            <CardContent>
                <Grid
                    container
                    spacing={3}
                    sx={{ justifyContent: 'space-between' }}
                >
                    <Grid item>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="overline"
                        >
                            {heading}
                        </Typography>
                        <Typography color="textPrimary" variant="h2">
                            <CountUp duration={2} end={value} start={0} />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Avatar
                            sx={{
                                backgroundColor: iconBgColor,
                                height: 56,
                                width: 56,
                            }}
                        >
                            {icon}
                        </Avatar>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
