import { Grid, Button, Box, Card, Typography, TextField } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { LoadingButton } from '@mui/lab'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useLazyQuery } from '@apollo/client'
import { useDebouncedCallback } from 'use-debounce'
import { FormikTextField } from '../../components/Form/FormikTextField'
import { MessageContext, IMessageContext } from '../../context/MessageContext'
import { PurchaseItems } from './PurchaseItems'
import { queryGetVendorById } from '../../common/queries'
import { MutationAddPurchaseOrder } from '../../common/Mutations'
import { NumberFormatter } from '../../utils/NumberFormatter'

const formikConfig = {
    initialValues: {
        vendor: '',
        contactName: '',
        products: [{}] as IPurchaseOrderProduct[],
    },
    validationSchema: Yup.object({
        vendor: Yup.string().required('required'),
        products: Yup.array().of(
            Yup.object().shape({
                quantity: Yup.string().required('required'),
                unitOfMeasure: Yup.string().required('required'),
                unitPrice: Yup.string().required('required'),
            })
        ),
    }),
}

export const CreatePurchaseOrder = (): ReactNode => {
    const [getVendorById, { data: vendorData }] = useLazyQuery(
        queryGetVendorById
    )

    const [addPurchaseOrder] = useMutation(MutationAddPurchaseOrder)

    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const navigate = useNavigate()

    const createPurchaseOrderSumit = async (
        values,
        setSubmitting
    ): Promise<boolean> => {
        try {
            setSubmitting(true)
            await addPurchaseOrder({
                variables: values,
            })
            setSubmitting(false)
            showSnackbar('Success !! New Purchase Order Created')
            navigate('/dashboard/purchase-orders')
            return true
        } catch (error) {
            setSubmitting(false)
            showSnackbar(error.message, true)
            return false
        }
    }

    const debounced = useDebouncedCallback((value) => {
        getVendorById({
            variables: {
                id: value,
            },
        })
    }, 800)

    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                py: 8,
                px: 4,
            }}
        >
            <Card>
                <Typography variant="h4" sx={{ my: 2, mx: 2 }}>
                    Create Purchase Order
                </Typography>
                <Box>
                    <Formik
                        {...formikConfig}
                        onSubmit={(values, { setSubmitting }) => {
                            createPurchaseOrderSumit(values, setSubmitting)
                        }}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ py: 2, px: 2 }}
                                >
                                    <Grid item sm={6} md={1.5}>
                                        <Field
                                            error={Boolean(
                                                formik.touched.vendor &&
                                                    formik.errors.vendor
                                            )}
                                            helperText={
                                                formik.touched.vendor &&
                                                formik.errors.vendor
                                            }
                                            as={TextField}
                                            label="Vendor ID"
                                            name="vendor"
                                            fullWidth
                                            onChange={(event) => {
                                                formik.setFieldValue(
                                                    'vendor',
                                                    event.target.value
                                                )

                                                debounced(event.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={6} md={3}>
                                        <TextField
                                            fullWidth
                                            label="Company/Vendor Name"
                                            value={
                                                vendorData?.getVendor
                                                    ?.companyName
                                                    ? vendorData?.getVendor
                                                          ?.companyName
                                                    : ''
                                            }
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item sm={6} md={3}>
                                        <TextField
                                            fullWidth
                                            label="Company/Vendor Address"
                                            value={
                                                vendorData?.getVendor
                                                    ?.primaryAddress
                                                    ? vendorData?.getVendor
                                                          ?.primaryAddress
                                                    : ''
                                            }
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item sm={6} md={3}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Contact Name"
                                            name="contactName"
                                        />
                                    </Grid>

                                    <PurchaseItems formik={formik} />
                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent="flex-end"
                                        alignItems="center"
                                    >
                                        {/* <Grid item xs={4} /> */}
                                        <Grid item>
                                            <Typography variant="h5">
                                                Total
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9} sm={9} md={3}>
                                            <TextField
                                                fullWidth
                                                disabled
                                                value={NumberFormatter(
                                                    formik.values.products.reduce(
                                                        (n, item) =>
                                                            n +
                                                                item.quantity *
                                                                    item.unitPrice ||
                                                            0,
                                                        0
                                                    )
                                                )}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={6} sm={4} md={3}>
                                            <Button
                                                color="error"
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                variant="outlined"
                                                fullWidth
                                                onClick={() => {
                                                    formik.resetForm()
                                                    navigate(
                                                        '/dashboard/purchase-orders'
                                                    )
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={3}>
                                            <LoadingButton
                                                color="success"
                                                loadingPosition="end"
                                                loading={formik.isSubmitting}
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                fullWidth
                                            >
                                                {formik.isSubmitting
                                                    ? 'Submitting'
                                                    : 'Submit'}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Card>
        </Box>
    )
}
