/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { format, formatDistance } from 'date-fns'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    Container,
    TableHead,
    TableRow,
    Typography,
    Button,
    ButtonGroup,
} from '@mui/material'
import { useQuery, useMutation } from '@apollo/client'
import React, { useEffect, useState, useMemo, useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { SeverityPill } from '../../components/severity-pill.js'
import { queryGetCurrDayAttendance } from '../../common/queries'
import { APP_NAME } from '../../config/config'
import { MutationAddAttendance } from '../../common/Mutations'
import { MessageContext, IMessageContext } from '../../context/MessageContext'

export const CheckAttendance = (): ReactNode => {
    const { data: allAttendance, loading, refetch } = useQuery(
        queryGetCurrDayAttendance
    )
    const { showSnackbar } = useContext(MessageContext) as IMessageContext

    const [data, setData] = useState<IAttendance[]>([])
    const [addAttendance] = useMutation(MutationAddAttendance)

    const onAction = async (item, actionType): Promise<void> => {
        try {
            await addAttendance({
                variables: {
                    employeeId: item.id,
                    attendanceStatus: actionType,
                },
            })
            refetch()
            showSnackbar('Success !! Action updated')
        } catch (error) {
            showSnackbar(error.message, true)
        }
    }

    useEffect(() => {
        if (
            !loading &&
            allAttendance &&
            allAttendance?.getCurrentDayAttendance
        ) {
            setData(allAttendance?.getCurrentDayAttendance)
        }
    }, [loading, allAttendance])
    return (
        <>
            <Helmet>
                <title>Attendance | {APP_NAME}</title>
            </Helmet>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8,
                }}
            >
                <Container maxWidth={false}>
                    <Typography sx={{ m: 1 }} variant="h4">
                        Today Attendance
                    </Typography>

                    {useMemo(
                        () => (
                            <Box sx={{ mt: 3 }}>
                                <Card>
                                    <PerfectScrollbar>
                                        <Box>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            Employee Id
                                                        </TableCell>
                                                        <TableCell>
                                                            Name
                                                        </TableCell>
                                                        <TableCell>
                                                            Check-In
                                                        </TableCell>
                                                        <TableCell>
                                                            Check-Out
                                                        </TableCell>
                                                        <TableCell>
                                                            Total Hours
                                                        </TableCell>
                                                        <TableCell>
                                                            Status
                                                        </TableCell>
                                                        <TableCell>
                                                            Action
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {data?.map((item) => {
                                                        let checkIn = '-'
                                                        let checkOut = '-'
                                                        let totalHours = '-'
                                                        let status = 'ABSENT'
                                                        let color = 'error'
                                                        if (
                                                            item.attendanceStatus ===
                                                            'CHECK_IN'
                                                        ) {
                                                            color = 'info'
                                                            status = 'PRESENT'
                                                        }
                                                        if (
                                                            item.attendanceStatus ===
                                                            'CHECK_OUT'
                                                        ) {
                                                            color = 'success'
                                                            status = 'CHECK OUT'
                                                        }

                                                        if (item.attendance) {
                                                            const CheckInTime = item.attendance?.find(
                                                                (
                                                                    attendance
                                                                ) => {
                                                                    return (
                                                                        attendance.attendanceStatus ===
                                                                        'CHECK_IN'
                                                                    )
                                                                }
                                                            )
                                                            const CheckOutTime = item.attendance?.find(
                                                                (
                                                                    attendance
                                                                ) => {
                                                                    return (
                                                                        attendance.attendanceStatus ===
                                                                        'CHECK_OUT'
                                                                    )
                                                                }
                                                            )
                                                            if (CheckInTime)
                                                                checkIn = format(
                                                                    Number(
                                                                        CheckInTime?.createdAt
                                                                    ),
                                                                    'hh : mm  a'
                                                                )
                                                            if (CheckOutTime)
                                                                checkOut = format(
                                                                    Number(
                                                                        CheckOutTime?.createdAt
                                                                    ),
                                                                    'hh : mm  a'
                                                                )
                                                            if (
                                                                CheckInTime &&
                                                                CheckOutTime
                                                            ) {
                                                                totalHours = formatDistance(
                                                                    Number(
                                                                        CheckInTime?.createdAt
                                                                    ),
                                                                    Number(
                                                                        CheckOutTime?.createdAt
                                                                    ),
                                                                    {
                                                                        addSuffix: false,
                                                                    }
                                                                )
                                                            }
                                                        }
                                                        return (
                                                            <TableRow
                                                                hover
                                                                key={item.id}
                                                            >
                                                                <TableCell>
                                                                    {item?.id}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item?.name}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {checkIn}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {checkOut}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {totalHours}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <SeverityPill
                                                                        color={
                                                                            color
                                                                        }
                                                                    >
                                                                        {status}
                                                                    </SeverityPill>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <ButtonGroup
                                                                        color="secondary"
                                                                        aria-label="small secondary button group"
                                                                    >
                                                                        <Button
                                                                            onClick={() => {
                                                                                onAction(
                                                                                    item,
                                                                                    'CHECK_IN'
                                                                                )
                                                                            }}
                                                                            disabled={
                                                                                status ===
                                                                                'PRESENT'
                                                                            }
                                                                        >
                                                                            Check
                                                                            In
                                                                        </Button>

                                                                        <Button
                                                                            onClick={() => {
                                                                                onAction(
                                                                                    item,
                                                                                    'CHECK_OUT'
                                                                                )
                                                                            }}
                                                                            disabled={
                                                                                status ===
                                                                                    'ABSENT' ||
                                                                                status ===
                                                                                    'CHECK OUT'
                                                                            }
                                                                        >
                                                                            Check
                                                                            Out
                                                                        </Button>
                                                                    </ButtonGroup>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    </PerfectScrollbar>
                                </Card>
                            </Box>
                        ),
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                        [data]
                    )}
                </Container>
            </Box>
        </>
    )
}
