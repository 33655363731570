import {
    Box,
    Button,
    Container,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { useMemo, useState, useEffect, useContext } from 'react'
import Add from '@mui/icons-material/Add'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import Autocomplete from '@mui/material/Autocomplete'
import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts'
import { DynamicTable } from '../../components/Table/DynamicTable'
import { TableToolbar } from '../../components/Table/TableToolbar'
import { APP_NAME } from '../../config/config'
import { applyFilter } from '../../utils/searchHelper'
import { DeleteModal } from '../../components/Modal/DeleteModal'
import { queryGetAllQuotations } from '../../common/queries'
import {
    MutationDeleteQuotationById,
    MutationSendEmail,
} from '../../common/Mutations'
import { IMessageContext, MessageContext } from '../../context/MessageContext'
import { generatePDFContent } from '../../pdfs/QuotationPDF'
import { generatePDF } from '../../pdfs/pdf'

pdfMake.vfs = pdfFonts.pdfMake.vfs

const columns: IColumn[] = [
    { name: 'Quotation #', key: 'id' },
    { name: 'Contact Name', key: 'contactName' },
    { name: 'Lead Time', key: 'leadTime' },
    { name: 'Customer Name', key: 'customer.companyName' },
    { name: 'Total Price', key: 'grandTotal' },
    { name: 'Quoted By', key: 'quotedBy.name' },
    {
        name: 'Actions',
        key: 'action',
        download: true,
        downloadCallback(item: IQuotation) {
            generatePDF(generatePDFContent(item), {
                title: `Quotation ${item.id}`,
            })
        },
        showEmail: true,
    },
]
const validateEmail = (email): boolean => {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
}

export const Quotations = (): ReactNode => {
    const { data: allQuotations, loading, refetch } = useQuery(
        queryGetAllQuotations
    )
    const [deleteQuotationById] = useMutation(MutationDeleteQuotationById)
    const [sendEmail] = useMutation(MutationSendEmail)
    const navigate = useNavigate()

    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const [isLoading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<IQuotation[]>([])

    const [deleteQuotation, setDeleteQuotation] = useState<IQuotation | null>(
        null
    )
    const [deleting, setDeleting] = useState<boolean>(false)
    const [searchText, setSearchText] = useState('')

    const [emailSending, setEmailSending] = useState<boolean>(false)
    const [quoteEmail, setQuoteEmail] = useState<IQuotation | null>(null)
    const [customerEmail, setCustomerEmail] = useState<string>('')

    useEffect(() => {
        if (quoteEmail?.customer?.secondaryContacts[0]?.contact) {
            setCustomerEmail(
                quoteEmail?.customer?.secondaryContacts[0]?.contact.toLowerCase()
            )
        }
    }, [quoteEmail])

    useEffect(() => {
        setLoading(loading)
        // refetch()
        if (!loading && allQuotations && allQuotations?.getAllQuotations) {
            setData(allQuotations?.getAllQuotations)
        }
    }, [allQuotations, loading, refetch])

    const handleSearchTextChanged = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ): void => {
        setSearchText(event.target.value)
    }

    const [heading, setheading] = useState('QUOTATION')

    const handleDeleteQuotation = async (): Promise<boolean> => {
        try {
            setLoading(true)
            setDeleting(true)
            await deleteQuotationById({
                variables: {
                    id: deleteQuotation.id,
                },
            })
            showSnackbar('Success !! Quotation Deleted ')

            return true
        } catch (error) {
            showSnackbar(error.messag, true)
            return false
        } finally {
            setDeleting(false)
            setDeleteQuotation(null)
            refetch()
            setLoading(false)
        }
    }

    columns[columns.length - 1].emailCallback = (item) => {
        setQuoteEmail(item)
    }
    const SendEmail = async (): Promise<void> => {
        try {
            if (!validateEmail(customerEmail)) {
                showSnackbar('Please enter a valid email address..', true)
            } else {
                const content = generatePDFContent(quoteEmail, heading)
                const pdfDocGenerator = pdfMake.createPdf({
                    pageMargins: [20, 20, 20, 110],
                    pageSize: 'A4',
                    content,
                    styles: {
                        mainHeading: {
                            fontSize: 22,
                            bold: true,
                        },
                        subHeading: {
                            fontSize: 12,
                            bold: true,
                        },
                        center: {
                            alignment: 'center',
                        },
                        right: {
                            alignment: 'right',
                        },
                    },

                    defaultStyle: {
                        fontSize: 9,
                        bold: true,
                    },
                })
                pdfDocGenerator.getBase64(async (file) => {
                    setEmailSending(true)
                    await sendEmail({
                        variables: {
                            email: customerEmail,
                            contact_name: quoteEmail?.contactName,
                            sales_rep: quoteEmail?.quotedBy?.name,
                            quotation_number: quoteEmail?.id,
                            file,
                        },
                    })
                    setCustomerEmail('')
                    setQuoteEmail(null)
                    setEmailSending(false)
                    showSnackbar('Email send successfully...')
                })
            }
        } catch (error) {
            setCustomerEmail('')
            setQuoteEmail(null)
            showSnackbar(error.messag, true)
        }
    }

    const filteredData = applyFilter(data, searchText, [
        'id',
        'customer.companyName',
    ])
    return (
        <>
            <Helmet>
                <title>Quotations | {APP_NAME}</title>
            </Helmet>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8,
                }}
            >
                <Container maxWidth={false}>
                    <TableToolbar
                        title="Quotations"
                        searchText={searchText}
                        onSearchTextChanged={handleSearchTextChanged}
                        placeHolder="Search Quotations"
                    >
                        <Button
                            component={RouterLink}
                            to="/dashboard/quotation"
                            startIcon={<Add />}
                            color="primary"
                            variant="contained"
                            size="large"
                        >
                            Add Quotation
                        </Button>
                    </TableToolbar>
                    {useMemo(
                        () => (
                            <Box sx={{ mt: 3 }}>
                                <DynamicTable<IQuotation>
                                    isLoading={isLoading}
                                    columns={columns}
                                    data={filteredData}
                                    onRowClick={(item) => {
                                        navigate(
                                            `/dashboard/quotation/${item.id}`,
                                            {
                                                state: {
                                                    quotation: item,
                                                },
                                            }
                                        )
                                    }}
                                    onRowDelete={(item) =>
                                        setDeleteQuotation(item)
                                    }
                                />
                            </Box>
                        ),
                        [filteredData, isLoading, navigate]
                    )}
                </Container>
                <DeleteModal
                    open={Boolean(deleteQuotation)}
                    onClose={() => setDeleteQuotation(null)}
                    onDeleteConfirm={handleDeleteQuotation}
                    dialogTitle="Delete Quotation"
                    deleting={deleting}
                >
                    Are You sure you want to delete this quotation?
                    <br />
                    ID: {deleteQuotation?.id}
                    <br />
                    Contact Name: {deleteQuotation?.contactName}
                </DeleteModal>

                <DeleteModal
                    open={Boolean(quoteEmail)}
                    onClose={() => {
                        setQuoteEmail(null)
                        setCustomerEmail('')
                    }}
                    onDeleteConfirm={SendEmail}
                    dialogTitle="Send Email"
                    deleting={emailSending}
                    emailSending
                >
                    Are you sure you want to send this quotation?
                    <br />
                    <br />
                    Quotation No:<b> {quoteEmail?.id}</b>
                    <br />
                    Contact Person: <b>{quoteEmail?.contactName}</b>
                    <br />
                    <br />
                    <Autocomplete
                        autoComplete
                        id="free-solo-demo"
                        freeSolo
                        fullWidth
                        value={
                            quoteEmail?.customer?.secondaryContacts[0]?.contact.toLowerCase() ||
                            ''
                        }
                        options={quoteEmail?.customer?.secondaryContacts.map(
                            (option) => option?.contact?.toLocaleLowerCase()
                        )}
                        renderInput={(params) => (
                            <TextField
                                onChange={(e) => {
                                    setCustomerEmail(e.target.value)
                                }}
                                {...params}
                                label="Email"
                            />
                        )}
                        onChange={(event: any, newValue: any) => {
                            setCustomerEmail(newValue)
                        }}
                    />
                    <br />
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                            Select Email Header
                        </FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="QUOTATION"
                            name="radio-buttons-group"
                            onChange={(e) => {
                                setheading(e.target.value)
                            }}
                        >
                            <FormControlLabel
                                value="QUOTATION"
                                control={<Radio />}
                                label="QUOTATION"
                            />
                            <FormControlLabel
                                value="ORDER CONFIRMATION"
                                control={<Radio />}
                                label="ORDER CONFIRMATION"
                            />
                        </RadioGroup>
                    </FormControl>
                </DeleteModal>
            </Box>
        </>
    )
}
