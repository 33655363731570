/* eslint-disable  */
import { Line } from 'react-chartjs-2'
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    useTheme,
} from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import {  useNavigate } from 'react-router-dom'

export const LineChart = ({dataSet,label}) => {
    const navigate = useNavigate()
    const theme = useTheme()

    const data = {
        datasets: [
            // {
            //     borderColor:'rgb(53, 162, 235)',
            //     backgroundColor: 'rgba(53, 162, 235, 0.5',   
            //     categoryPercentage: 2,
            //     data: [25, 10, 52, 60, 3, 95, 3],
            //     label: 'Orders',
            // },
            {
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',

                categoryPercentage: 0.5,
                data: dataSet,
                label: 'Quotations',
            },
        ],
        labels: label
    }

    const options = {
        animation: true,
        // cornerRadius: 20,
        layout: { padding: 0 },
        legend: { display: false },
        maintainAspectRatio: false,
        responsive: true,
        xAxes: [
            {
                ticks: {
                    fontColor: theme.palette.text.secondary,
                },
                gridLines: {
                    display: false,
                    drawBorder: false,
                },
            },
        ],
        yAxes: [
            {
                ticks: {
                    fontColor: theme.palette.text.secondary,
                    beginAtZero: true,
                    min: 0,
                },
                gridLines: {
                    borderDash: [2],
                    borderDashOffset: [2],
                    color: theme.palette.divider,
                    drawBorder: false,
                    zeroLineBorderDash: [2],
                    zeroLineBorderDashOffset: [2],
                    zeroLineColor: theme.palette.divider,
                },
            },
        ],
        tooltips: {
            backgroundColor: theme.palette.background.paper,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            enabled: true,
            footerFontColor: theme.palette.text.secondary,
            intersect: false,
            mode: 'index',
            titleFontColor: theme.palette.text.primary,
        },
    }

    return (
        <Card >
            <CardHeader
                action={
                    <Button
                    color="primary"
                    endIcon={<ArrowRightIcon fontSize="small" />}
                    size="small"
                    onClick={()=>{
                        navigate('/dashboard/quotations')
                    }}
                >
                    Quotations
                </Button>
                }
                title="Quotations"
            />
            <Divider />
            <CardContent>
                <Box
                    sx={{
                        height: 300,
                        position: 'relative',
                    }}
                >
                    <Line data={data} options={options} />
                </Box>
            </CardContent>
            <Divider />
        </Card>
    )
}
