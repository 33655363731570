import { Box, Button, Chip, Container, Stack } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { useMemo, useState, useEffect, useContext } from 'react'
import Add from '@mui/icons-material/Add'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import moment from 'moment-timezone'
import { DynamicTable } from '../../components/Table/DynamicTable'
import { TableToolbar } from '../../components/Table/TableToolbar'
import { APP_NAME } from '../../config/config'
import { applySortFilter } from '../../utils/searchHelper'
import { DeleteModal } from '../../components/Modal/DeleteModal'
import {
    queryGetAllOrdersAdmin,
    queryGetAllProducts,
} from '../../common/queries'
import { MutationDeleteOrderAdminById } from '../../common/Mutations'
import { IMessageContext, MessageContext } from '../../context/MessageContext'
import { ChipColor } from '../../common/ChipColors'
import { generatePDF } from '../../pdfs/pdf'
import { generatePDFContent } from '../../pdfs/WorkOrderPDF'

const columns = [
    { name: 'Order ID', key: 'id' },
    { name: 'Customer ID', key: 'customer.id' },
    { name: 'Shipping Address', key: 'shipToCustomerAddress' },
    { name: 'Shipping Date', key: 'shipDate' },
    { name: 'Invoice #', key: 'invoiceNo' },
    {
        name: 'Status',
        key: 'status',
        component: (item: IOrderAdmin) => {
            const shipDate = moment(item.shipDate, 'DD-MM-YYYY')
            const diff = shipDate.diff(moment(), 'days')

            let severityColor
            if (diff < 0) severityColor = 'red'
            else if (diff <= 2) severityColor = 'orange'
            return (
                <Stack direction="row" spacing={1}>
                    <Chip
                        sx={{ ...ChipColor[item.status], fontWeight: 600 }}
                        label={item.status}
                    />
                    {severityColor &&
                        item.status !== 'READY TO SHIP' &&
                        item.status !== 'IN SHIPPING' &&
                        item.status !== 'COMPLETED' && (
                            <Chip
                                sx={{
                                    fontWeight: 600,
                                    backgroundColor: severityColor,
                                    color: 'white',
                                }}
                                label={diff < 0 ? 'LATE' : 'DUE SOON'}
                            />
                        )}
                </Stack>
            )
        },
    },
    { name: 'Total', key: 'grandTotal' },
    {
        name: 'Actions',
        key: 'action',
        download: true,
        downloadCallback(item: IOrderAdmin) {
            generatePDF(
                generatePDFContent(item),
                {
                    title: `Work Order ${item.id}`,
                }
                // {
                //     pageSize: {
                //         width: 8.3 * 72,
                //         height: 450,
                //     },
                // }
            )
        },
    },
]

export const OrdersAdmin = (): ReactNode => {
    const { data: allAdminOrders, loading, refetch } = useQuery(
        queryGetAllOrdersAdmin
    )
    const [deleteOrderAdminById] = useMutation(MutationDeleteOrderAdminById)
    const navigate = useNavigate()

    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const [isLoading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<IOrderAdmin[]>([])
    const [dataLoaded, setDataLoaded] = useState<string | null>(null)

    const [
        deleteOrderAdmin,
        setDeleteOrderAdmin,
    ] = useState<IOrderAdmin | null>(null)
    const [deleting, setDeleting] = useState<boolean>(false)
    const [searchText, setSearchText] = useState('')

    useEffect(() => {
        setLoading(loading)
        if (!loading && allAdminOrders && allAdminOrders?.getAllOrderAdmins) {
            setData(allAdminOrders?.getAllOrderAdmins)
            setDataLoaded('')
        }
    }, [allAdminOrders, loading, refetch])

    const { data: allProducts, loading: gradesLoading } = useQuery(
        queryGetAllProducts
    )
    const [grades, setGrades] = useState<any>({})

    useEffect(() => {
        if (
            !gradesLoading &&
            allProducts &&
            allProducts?.getAllProducts &&
            data.length > 0
        ) {
            let tempGrades = {}
            if (Object.keys(grades).length === 0) {
                // eslint-disable-next-line array-callback-return
                allProducts?.getAllProducts.forEach((prod): void => {
                    // eslint-disable-next-line array-callback-return
                    prod?.grades.forEach((grade): void => {
                        tempGrades[grade.name] = {
                            label: grade.name,
                            product: prod.name,
                            unitWeight: prod.unitWeight,
                        }
                    })
                })
                setGrades(tempGrades)
            } else {
                tempGrades = grades
            }

            const temp = JSON.parse(JSON.stringify(data))
            for (let i = 0; i < temp.length; i += 1) {
                const orders = temp[i]
                for (let j = 0; j < orders.orderAdminChs.length; j += 1) {
                    orders.orderAdminChs[j].grade = {
                        ...tempGrades[orders.orderAdminChs[j].grade],
                    }
                }
            }
            setData(temp)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gradesLoading, allProducts, dataLoaded])

    const handleSearchTextChanged = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ): void => {
        setSearchText(event.target.value)
    }

    const handleDeleteOrderAdmin = async (): Promise<boolean> => {
        try {
            setLoading(true)
            setDeleting(true)
            await deleteOrderAdminById({
                variables: {
                    id: deleteOrderAdmin.id,
                },
            })
            showSnackbar('Success !! Order Deleted ')

            return true
        } catch (error) {
            showSnackbar(error.message, true)
            return false
        } finally {
            setDeleting(false)
            setDeleteOrderAdmin(null)
            refetch()
            setLoading(false)
        }
    }

    const filteredData = applySortFilter(data, 'asc', 'id', searchText, 'id')
    return (
        <>
            <Helmet>
                <title>Orders | {APP_NAME}</title>
            </Helmet>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8,
                }}
            >
                <Container maxWidth={false}>
                    <TableToolbar
                        title="Orders"
                        searchText={searchText}
                        onSearchTextChanged={handleSearchTextChanged}
                        placeHolder="Search Orders"
                    >
                        <Button
                            component={RouterLink}
                            to="/dashboard/order-admin"
                            startIcon={<Add />}
                            color="primary"
                            variant="contained"
                            size="large"
                        >
                            Add Order
                        </Button>
                    </TableToolbar>
                    {useMemo(
                        () => (
                            <Box sx={{ mt: 3 }}>
                                <DynamicTable<IOrderAdmin>
                                    isLoading={isLoading}
                                    columns={columns}
                                    data={filteredData}
                                    onRowClick={(item) => {
                                        const orderAdmin = JSON.parse(
                                            JSON.stringify(item)
                                        )
                                        orderAdmin.orderAdminChs.forEach(
                                            (_, index, array) => {
                                                array[index].grade =
                                                    array[index].grade.label
                                            }
                                        )

                                        navigate(
                                            `/dashboard/orders-admin/${orderAdmin.id}`,
                                            {
                                                state: {
                                                    orderAdmin,
                                                },
                                            }
                                        )
                                    }}
                                    onRowDelete={(item) =>
                                        setDeleteOrderAdmin(item)
                                    }
                                />
                            </Box>
                        ),
                        [filteredData, isLoading, navigate]
                    )}
                </Container>
                <DeleteModal
                    open={Boolean(deleteOrderAdmin)}
                    onClose={() => setDeleteOrderAdmin(null)}
                    onDeleteConfirm={handleDeleteOrderAdmin}
                    dialogTitle="Delete Order"
                    deleting={deleting}
                >
                    Are You sure you want to delete this order ?
                    <br />
                    ID: {deleteOrderAdmin?.id}
                    <br />
                    Invoice #: {deleteOrderAdmin?.invoiceNo}
                    <br />
                    Company Name #: {deleteOrderAdmin?.customer.companyName}
                </DeleteModal>
            </Box>
        </>
    )
}
