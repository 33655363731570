import { Remove, Add } from '@mui/icons-material'
import { Grid, Typography, IconButton, Button, TextField } from '@mui/material'
import { FieldArray } from 'formik'
import { FormikArrayField } from '../../components/Form/FormikArrayFeild'

export const PurchaseItems = ({ formik }: { formik: any }): ReactNode => {
    return (
        <Grid item container spacing={2}>
            <Grid item container spacing={2}>
                <Grid item xs="auto" sx={{ visibility: 'hidden' }}>
                    <IconButton color="error">
                        <Remove />
                    </IconButton>
                </Grid>
                <Grid item md={1.5}>
                    <Typography variant="h6">Quantity</Typography>
                </Grid>
                <Grid item md={1}>
                    <Typography variant="h6">UM</Typography>
                </Grid>
                <Grid item md={5}>
                    <Typography variant="h6">Description</Typography>
                </Grid>
                <Grid item md={1.5}>
                    <Typography variant="h6">Unit Price</Typography>
                </Grid>
                <Grid item md={2}>
                    <Typography variant="h6">Total</Typography>
                </Grid>
            </Grid>
            <FieldArray name="products">
                {({ remove, push }) => (
                    <>
                        {formik.values?.products?.map(
                            (purchaseItem: any, index: number) => (
                                <Grid
                                    key={purchaseItem.id?.toString() || index}
                                    item
                                    container
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Grid item xs="auto">
                                        <IconButton
                                            color="error"
                                            onClick={() => remove(index)}
                                        >
                                            <Remove />
                                        </IconButton>
                                    </Grid>

                                    <Grid item sm={12} md={1.5}>
                                        <FormikArrayField
                                            formik={formik}
                                            label="Qty"
                                            name={`products[${index}].quantity`}
                                            value={purchaseItem.quantity}
                                            type="number"
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={1}>
                                        <FormikArrayField
                                            formik={formik}
                                            label="UM"
                                            name={`products[${index}].unitOfMeasure`}
                                            value={purchaseItem.unitOfMeasure}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={5}>
                                        <FormikArrayField
                                            formik={formik}
                                            label="Description"
                                            name={`products[${index}].description`}
                                            value={purchaseItem.description}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={1.5}>
                                        <FormikArrayField
                                            formik={formik}
                                            label="Unit Price"
                                            name={`products[${index}].unitPrice`}
                                            value={purchaseItem.unitPrice}
                                            type="number"
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2}>
                                        <TextField
                                            value={
                                                purchaseItem.quantity *
                                                    purchaseItem.unitPrice || 0
                                            }
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                            )
                        )}
                        <Grid item xs={12}>
                            <Button
                                startIcon={<Add />}
                                disabled={formik.isSubmitting}
                                onClick={() => push({})}
                            >
                                Add Item
                            </Button>
                        </Grid>
                    </>
                )}
            </FieldArray>
        </Grid>
    )
}
