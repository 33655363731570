/* eslint-disable  */
import { Bar } from 'react-chartjs-2'
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    useTheme,
} from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import {  useNavigate } from 'react-router-dom'

export const BarChart = ({dataSet,labelSet}) => {
    const navigate = useNavigate()
    const theme = useTheme()

    const data = {
        datasets: [
            {
                backgroundColor: 'rgba(54, 175, 87, 1.5)',   
                barPercentage: 0.5,
                barThickness: 25,
                borderRadius: 4,
                categoryPercentage: 2,
                data: dataSet,
                label: 'Orders',
                maxBarThickness: 25,
            }
            // },
            // {
            //     backgroundColor: '#EEEEEE',
            //     barPercentage: 0.5,
            //     barThickness: 12,
            //     borderRadius: 4,
            //     categoryPercentage: 0.5,
            //     data: [11, 20, 12, 29, 30, 25, 13],
            //     label: 'Last year',
            //     maxBarThickness: 10,
            // },
        ],
        labels: labelSet,
    }

    const options = {
        animation: true,
        cornerRadius: 20,
        layout: { padding: 0 },
        legend: { display: false },
        maintainAspectRatio: false,
        responsive: true,
        xAxes: [
            {
                ticks: {
                    fontColor: theme.palette.text.secondary,
                },
                gridLines: {
                    display: false,
                    drawBorder: false,
                },
            },
        ],
        yAxes: [
            {
                ticks: {
                    fontColor: theme.palette.text.secondary,
                    beginAtZero: true,
                    min: 0,
                },
                gridLines: {
                    borderDash: [2],
                    borderDashOffset: [2],
                    color: theme.palette.divider,
                    drawBorder: false,
                    zeroLineBorderDash: [2],
                    zeroLineBorderDashOffset: [2],
                    zeroLineColor: theme.palette.divider,
                },
            },
        ],
        tooltips: {
            backgroundColor: theme.palette.background.paper,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            enabled: true,
            footerFontColor: theme.palette.text.secondary,
            intersect: false,
            mode: 'index',
            titleFontColor: theme.palette.text.primary,
        },
    }

    return (
        <Card>
            <CardHeader
                action={
                    <Button
                    color="primary"
                    endIcon={<ArrowRightIcon fontSize="small" />}
                    size="small"
                    onClick={()=>{
                        navigate('/dashboard/orders-admin')
                    }}
                >
                    Orders
                </Button>
                }
                title="Orders"
            />
            <Divider />
            <CardContent>
                <Box
                    sx={{
                        height: 300,
                        position: 'relative',
                    }}
                >
                    <Bar data={data} options={options} />
                </Box>
            </CardContent>
            <Divider />
        </Card>
    )
}
