/* eslint-disable no-param-reassign */
import { AceGrindingImageData } from '../icons/ace-grinding'
import { SealOfQualityImageData } from '../icons/seal-of-quality'

const calculator = (order: IOrderAdmin): IOrderAdmin => {
    const orderAdmin: IOrderAdmin = JSON.parse(JSON.stringify(order))
    orderAdmin.orderAdminChs.forEach((item, index, array) => {
        const currValues = item

        let bars = 0
        let grossLB = 0
        let netLB = 0
        let stockRemoval = 0
        let tolerance = 0

        // if qty is in bars (typeLorB == B) then totalBars = qty enter by user
        if (currValues?.typeLorB === 'B') {
            bars = currValues.quantity
        } else if (currValues?.typeLorB === 'L') {
            grossLB = currValues.quantity
        }

        stockRemoval = item.startSize - item.finishSizeHigh
        tolerance = item.finishSizeHigh - item.finishSizeLow

        const unitWeight = (currValues?.grade as any)?.unitWeight || 0
        // if its Ace's material
        let netWeight = 0
        const firstPart =
            currValues?.finishSizeHigh *
            currValues?.finishSizeHigh *
            (unitWeight / 12) *
            (currValues?.feet / (currValues?.feet * 12))

        netWeight =
            firstPart *
            (currValues?.feet * 12 + currValues.inches) *
            currValues?.quantity

        if (currValues.typeAorC === 'A') {
            if (currValues.typeLorB === 'B') {
                netLB = netWeight
                grossLB = netWeight
            } else {
                netLB = currValues.quantity

                const totalBars =
                    currValues.quantity /
                    (currValues.finishSizeHigh *
                        currValues.finishSizeHigh *
                        unitWeight)
                bars = Math.round(totalBars)
            }
        } else if (currValues.typeAorC === 'C') {
            if (currValues.typeLorB === 'B') {
                netLB = netWeight

                const grossFirstPart =
                    currValues?.startSize *
                    currValues?.startSize *
                    (unitWeight / 12) *
                    (currValues?.feet / (currValues?.feet * 12))

                const grossWeight =
                    grossFirstPart *
                    (currValues?.feet * 12 + currValues?.inches) *
                    currValues?.quantity
                grossLB = grossWeight
            } else {
                const totalBars =
                    currValues.quantity /
                    (currValues.startSize * currValues.startSize * unitWeight)
                bars = Math.round(totalBars)

                const netLb =
                    totalBars *
                    currValues.finishSizeHigh *
                    currValues.finishSizeHigh *
                    unitWeight

                netLB = netLb
            }
        }

        ;(array[index] as any).bars = bars
        ;(array[index] as any).grossLB = grossLB
        ;(array[index] as any).netLB = netLB
        ;(array[index] as any).stockRemoval = stockRemoval
        ;(array[index] as any).tolerance = tolerance
    })

    return orderAdmin
}

const imageScale = 0.095
const qualitySealImageScale = 0.15
export const generatePDFContent = (shipping: IShipping): Array<any> => {
    // const workOrder = calculator(orderAdmin)
    const orderAdmin = calculator(shipping.orderAdmin)
    return [
        {
            columns: [
                {
                    width: '*',
                    columns: [
                        {
                            image: AceGrindingImageData.image,
                            width: AceGrindingImageData.width * imageScale,
                            height: AceGrindingImageData.height * imageScale,
                            style: ['center'],
                            alignment: 'center',
                        },
                    ],
                    style: ['center'],
                    alignment: 'center',
                },
                {
                    width: '*',
                    text: `CERTIFICATE # ${shipping.id}`,
                    style: ['mainHeading', 'center'],
                    margin: [0, 10, 0, 0],
                },
                {
                    width: '*',
                    columns: [
                        {
                            image: SealOfQualityImageData.image,
                            width:
                                SealOfQualityImageData.width *
                                qualitySealImageScale,
                            height:
                                SealOfQualityImageData.height *
                                qualitySealImageScale,
                        },
                    ],
                    style: ['center'],
                    alignment: 'center',
                    margin: [40, 0, 0, 0],
                },
            ],

            margin: [0, 0, 0, 20],
        },

        {
            margin: [0, 0, 0, 20],
            table: {
                headerRows: 1,
                widths: ['*'],
                fontSize: 12,
                body: [
                    [
                        [
                            {
                                text: `Customer # ${shipping?.orderAdmin?.customer?.id}
								${shipping.orderAdmin.customer.companyName}
								${shipping.orderAdmin.customer.primaryAddress}
								${shipping.orderAdmin.customer.city}, ${shipping.orderAdmin.customer.state}
								${shipping.orderAdmin.customer.postalCode}`,
                                margin: [20, 0, 0, 0],
                                fontSize: 12,
                            },
                        ],
                    ],
                ],
            },
        },
        {
            margin: [0, 0, 0, 20],
            table: {
                headerRows: 1,
                widths: ['*', '*', '*'],
                body: [
                    [
                        {
                            text: `Order Number`,
                            bold: true,
                            fontSize: 10,
                            style: ['center'],
                        },
                        {
                            text: 'Customer Purchase Order Number',
                            bold: true,
                            fontSize: 10,
                            style: ['center'],
                        },
                        {
                            text: 'Date Shipped',
                            bold: true,
                            fontSize: 10,
                            style: ['center'],
                        },
                    ],
                    [
                        {
                            text: `${orderAdmin.id}`,
                            style: ['center'],
                            fontSize: 10,
                        },
                        {
                            text: orderAdmin.orderAdminChs
                                .map((item) => item?.inventory?.id || '-')
                                // .filter((item) => Boolean(item))
                                .join(', '),
                            style: ['center'],
                            fontSize: 10,
                        },
                        {
                            text: `${orderAdmin.shipDate}`,
                            style: ['center'],
                            fontSize: 10,
                        },
                    ],
                ],
            },
        },

        {
            margin: [0, 0, 0, 20],
            table: {
                headerRows: 1,
                widths: ['*'],
                body: [
                    [
                        {
                            text: '*** CERTIFICATE OF ANALYSIS ***',
                            bold: true,
                            style: ['center'],
                            fontSize: 10,
                        },
                    ],
                    [
                        {
                            margin: [5, 5, 5, 5],
                            table: {
                                headerRows: 1,
                                widths: ['*'],
                                fontSize: 12,
                                body: [
                                    [
                                        {
                                            text: 'DESCRIPTION',
                                            bold: true,
                                            style: ['center'],
                                            fontSize: 10,
                                        },
                                    ],
                                    [
                                        {
                                            table: {
                                                headerRows: 1,
                                                widths: [
                                                    '*',
                                                    '*',
                                                    150,
                                                    '*',
                                                    '*',
                                                    150,
                                                ],
                                                fontSize: 12,
                                                body: [
                                                    [
                                                        {
                                                            text: 'Qty Shipped',
                                                            bold: true,
                                                            style: ['center'],
                                                            fontSize: 10,
                                                        },
                                                        {
                                                            text: 'Qty Weight',
                                                            bold: true,
                                                            style: ['center'],
                                                            fontSize: 10,
                                                        },
                                                        {
                                                            text: 'Dimensions',
                                                            bold: true,
                                                            style: ['center'],
                                                            fontSize: 10,
                                                        },
                                                        {
                                                            text: 'Grade',
                                                            bold: true,
                                                            style: ['center'],
                                                            fontSize: 10,
                                                        },
                                                        {
                                                            text: 'Heat #',
                                                            bold: true,
                                                            style: ['center'],
                                                            fontSize: 10,
                                                        },
                                                        {
                                                            text: 'Remarks',
                                                            bold: true,
                                                            style: ['center'],
                                                            fontSize: 10,
                                                        },
                                                    ],
                                                    ...orderAdmin.orderAdminChs.map(
                                                        (item) => [
                                                            {
                                                                text:
                                                                    item.quantity,
                                                                style: [
                                                                    'center',
                                                                ],
                                                                margin: [
                                                                    2,
                                                                    2,
                                                                    2,
                                                                    2,
                                                                ],
                                                                fontSize: 10,
                                                            },
                                                            {
                                                                text:
                                                                    item.netLB,
                                                                style: [
                                                                    'center',
                                                                ],
                                                                margin: [
                                                                    2,
                                                                    2,
                                                                    2,
                                                                    2,
                                                                ],
                                                                fontSize: 10,
                                                            },
                                                            {
                                                                text: `${item.finishSizeHigh.toFixed(
                                                                    5
                                                                )}" / ${item.finishSizeHigh.toFixed(
                                                                    5
                                                                )}" ${
                                                                    item.feet
                                                                }' ${
                                                                    item.inches ||
                                                                    0
                                                                }"`,
                                                                style: [
                                                                    'center',
                                                                ],
                                                                margin: [
                                                                    2,
                                                                    2,
                                                                    2,
                                                                    2,
                                                                ],
                                                                fontSize: 10,
                                                            },
                                                            {
                                                                text:
                                                                    item.grade,
                                                                style: [
                                                                    'center',
                                                                ],
                                                                margin: [
                                                                    2,
                                                                    2,
                                                                    2,
                                                                    2,
                                                                ],
                                                                fontSize: 10,
                                                            },
                                                            {
                                                                text:
                                                                    item.heatNo,
                                                                style: [
                                                                    'center',
                                                                ],
                                                                margin: [
                                                                    2,
                                                                    2,
                                                                    2,
                                                                    2,
                                                                ],
                                                                fontSize: 10,
                                                            },
                                                            [
                                                                {
                                                                    text: `Material Spec: ${
                                                                        item
                                                                            .inventory
                                                                            ?.notes ||
                                                                        '-'
                                                                    }`,
                                                                    color:
                                                                        'green',
                                                                    style: [
                                                                        'center',
                                                                    ],
                                                                    margin: [
                                                                        2,
                                                                        2,
                                                                        2,
                                                                        2,
                                                                    ],
                                                                    fontSize: 10,
                                                                },
                                                                {
                                                                    text: `Country of Origin: ${
                                                                        item
                                                                            .inventory
                                                                            ?.country ||
                                                                        '-'
                                                                    }`,
                                                                    style: [
                                                                        'center',
                                                                    ],
                                                                    margin: [
                                                                        2,
                                                                        2,
                                                                        2,
                                                                        2,
                                                                    ],
                                                                    fontSize: 10,
                                                                },
                                                            ],
                                                        ]
                                                    ),
                                                ],
                                            },
                                            layout: {
                                                hLineWidth(i) {
                                                    return i === 1 ? 1 : 0
                                                },
                                                vLineWidth() {
                                                    return 0
                                                },
                                            },
                                        },
                                    ],
                                ],
                            },
                        },
                    ],
                ],
            },
        },
        {
            table: {
                headerRows: 1,
                widths: ['*'],
                body: [
                    [
                        {
                            text: 'CERTIFICATION',
                            bold: true,
                            style: ['center'],
                        },
                    ],
                    [
                        {
                            text:
                                'Ace Grinding Inc, hereby certifies that the above information is taken from ' +
                                'chemical and metallurgical test reports furnished to us by our suppliers, copies of which are on file.',
                        },
                    ],
                ],
            },
        },
    ]
}
