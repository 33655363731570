import { Box, Chip, Container, Stack } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { useMemo, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import moment from 'moment-timezone'
import { DynamicTable } from '../../components/Table/DynamicTable'
import { TableToolbar } from '../../components/Table/TableToolbar'
import { APP_NAME } from '../../config/config'
import { applySortFilter } from '../../utils/searchHelper'
import {
    queryGetAllOrdersShop,
    queryGetAllProducts,
} from '../../common/queries'
import { ChipColor } from '../../common/ChipColors'
import { generatePDFContent } from '../../pdfs/WorkOrderPDF'
import { generatePDF } from '../../pdfs/pdf'

const columns = [
    { name: 'Order ID', key: 'id' },
    { name: 'Customer Name', key: 'customer.companyName' },
    { name: 'Shipping Address', key: 'shipToCustomerAddress' },
    { name: 'Shipping Date', key: 'shipDate' },
    { name: 'Invoice #', key: 'invoiceNo' },
    { name: 'PO Number', key: 'pONumber' },
    {
        name: 'Status',
        key: 'status',
        component: (item: IOrderAdmin) => {
            const shipDate = moment(item.shipDate, 'DD-MM-YYYY')
            const diff = shipDate.diff(moment(), 'days')

            let severityColor
            if (diff < 0) severityColor = 'red'
            else if (diff <= 2) severityColor = 'orange'
            return (
                <Stack direction="row" spacing={1}>
                    <Chip
                        sx={{ ...ChipColor[item.status], fontWeight: 600 }}
                        label={item.status}
                    />
                    {severityColor && (
                        <Chip
                            sx={{
                                fontWeight: 600,
                                backgroundColor: severityColor,
                                color: 'white',
                            }}
                            label={diff < 0 ? 'LATE' : 'DUE SOON'}
                        />
                    )}
                </Stack>
            )
        },
    },
    {
        name: 'Actions',
        key: 'action',
        download: true,
        hideDelete: true,
        hideEdit: true,
        downloadCallback(item: IOrderAdmin) {
            generatePDF(generatePDFContent(item), {
                title: `Purchase Order ${item.id}`,
            })
        },
    },
]

export const WorkOrders = (): ReactNode => {
    const { data: allShopOrders, loading, refetch } = useQuery(
        queryGetAllOrdersShop
    )
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<IOrderAdmin[]>([])
    const [dataLoaded, setDataLoaded] = useState<string | null>(null)

    const [searchText, setSearchText] = useState('')

    useEffect(() => {
        setLoading(loading)
        if (!loading && allShopOrders && allShopOrders?.getAllOrdersShop) {
            setData(allShopOrders?.getAllOrdersShop)
            setDataLoaded('')
        }
    }, [allShopOrders, loading, refetch])

    const { data: allProducts, loading: gradesLoading } = useQuery(
        queryGetAllProducts
    )
    const [grades, setGrades] = useState<any>({})
    useEffect(() => {
        if (
            !gradesLoading &&
            allProducts &&
            allProducts?.getAllProducts &&
            data.length > 0
        ) {
            let tempGrades = {}
            if (Object.keys(grades).length === 0) {
                allProducts?.getAllProducts.forEach((prod): void => {
                    prod?.grades.forEach((grade): void => {
                        tempGrades[grade.name] = {
                            label: grade.name,
                            product: prod.name,
                            unitWeight: prod.unitWeight,
                        }
                    })
                })
                setGrades(tempGrades)
            } else {
                tempGrades = grades
            }

            const temp = JSON.parse(JSON.stringify(data))

            for (let i = 0; i < temp.length; i += 1) {
                const orders = temp[i]
                for (let j = 0; j < orders.orderAdminChs.length; j += 1) {
                    orders.orderAdminChs[j].grade = {
                        ...tempGrades[orders.orderAdminChs[j].grade],
                    }
                }
            }

            setData(temp)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gradesLoading, allProducts, dataLoaded])

    const handleSearchTextChanged = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ): void => {
        setSearchText(event.target.value)
    }

    const filteredData = applySortFilter(data, 'asc', 'id', searchText, 'id')
    return (
        <>
            <Helmet>
                <title>Work Orders| {APP_NAME}</title>
            </Helmet>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8,
                }}
            >
                <Container maxWidth={false}>
                    <TableToolbar
                        title="Work Orders "
                        searchText={searchText}
                        onSearchTextChanged={handleSearchTextChanged}
                        placeHolder="Search Work Orders"
                    />
                    {useMemo(
                        () => (
                            <Box sx={{ mt: 3 }}>
                                <DynamicTable<IOrderAdmin>
                                    isLoading={isLoading}
                                    columns={columns}
                                    data={filteredData}
                                    onRowClick={(item) => {
                                        navigate(
                                            `/shop/work-order/${item.id}`,
                                            {
                                                state: {
                                                    workOrder: item,
                                                },
                                            }
                                        )
                                    }}
                                />
                            </Box>
                        ),
                        [filteredData, isLoading, navigate]
                    )}
                </Container>
            </Box>
        </>
    )
}
