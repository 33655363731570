import { Grid, Button, Box, Card, Typography, TextField } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { LoadingButton } from '@mui/lab'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useLazyQuery } from '@apollo/client'
import { useDebouncedCallback } from 'use-debounce'
import { FormikTextField } from '../../components/Form/FormikTextField'
import { MessageContext, IMessageContext } from '../../context/MessageContext'
import { QuoteItems } from './QuoteItems'
import { queryGetCustomerById } from '../../common/queries'
import { MutationAddQuotation } from '../../common/Mutations'
import { IUserContext, UserContext } from '../../context/UserContext'
import { NumberFormatter } from '../../utils/NumberFormatter'

export const CreateQuotation = (): ReactNode => {
    const { data } = useContext(UserContext) as IUserContext
    const { user } = data

    const [getCustomerById, { data: customerData }] = useLazyQuery(
        queryGetCustomerById
    )
    const [customerId, setCustomerId] = useState<string>('')

    const formikConfig = {
        enableReinitialize: true,
        initialValues: {
            customerId,
            req: '',
            contactName: customerData?.getCustomer?.companyName || '',
            contactNumber: customerData?.getCustomer?.primaryContact || '',
            leadTime: '',
            faxNumber: '',
            shipVia: customerData?.getCustomer?.shipVia || '',
            quotationStock: [{}] as Array<IQuotationStock>,
            grandTotal: 0,
            quotedBy: user?.id,
        },
        validationSchema: Yup.object({
            quotedBy: Yup.string().required('required'),
            customerId: Yup.string().required('required'),
            quotationStock: Yup.array().of(
                Yup.object().shape({
                    typeAorC: Yup.string().required('required'),
                    typeLorB: Yup.string().required('required'),
                    grade: Yup.string().required('required'),
                    finishSizeHigh: Yup.number().required('required'),
                    finishSizeLow: Yup.number().required('required'),
                    rms: Yup.string().required('required'),
                    feet: Yup.string().required('required'),
                    grossLB: Yup.string().required('required'),
                    netLB: Yup.string().required('required'),
                    bars: Yup.string().required('required'),
                    priceType: Yup.string().required('required'),
                    total: Yup.string().required('required'),
                    unitPrice: Yup.string().required('required'),
                    quantity: Yup.number().required('required'),
                })
            ),
        }),
    }

    const [addQuotation] = useMutation(MutationAddQuotation)

    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const navigate = useNavigate()

    const createQuotationSubmit = async (
        values,
        setSubmitting
    ): Promise<boolean> => {
        try {
            const gTotal = values.quotationStock.reduce(
                (n, item) => n + item.total || 0,
                0
            )
            // eslint-disable-next-line no-param-reassign
            values.grandTotal = gTotal
            setSubmitting(true)
            await addQuotation({
                variables: values,
            })
            setSubmitting(false)
            showSnackbar('Success !! New Quotation Created')
            navigate('/dashboard/quotations')
            return true
        } catch (error) {
            setSubmitting(false)
            showSnackbar(error.message, true)
            return false
        }
    }
    const debounced = useDebouncedCallback((value) => {
        getCustomerById({
            variables: {
                id: value,
            },
        })
    }, 800)

    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                py: 8,
                px: 4,
            }}
        >
            <Card>
                <Typography variant="h4" sx={{ my: 2, mx: 2 }}>
                    Create Quotation
                </Typography>
                <Box>
                    <Formik
                        {...formikConfig}
                        onSubmit={(values, { setSubmitting }) => {
                            createQuotationSubmit(values, setSubmitting)
                        }}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ py: 2, px: 2 }}
                                >
                                    <Grid item xs={12} sm={6} md={1.5}>
                                        <Field
                                            error={Boolean(
                                                formik.touched.customerId &&
                                                    formik.errors.customerId
                                            )}
                                            helperText={
                                                formik.touched.customerId &&
                                                formik.errors.customerId
                                            }
                                            as={TextField}
                                            label="Customer ID"
                                            name="customerId"
                                            fullWidth
                                            onChange={(event) => {
                                                debounced(event.target.value)
                                                setCustomerId(
                                                    event.target.value
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2.5}>
                                        <TextField
                                            label="Customer Name"
                                            value={
                                                customerData?.getCustomer
                                                    ?.companyName
                                                    ? customerData?.getCustomer
                                                          ?.companyName
                                                    : ''
                                            }
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <FormikTextField
                                            formik={formik}
                                            label="REQ"
                                            name="req"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Contact Name"
                                            name="contactName"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Contact Number"
                                            name="contactNumber"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Lead Time"
                                            name="leadTime"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={1.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Quoted By"
                                            name="quotedBy"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Ship Via"
                                            name="shipVia"
                                        />
                                    </Grid>

                                    <QuoteItems formik={formik} />

                                    <Grid
                                        item
                                        container
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                label="Grand Total"
                                                fullWidth
                                                disabled
                                                value={NumberFormatter(
                                                    formik.values.quotationStock.reduce(
                                                        (n, item) =>
                                                            n + item.total || 0,
                                                        0
                                                    )
                                                )}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={6} sm={4} md={3}>
                                            <Button
                                                color="error"
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                variant="outlined"
                                                fullWidth
                                                onClick={() => {
                                                    formik.resetForm()
                                                    navigate(
                                                        '/dashboard/quotations'
                                                    )
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={3}>
                                            <LoadingButton
                                                color="success"
                                                loadingPosition="end"
                                                loading={formik.isSubmitting}
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                fullWidth
                                            >
                                                {formik.isSubmitting
                                                    ? 'Submitting'
                                                    : 'Submit'}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Card>
        </Box>
    )
}
