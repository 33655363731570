import { LoadingButton } from '@mui/lab'
import { Grid, Button, Box, Card, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import moment from 'moment-timezone'
import { useMutation } from '@apollo/client'
import { FormikTextField } from '../../components/Form/FormikTextField'
import { IMessageContext, MessageContext } from '../../context/MessageContext'
import { MutationUpdateProduct } from '../../common/Mutations'
import { ProductGrades } from './ProductGrades'

const validationSchema = Yup.object({
    name: Yup.string().max(255).required('Field is required'),
    unitWeight: Yup.string().max(255).required('Field is required'),
    grades: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required('required'),
        })
    ),
})

export const EditProduct = (): ReactNode => {
    const [updateProduct] = useMutation(MutationUpdateProduct)

    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const location = useLocation()
    const navigate = useNavigate()

    const { product } = location.state as { product: IProduct }

    const updateProductInfo = async (
        values,
        setSubmitting
    ): Promise<boolean> => {
        try {
            setSubmitting(true)

            await updateProduct({
                variables: values,
            })

            showSnackbar('Success !! Product Information Updated !! ')
            navigate('/dashboard/products', {
                replace: true,
            })
            setSubmitting(false)
            return true
        } catch (error) {
            setSubmitting(false)
            showSnackbar('Error !! product name must be unique ..', true)
            return false
        }
    }
    const formikConfig = {
        initialValues: {
            id: product?.id || '',
            name: product?.name || '',
            grades: product?.grades || [],
            unitWeight: product?.unitWeight || '',
            createdAt:
                moment(Number(product?.createdAt)).format('DD-MM-YYYY') || '',
        },
        validationSchema,
    }
    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexGrow: 1,
                py: 8,
                px: 4,
            }}
        >
            <Card>
                <Typography variant="h4" sx={{ my: 2, mx: 2 }}>
                    Edit Product
                </Typography>
                <Box>
                    <Formik
                        {...formikConfig}
                        onSubmit={(values, { setSubmitting }) => {
                            updateProductInfo(values, setSubmitting)
                        }}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ py: 2, px: 2 }}
                                >
                                    <Grid item sm={12} md={1.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Product Id"
                                            name="id"
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={2.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Created At"
                                            name="createdAt"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={4}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Name"
                                            name="name"
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={4}>
                                        <FormikTextField
                                            formik={formik}
                                            label="unit Weight"
                                            name="unitWeight"
                                            type="number"
                                            step="0.01"
                                        />
                                    </Grid>
                                    <ProductGrades formik={formik} />

                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={6} sm={4} md={3}>
                                            <Button
                                                color="error"
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                variant="outlined"
                                                fullWidth
                                                onClick={() => {
                                                    formik.resetForm()
                                                    navigate(
                                                        '/dashboard/products',
                                                        { replace: true }
                                                    )
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={3}>
                                            <LoadingButton
                                                color="success"
                                                loadingPosition="end"
                                                loading={formik.isSubmitting}
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                fullWidth
                                            >
                                                {formik.isSubmitting
                                                    ? 'Submitting'
                                                    : 'Submit'}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Card>
        </Box>
    )
}
