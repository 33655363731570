import React, { useState } from 'react'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Outlet } from 'react-router-dom'
import { DashboardNavbar } from './DashboardNavbar'
import { DashboardSidebar } from './DashboardSidebar'
import EditCustomerContainer from '../../context/EditCustomerContext'

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
        paddingLeft: 280,
    },
}))

export const DashboardLayout = (): ReactNode => {
    const [isSidebarOpen, setSidebarOpen] = useState(true)

    return (
        <>
            <DashboardLayoutRoot>
                <Box
                    sx={{
                        display: 'flex',
                        flex: '1 1 auto',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <EditCustomerContainer>
                        <Outlet />
                    </EditCustomerContainer>
                </Box>
            </DashboardLayoutRoot>
            <DashboardNavbar
                onSidebarOpen={() => {
                    setSidebarOpen(true)
                }}
            />
            <DashboardSidebar
                onClose={() => setSidebarOpen(false)}
                open={isSidebarOpen}
            />
        </>
    )
}
