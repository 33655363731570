import { LoadingButton } from '@mui/lab'
import { Grid, Button, Box, Card, Typography, TextField } from '@mui/material'
import { Form, Formik, Field } from 'formik'
import { useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import moment from 'moment-timezone'
import { useMutation } from '@apollo/client'
import MenuItem from '@mui/material/MenuItem'
import { FormikTextField } from '../../components/Form/FormikTextField'
import { IMessageContext, MessageContext } from '../../context/MessageContext'
import { MutationUpdateUSer, MutationDeleteUser } from '../../common/Mutations'
import { UserRoles } from '../../common/UserRoles'
import { UpdatePassword } from './UpdatePassword'
import { UserContext, IUserContext } from '../../context/UserContext'
import { auth } from '../../config/firebase-config.js'
import { DeleteModal } from '../../components/Modal/DeleteModal'

const validationSchema = Yup.object({
    name: Yup.string().max(255).required('User Name is required'),
    role: Yup.string().required('User Role is required'),
})

export const EditUser = (): ReactNode => {
    const [updateUser] = useMutation(MutationUpdateUSer)
    const { data } = useContext(UserContext) as IUserContext

    const [deleteUserById] = useMutation(MutationDeleteUser)
    const [deleting, setDeleting] = useState<boolean>(false)
    const [deleteUser, setDeleteUser] = useState<IUser | null>(null)

    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const location = useLocation()
    const navigate = useNavigate()

    const { user } = location.state as { user: IUser }

    const updateUserInfo = async (values, setSubmitting): Promise<boolean> => {
        try {
            setSubmitting(true)

            await updateUser({
                variables: values,
            })

            showSnackbar('Success !! User Information Updated !! ')
            navigate('/dashboard/users', {
                replace: true,
            })
            setSubmitting(false)

            return true
        } catch (error) {
            setSubmitting(false)
            showSnackbar(error.message, true)
            return false
        }
    }

    const handleDeleteUser = async (): Promise<boolean> => {
        try {
            setDeleting(true)
            await auth.currentUser.delete()
            await deleteUserById({
                variables: {
                    id: deleteUser?.id,
                },
            })
            setDeleteUser(null)
            setDeleting(false)
            showSnackbar('Success !! User Deleted ')

            return true
        } catch (error) {
            setDeleting(false)
            setDeleteUser(null)
            showSnackbar(error.message, true)

            return false
        } finally {
            navigate('/')
        }
    }
    const formikConfig = {
        initialValues: {
            id: user?.id || '',
            createdAt:
                moment(Number(user?.createdAt)).format('DD-MM-YYYY') || '',
            name: user?.name || '',
            role: user?.role || '',
        },
        validationSchema,
    }
    return (
        <>
            <Box
                component="main"
                sx={{
                    py: 8,
                    px: 4,
                }}
            >
                <Card>
                    <Grid container spacing={2} sx={{ py: 2, px: 2 }}>
                        <Grid item xs={8.5}>
                            <Typography variant="h4">Edit User</Typography>
                        </Grid>
                        {data?.user?.email === user?.email && (
                            <Grid item xs={3} justifyContent="flex-end">
                                <Button
                                    onClick={() => {
                                        setDeleteUser(user)
                                    }}
                                    color="error"
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                >
                                    Delete Account
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    <Box>
                        <Formik
                            {...formikConfig}
                            onSubmit={(values, { setSubmitting }) => {
                                updateUserInfo(values, setSubmitting)
                            }}
                        >
                            {(formik) => (
                                <Form onSubmit={formik.handleSubmit}>
                                    <Grid
                                        container
                                        spacing={2}
                                        sx={{ py: 2, px: 2 }}
                                    >
                                        <Grid
                                            item
                                            container
                                            sm={12}
                                            spacing={2}
                                        >
                                            <Grid item xs={6}>
                                                <FormikTextField
                                                    formik={formik}
                                                    label="User ID"
                                                    name="id"
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item sm={12} md={6}>
                                                <FormikTextField
                                                    formik={formik}
                                                    label="Created At"
                                                    name="createdAt"
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item sm={12} md={6}>
                                            <FormikTextField
                                                formik={formik}
                                                label="User Name"
                                                name="name"
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={6}>
                                            <Field
                                                as={TextField}
                                                select
                                                label="Role"
                                                name="role"
                                                fullWidth
                                                onBlur={formik.handleBlur}
                                                variant="outlined"
                                            >
                                                <MenuItem
                                                    value={UserRoles.ADMIN}
                                                >
                                                    {UserRoles.ADMIN}
                                                </MenuItem>
                                                <MenuItem
                                                    value={UserRoles.SHOP}
                                                >
                                                    {UserRoles.SHOP}
                                                </MenuItem>
                                            </Field>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            spacing={2}
                                            justifyContent="flex-end"
                                        >
                                            <Grid item xs={6} sm={4} md={3}>
                                                <Button
                                                    color="error"
                                                    disabled={
                                                        formik.isSubmitting
                                                    }
                                                    size="large"
                                                    variant="outlined"
                                                    fullWidth
                                                    onClick={() => {
                                                        formik.resetForm()
                                                        navigate(
                                                            '/dashboard/users'
                                                        )
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6} sm={4} md={3}>
                                                <LoadingButton
                                                    color="success"
                                                    loadingPosition="end"
                                                    loading={
                                                        formik.isSubmitting
                                                    }
                                                    disabled={
                                                        formik.isSubmitting
                                                    }
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                    fullWidth
                                                >
                                                    {formik.isSubmitting
                                                        ? 'Submitting'
                                                        : 'Submit'}
                                                </LoadingButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                        {data?.user?.email === user?.email && (
                            <UpdatePassword />
                        )}
                    </Box>
                </Card>
                <DeleteModal
                    dialogTitle="Delete User"
                    open={Boolean(deleteUser)}
                    onClose={() => setDeleteUser(null)}
                    onDeleteConfirm={handleDeleteUser}
                    deleting={deleting}
                >
                    Are You sure you want to delete your account?
                    <br />
                    Role: <b>{deleteUser?.role}</b>
                    <br />
                    Name: <b>{deleteUser?.name}</b>
                </DeleteModal>
            </Box>
        </>
    )
}
