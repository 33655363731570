import { Grid, Button, Box, Card, Typography } from '@mui/material'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { LoadingButton } from '@mui/lab'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { FormikTextField } from '../../components/Form/FormikTextField'
import { MessageContext, IMessageContext } from '../../context/MessageContext'
import { MutationAddNonConfimance } from '../../common/Mutations'

export const AddConformance = (): ReactNode => {
    const formikConfig = {
        initialValues: {
            nonConformanceType: '',
            nonConformanceCode: '',
            invoiceTotal: 0,
            quantity: 0,
            customerCode: '',
        },
        validationSchema: Yup.object({
            customerCode: Yup.string().required('required'),
            invoiceTotal: Yup.string().required('required'),
            quantity: Yup.string().required('required'),
        }),
    }

    const [addNewConformance] = useMutation(MutationAddNonConfimance)

    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const navigate = useNavigate()

    const createConformanceSubmit = async (
        values,
        setSubmitting
    ): Promise<boolean> => {
        try {
            setSubmitting(true)
            await addNewConformance({
                variables: values,
            })
            setSubmitting(false)
            showSnackbar('Success !! New Conformance Created')
            navigate('/dashboard/nonConformance')
            return true
        } catch (error) {
            setSubmitting(false)
            showSnackbar(error.message, true)
            return false
        }
    }

    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                py: 8,
                px: 4,
            }}
        >
            <Card>
                <Typography variant="h4" sx={{ my: 2, mx: 2 }}>
                    Create Conformance
                </Typography>
                <Box>
                    <Formik
                        {...formikConfig}
                        onSubmit={(values, { setSubmitting }) => {
                            createConformanceSubmit(values, setSubmitting)
                        }}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ py: 2, px: 2 }}
                                >
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Type"
                                            name="nonConformanceType"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Code"
                                            name="nonConformanceCode"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Customer Code"
                                            name="customerCode"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Quantity"
                                            name="quantity"
                                            type="number"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Invoice Total"
                                            name="invoiceTotal"
                                            type="number"
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={6} sm={4} md={3}>
                                            <Button
                                                color="error"
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                variant="outlined"
                                                fullWidth
                                                onClick={() => {
                                                    formik.resetForm()
                                                    navigate(
                                                        '/dashboard/nonConformance'
                                                    )
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={3}>
                                            <LoadingButton
                                                color="success"
                                                loadingPosition="end"
                                                loading={formik.isSubmitting}
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                fullWidth
                                            >
                                                {formik.isSubmitting
                                                    ? 'Submitting'
                                                    : 'Submit'}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Card>
        </Box>
    )
}
