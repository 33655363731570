import { Box, Button, Container } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { useMemo, useState, useEffect, useContext } from 'react'
import Add from '@mui/icons-material/Add'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { DynamicTable } from '../../components/Table/DynamicTable'
import { TableToolbar } from '../../components/Table/TableToolbar'
import { APP_NAME } from '../../config/config'
import { applySortFilter } from '../../utils/searchHelper'
import { DeleteModal } from '../../components/Modal/DeleteModal'
import { queryGetAllNonConfirmance } from '../../common/queries'
import { MutationDeleteNonConfirmance } from '../../common/Mutations'
import { IMessageContext, MessageContext } from '../../context/MessageContext'

const columns = [
    { name: 'ID', key: 'id' },
    { name: 'Type', key: 'nonConformanceType' },
    { name: 'Code', key: 'nonConformanceCode' },
    { name: 'Invoice Total', key: 'invoiceTotal' },
    { name: 'Quantity', key: 'quantity' },
    { name: 'Customer Code', key: 'customerCode' },
    { name: 'Actions', key: 'action' },
]

export const NonConformance = (): ReactNode => {
    const { data: allNCs, loading, refetch } = useQuery(
        queryGetAllNonConfirmance
    )
    const [deleteConformanceById] = useMutation(MutationDeleteNonConfirmance)

    const navigate = useNavigate()
    const [isLoading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<INonConformance[]>([])
    const [
        deleteConformace,
        setDeleteConformance,
    ] = useState<INonConformance | null>(null)
    const [deleting, setDeleting] = useState<boolean>(false)
    const [searchText, setSearchText] = useState('')
    const { showSnackbar } = useContext(MessageContext) as IMessageContext

    useEffect(() => {
        setLoading(loading)
        if (!loading && allNCs && allNCs?.getAllNCs) {
            setData(allNCs?.getAllNCs)
        }
    }, [allNCs, loading])

    const handleSearchTextChanged = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ): void => {
        setSearchText(event.target.value)
    }

    const filteredData: INonConformance[] = applySortFilter<INonConformance>(
        data,
        'asc',
        'id',
        searchText,
        'id'
    )

    const handleDeleteShipping = async (): Promise<boolean> => {
        try {
            setLoading(true)
            setDeleting(true)
            await deleteConformanceById({
                variables: {
                    id: deleteConformace?.id,
                },
            })
            setDeleteConformance(null)
            setDeleting(false)
            showSnackbar('Success !! Conformance Deleted ')

            return true
        } catch (error) {
            setDeleting(false)
            setDeleteConformance(null)
            showSnackbar(error.message, true)

            return false
        } finally {
            refetch()
            setLoading(false)
        }
    }

    return (
        <>
            <Helmet>
                <title>Non Conformance | {APP_NAME}</title>
            </Helmet>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8,
                }}
            >
                <Container maxWidth={false}>
                    <TableToolbar
                        title="Quality Control"
                        searchText={searchText}
                        onSearchTextChanged={handleSearchTextChanged}
                        placeHolder="Search By ID"
                    >
                        <Button
                            component={RouterLink}
                            to="/dashboard/add-nonConformance"
                            startIcon={<Add />}
                            color="primary"
                            variant="contained"
                            size="large"
                        >
                            Add Conformance
                        </Button>
                    </TableToolbar>
                    {useMemo(
                        () => (
                            <Box sx={{ mt: 3 }}>
                                <DynamicTable
                                    isLoading={isLoading}
                                    columns={columns}
                                    data={filteredData}
                                    onRowClick={(item) => {
                                        navigate(
                                            `/dashboard/edit-nonConformance/${item.id}`,
                                            {
                                                state: {
                                                    conformanceItem: item,
                                                },
                                            }
                                        )
                                    }}
                                    onRowDelete={(item) =>
                                        setDeleteConformance(item)
                                    }
                                />
                            </Box>
                        ),
                        [filteredData, isLoading, navigate]
                    )}
                </Container>
                <DeleteModal
                    dialogTitle="Delete Shipping"
                    open={Boolean(deleteConformace)}
                    onClose={() => setDeleteConformance(null)}
                    onDeleteConfirm={handleDeleteShipping}
                    deleting={deleting}
                >
                    Are You sure you want to delete this Item?
                    <br />
                    ID: {deleteConformace?.id}
                    <br />
                    Customer Code: {deleteConformace?.customerCode}
                </DeleteModal>
            </Box>
        </>
    )
}
