import React, { useState } from 'react'
// import { getToken } from '../utils/authHelper'

// import { ENDPOINT } from '../config/config'

export const EditCustomerContext = React.createContext({})

export const EditCustomerProvider = EditCustomerContext.Provider
export const EditCustomerConsumer = EditCustomerContext.Consumer

export interface IEditCustomerContainer {
    children: ReactNode[] | ReactNode
}

export interface IEditCustomerContext {
    customer: ICustomer | null
    setCustomer: React.Dispatch<React.SetStateAction<ICustomer | null>>
}

export function EditCustomerContainer({
    children,
}: IEditCustomerContainer): ReactNode {
    const [customer, setCustomer] = useState<ICustomer | null>(null)

    return (
        <EditCustomerProvider
            value={{
                customer,
                setCustomer,
            }}
        >
            {children}
        </EditCustomerProvider>
    )
}

export default EditCustomerContainer
