import { LoadingButton } from '@mui/lab'
import { Grid, Button, Box, Card, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import moment from 'moment-timezone'
import { useMutation } from '@apollo/client'
import { FormikTextField } from '../../components/Form/FormikTextField'
import { IMessageContext, MessageContext } from '../../context/MessageContext'
import { SecondaryAddresses } from '../Customer/SecondaryAddresses'
import { SecondaryContacts } from '../Customer/SecondaryContacts'
import { MutationUpdateVendor } from '../../common/Mutations'

const validationSchema = Yup.object({
    companyName: Yup.string().max(255).required('Company Name is required'),
    primaryAddress: Yup.string()
        .max(255)
        .required('Company Address is required'),
    secondaryAddresses: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required('Name is required'),
            address: Yup.string().required('Address is required'),
        })
    ),
    secondaryContacts: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required('Name is required'),
            contact: Yup.string().required('Contact is required'),
        })
    ),
    notes: Yup.string().notRequired(),
})

export const EditVendor = (): ReactNode => {
    const [updateVendor] = useMutation(MutationUpdateVendor)

    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const location = useLocation()
    const navigate = useNavigate()

    const { vendor } = location.state as { vendor: IVendor }

    const updateVendorInfo = async (
        values,
        setSubmitting
    ): Promise<boolean> => {
        try {
            setSubmitting(true)

            await updateVendor({
                variables: values,
            })

            showSnackbar('Success !! Vendor Information Updated !! ')
            navigate('/dashboard/vendors', {
                replace: true,
            })
            setSubmitting(false)

            return true
        } catch (error) {
            setSubmitting(false)
            showSnackbar(error.mesage, true)
            return false
        }
    }

    const formikConfig = {
        initialValues: {
            id: vendor?.id || '',
            createdAt:
                moment(Number(vendor?.createdAt)).format('DD-MM-YYYY') || '',
            companyName: vendor?.companyName || '',
            primaryAddress: vendor?.primaryAddress || '',
            secondaryAddresses: vendor?.secondaryAddresses || [],
            fax: vendor?.fax || '',
            primaryContact: vendor?.primaryContact || '',
            secondaryContacts: vendor?.secondaryContacts || [],
            termsOfPayment: vendor?.termsOfPayment || '',
            notes: vendor?.notes || '',
            state: vendor?.state || '',
            city: vendor?.city || '',
            zipCode: vendor?.zipCode || '',
        },
        validationSchema,
    }
    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                py: 8,
                px: 4,
            }}
        >
            <Card>
                <Typography variant="h4" sx={{ my: 2, mx: 2 }}>
                    Edit Vendor
                </Typography>
                <Box>
                    <Formik
                        {...formikConfig}
                        onSubmit={(values, { setSubmitting }) => {
                            updateVendorInfo(values, setSubmitting)
                        }}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ py: 2, px: 2 }}
                                >
                                    <Grid item xs={1.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Vendor ID"
                                            name="id"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Created At"
                                            name="createdAt"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={4}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Company Name"
                                            name="companyName"
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={4}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Company Address"
                                            name="primaryAddress"
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2}>
                                        <FormikTextField
                                            formik={formik}
                                            label="City"
                                            name="city"
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2}>
                                        <FormikTextField
                                            formik={formik}
                                            label="State"
                                            name="state"
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Zip Code"
                                            name="zipCode"
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={2}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Phone No."
                                            name="primaryContact"
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Fax"
                                            name="fax"
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={2}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Terms For Payment"
                                            name="termsOfPayment"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Notes"
                                            name="notes"
                                            multiline
                                            rows="3"
                                        />
                                    </Grid>

                                    <SecondaryAddresses formik={formik} />

                                    <SecondaryContacts formik={formik} />
                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={6} sm={4} md={3}>
                                            <Button
                                                color="error"
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                variant="outlined"
                                                fullWidth
                                                onClick={() => {
                                                    formik.resetForm()
                                                    navigate(
                                                        '/dashboard/vendors'
                                                    )
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={3}>
                                            <LoadingButton
                                                color="success"
                                                loadingPosition="end"
                                                loading={formik.isSubmitting}
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                fullWidth
                                            >
                                                {formik.isSubmitting
                                                    ? 'Submitting'
                                                    : 'Submit'}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Card>
        </Box>
    )
}
