import { Grid, Button, Box, Card, Typography } from '@mui/material'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { LoadingButton } from '@mui/lab'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { FormikTextField } from '../../components/Form/FormikTextField'
import { MessageContext, IMessageContext } from '../../context/MessageContext'
import { MutationCreateEmployee } from '../../common/Mutations'

const formikConfig = {
    initialValues: {
        name: '',
        email: '',
        address: '',
        contact: '',
        socialSecurity: '',
        notes: '',
    },
    validationSchema: Yup.object({
        name: Yup.string().max(255).required('Field is required'),
        email: Yup.string().notRequired(),
        address: Yup.string().required('Field is required'),
        contact: Yup.string().max(255).required('Field is required'),
        socialSecurity: Yup.string().max(255).required('Field is required'),
        notes: Yup.string().notRequired(),
    }),
}

export const CreateEmployee = (): ReactNode => {
    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const navigate = useNavigate()

    const [addEmployee] = useMutation(MutationCreateEmployee)

    const createEmployeeSubmit = async (
        values,
        setSubmitting
    ): Promise<boolean> => {
        try {
            setSubmitting(true)
            await addEmployee({
                variables: values,
            })

            setSubmitting(false)
            showSnackbar('Success !! Employee Created')
            navigate('/dashboard/employees', {
                replace: true,
            })
            return true
        } catch (e) {
            setSubmitting(false)
            showSnackbar(
                'Error !! Social security number already exist ..',
                true
            )
            return false
        }
    }

    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                py: 8,
                px: 4,
            }}
        >
            <Card>
                <Typography variant="h4" sx={{ my: 2, mx: 2 }}>
                    Create Employee
                </Typography>
                <Box>
                    <Formik
                        {...formikConfig}
                        onSubmit={(values, { setSubmitting }) => {
                            createEmployeeSubmit(values, setSubmitting)
                        }}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ py: 2, px: 2 }}
                                >
                                    <Grid item sm={12} md={2.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Name"
                                            name="name"
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Email"
                                            name="email"
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={4}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Address"
                                            name="address"
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={3}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Phone Number"
                                            name="contact"
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={3}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Social Security"
                                            name="socialSecurity"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Notes"
                                            name="notes"
                                            multiline
                                            rows="3"
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={6} sm={4} md={3}>
                                            <Button
                                                color="error"
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                variant="outlined"
                                                fullWidth
                                                onClick={() => {
                                                    formik.resetForm()
                                                    navigate(
                                                        '/dashboard/employees',
                                                        { replace: true }
                                                    )
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={3}>
                                            <LoadingButton
                                                color="success"
                                                loadingPosition="end"
                                                loading={formik.isSubmitting}
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                fullWidth
                                            >
                                                {formik.isSubmitting
                                                    ? 'Submitting'
                                                    : 'Submit'}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Card>
        </Box>
    )
}
