import * as React from 'react'
import ReactDOM from 'react-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { ApolloProvider } from '@apollo/client'
import App from './App'
import { theme } from './theme'

import apollo from './apollo'

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <ApolloProvider client={apollo as any}>
            <App />
        </ApolloProvider>
    </ThemeProvider>,
    document.querySelector('#root')
)
