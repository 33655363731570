import { Remove, Add } from '@mui/icons-material'
import { Grid, IconButton, Button } from '@mui/material'
import { FieldArray } from 'formik'
import { FormikArrayField } from '../../components/Form/FormikArrayFeild'

export const ProductGrades = ({ formik }: { formik: any }): ReactNode => {
    return (
        <Grid item container spacing={2}>
            <Grid item container spacing={2}>
                <Grid item xs="auto" sx={{ visibility: 'hidden' }}>
                    <IconButton color="error">
                        <Remove />
                    </IconButton>
                </Grid>
            </Grid>
            <FieldArray name="grades">
                {({ remove, push }) => (
                    <>
                        {formik.values?.grades?.map(
                            (grade: any, index: number) => (
                                <Grid
                                    key={grade.id?.toString() || index}
                                    item
                                    container
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Grid item xs="auto">
                                        <IconButton
                                            color="error"
                                            onClick={() => remove(index)}
                                        >
                                            <Remove />
                                        </IconButton>
                                    </Grid>

                                    <Grid item sm={12} md={5}>
                                        <FormikArrayField
                                            formik={formik}
                                            label="Grade"
                                            name={`grades[${index}].name`}
                                            value={grade.name}
                                            type="text"
                                        />
                                    </Grid>
                                </Grid>
                            )
                        )}
                        <Grid item xs={12}>
                            <Button
                                startIcon={<Add />}
                                disabled={formik.isSubmitting}
                                onClick={() =>
                                    push({
                                        name: '',
                                    })
                                }
                            >
                                Add
                            </Button>
                        </Grid>
                    </>
                )}
            </FieldArray>
        </Grid>
    )
}
