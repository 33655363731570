import { Box, Button, Container, Stack, Chip } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { useMemo, useState, useEffect, useContext } from 'react'
import Add from '@mui/icons-material/Add'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { DynamicTable } from '../../components/Table/DynamicTable'
import { TableToolbar } from '../../components/Table/TableToolbar'
import { APP_NAME } from '../../config/config'
import { applySortFilter } from '../../utils/searchHelper'
import { DeleteModal } from '../../components/Modal/DeleteModal'
import { queryGetAllShipping } from '../../common/queries'
import { MutationDeleteShippingById } from '../../common/Mutations'
import { IMessageContext, MessageContext } from '../../context/MessageContext'
import { generatePDF } from '../../pdfs/pdf'
import { generatePDFContent } from '../../pdfs/ShippingPDF'

const columns = [
    { name: 'Shipping ID', key: 'id' },
    { name: 'Order Id', key: 'orderAdmin.id' },
    { name: 'Customer Name', key: 'orderAdmin.shipToCustomerName' },
    { name: 'Shipping Charges', key: 'shippingCharges' },
    { name: 'Shipping Address', key: 'orderAdmin.shipToCustomerAddress' },
    {
        name: 'Bill send',
        key: 'hasCustomerBilled',
        component: (item: IShipping) => {
            let severityColor
            if (item.hasCustomerBilled) severityColor = 'green'
            else severityColor = 'red'
            return (
                <Stack direction="row" spacing={1}>
                    {severityColor && (
                        <Chip
                            sx={{
                                fontWeight: 600,
                                backgroundColor: severityColor,
                                color: 'white',
                            }}
                            label={item.hasCustomerBilled ? 'TRUE' : 'FALSE'}
                        />
                    )}
                </Stack>
            )
        },
    },
    {
        name: 'Actions',
        key: 'action',
        download: true,
        downloadCallback(item: IShipping) {
            generatePDF(generatePDFContent(item), {
                title: `Shipping ${item.id}`,
            })
        },
    },
]

export const Shipping = (): ReactNode => {
    const { data: allShippings, loading, refetch } = useQuery(
        queryGetAllShipping
    )
    const [deleteShippingById] = useMutation(MutationDeleteShippingById)

    const navigate = useNavigate()
    const [isLoading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<IShipping[]>([])
    const [deleteShipping, setDeleteShipping] = useState<IShipping | null>(null)
    const [deleting, setDeleting] = useState<boolean>(false)
    const [searchText, setSearchText] = useState('')
    const { showSnackbar } = useContext(MessageContext) as IMessageContext

    useEffect(() => {
        setLoading(loading)
        if (!loading && allShippings && allShippings?.getAllShipping) {
            setData(allShippings?.getAllShipping)
        }
    }, [allShippings, loading])

    const handleSearchTextChanged = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ): void => {
        setSearchText(event.target.value)
    }

    const filteredData: IShipping[] = applySortFilter<IShipping>(
        data,
        'asc',
        'id',
        searchText,
        'id'
    )

    const handleDeleteShipping = async (): Promise<boolean> => {
        try {
            setLoading(true)
            setDeleting(true)
            await deleteShippingById({
                variables: {
                    id: deleteShipping?.id,
                },
            })
            setDeleteShipping(null)
            setDeleting(false)
            showSnackbar('Success !! Shipping Deleted ')

            return true
        } catch (error) {
            setDeleting(false)
            setDeleteShipping(null)
            showSnackbar(error.message, true)

            return false
        } finally {
            refetch()
            setLoading(false)
        }
    }

    return (
        <>
            <Helmet>
                <title>Shipping | {APP_NAME}</title>
            </Helmet>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8,
                }}
            >
                <Container maxWidth={false}>
                    <TableToolbar
                        title="Shipping"
                        searchText={searchText}
                        onSearchTextChanged={handleSearchTextChanged}
                        placeHolder="Search By ID"
                    >
                        <Button
                            component={RouterLink}
                            to="/dashboard/add-shipping"
                            startIcon={<Add />}
                            color="primary"
                            variant="contained"
                            size="large"
                        >
                            Add Shipping
                        </Button>
                    </TableToolbar>
                    {useMemo(
                        () => (
                            <Box sx={{ mt: 3 }}>
                                <DynamicTable
                                    isLoading={isLoading}
                                    columns={columns}
                                    data={filteredData}
                                    onRowClick={(item) => {
                                        navigate(
                                            `/dashboard/edit-shipping/${item.id}`,
                                            {
                                                state: {
                                                    shippingItem: item,
                                                },
                                            }
                                        )
                                    }}
                                    onRowDelete={(item) =>
                                        setDeleteShipping(item)
                                    }
                                />
                            </Box>
                        ),
                        [filteredData, isLoading, navigate]
                    )}
                </Container>
                <DeleteModal
                    dialogTitle="Delete Shipping"
                    open={Boolean(deleteShipping)}
                    onClose={() => setDeleteShipping(null)}
                    onDeleteConfirm={handleDeleteShipping}
                    deleting={deleting}
                >
                    Are You sure you want to delete this Shipment?
                    <br />
                    ID: {deleteShipping?.id}
                    <br />
                    Name: {deleteShipping?.orderAdmin.shipToCustomerName}
                </DeleteModal>
            </Box>
        </>
    )
}
