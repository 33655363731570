import { Remove, Add } from '@mui/icons-material'
import {
    Grid,
    IconButton,
    Button,
    Card,
    MenuItem,
    TextField,
} from '@mui/material'
import { Field, FieldArray } from 'formik'
import Autocomplete from '@mui/material/Autocomplete'
import { useQuery } from '@apollo/client'
import { useState, useEffect } from 'react'
import { FormikArrayField } from '../../components/Form/FormikArrayFeild'
import { queryGetAllProducts } from '../../common/queries'
import { priceTypes } from '../../common/PriceTypes'

const calculator = (formik, index, selectedGrade): void => {
    const currValues = formik.values?.quotationStock[index]

    // if qty is in bars (typeLorB == B) then totalBars = qty enter by user
    if (currValues?.typeLorB === 'B') {
        formik.setFieldValue(
            `quotationStock[${index}].bars`,
            currValues.quantity
        )
    } else if (currValues?.typeLorB === 'L') {
        formik.setFieldValue(
            `quotationStock[${index}].grossLB`,
            currValues.quantity
        )
    }

    const { unitWeight } = selectedGrade
    // if its Ace's material
    let netWeight = 0

    const firstPart =
        currValues?.finishSizeHigh *
        currValues?.finishSizeHigh *
        (unitWeight / 12) *
        (currValues?.feet / (currValues?.feet * 12))

    netWeight =
        firstPart *
        (currValues?.feet * 12 + currValues.inches) *
        currValues?.quantity

    if (currValues.typeAorC === 'A') {
        if (currValues.typeLorB === 'B') {
            formik.setFieldValue(`quotationStock[${index}].netLB`, netWeight)
            formik.setFieldValue(`quotationStock[${index}].grossLB`, netWeight)
        } else {
            formik.setFieldValue(
                `quotationStock[${index}].netLB`,
                currValues.quantity
            )
            const totalBars =
                currValues.quantity /
                (currValues.finishSizeHigh *
                    currValues.finishSizeHigh *
                    unitWeight)
            formik.setFieldValue(
                `quotationStock[${index}].bars`,
                Math.round(totalBars)
            )
        }
    } else if (currValues.typeAorC === 'C') {
        if (currValues.typeLorB === 'B') {
            formik.setFieldValue(`quotationStock[${index}].netLB`, netWeight)

            const grossFirstPart =
                currValues?.startSize *
                currValues?.startSize *
                (unitWeight / 12) *
                (currValues?.feet / (currValues?.feet * 12))

            const grossWeight =
                grossFirstPart *
                (currValues?.feet * 12 + currValues?.inches) *
                currValues?.quantity
            formik.setFieldValue(
                `quotationStock[${index}].grossLB`,
                grossWeight
            )
        } else {
            const totalBars =
                currValues.quantity /
                (currValues.startSize * currValues.startSize * unitWeight)
            formik.setFieldValue(
                `quotationStock[${index}].bars`,
                Math.round(totalBars)
            )
            const netLb =
                totalBars *
                currValues.finishSizeHigh *
                currValues.finishSizeHigh *
                unitWeight

            formik.setFieldValue(`quotationStock[${index}].netLB`, netLb)
        }
    }
}

const QuoteItemsRow = ({
    formik,
    data,
    quoteItem,
    index,
    remove,
    loading,
}): ReactNode => {
    const item = formik.values.quotationStock[index]
    const [selectedGrade, setSelectedGrade] = useState<any>({})
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        // eslint-disable-next-line array-callback-return
        data.map((itemGrade: any) => {
            if (quoteItem.grade === itemGrade.label) {
                setSelectedGrade(itemGrade)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        calculator(formik, index, selectedGrade)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        item.typeLorB,
        item.typeAorC,
        item.quantity,
        selectedGrade,
        item.startSize,
        item.finishSizeHigh,
        item.feet,
        item.inches,
    ])
    return (
        <Grid item container xs={12} alignItems="center">
            <Grid item xs={0.75}>
                <IconButton color="error" onClick={() => remove(index)}>
                    <Remove />
                </IconButton>
            </Grid>
            <Grid item key={quoteItem.id?.toString() || index} xs>
                <Card variant="outlined">
                    <Grid
                        item
                        container
                        alignItems="start"
                        spacing={1}
                        sx={{ py: 1, px: 1 }}
                    >
                        <Grid item sm={12} md={1.25}>
                            <FormikArrayField
                                formik={formik}
                                label="A / C"
                                name={`quotationStock[${index}].typeAorC`}
                                value={quoteItem.typeAorC || ''}
                                select
                            >
                                <MenuItem value="A">A</MenuItem>
                                <MenuItem value="C">C</MenuItem>
                            </FormikArrayField>
                        </Grid>
                        <Grid item sm={12} md={1.25}>
                            <FormikArrayField
                                formik={formik}
                                label="L / B"
                                name={`quotationStock[${index}].typeLorB`}
                                value={quoteItem.typeLorB || ''}
                                select
                            >
                                <MenuItem value="L">L</MenuItem>
                                <MenuItem value="B">B</MenuItem>
                            </FormikArrayField>
                        </Grid>
                        <Grid item sm={12} md={1.5}>
                            <FormikArrayField
                                formik={formik}
                                label="Quantity"
                                name={`quotationStock[${index}].quantity`}
                                value={quoteItem.quantity}
                                type="number"
                                step="1"
                            />
                        </Grid>
                        <Grid item sm={12} md={1.5}>
                            <Autocomplete
                                options={data}
                                autoSelect
                                disableClearable
                                value={quoteItem.grade}
                                loading={loading}
                                onChange={(event, newValue) => {
                                    setSelectedGrade(newValue)
                                    formik.setFieldValue(
                                        `quotationStock[${index}].grade`,
                                        newValue.label
                                    )
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Grade" />
                                )}
                            />
                        </Grid>
                        <Grid item sm={12} md={1.5}>
                            <FormikArrayField
                                useSlowField
                                shrinkLabel
                                formik={formik}
                                label="Start Size"
                                name={`quotationStock[${index}].startSize`}
                                value={quoteItem.startSize}
                                type="number"
                                disabled={quoteItem.typeAorC === 'A'}
                            />
                        </Grid>
                        <Grid item sm={12} md={1.65}>
                            <FormikArrayField
                                formik={formik}
                                label="F. Size High"
                                name={`quotationStock[${index}].finishSizeHigh`}
                                value={quoteItem.finishSizeHigh}
                                type="number"
                            />
                        </Grid>
                        <Grid item sm={12} md={1.5}>
                            <FormikArrayField
                                formik={formik}
                                label="F. Size Low"
                                name={`quotationStock[${index}].finishSizeLow`}
                                value={quoteItem.finishSizeLow}
                                type="number"
                            />
                        </Grid>
                        <Grid item sm={12} md={1.5}>
                            <FormikArrayField
                                formik={formik}
                                label="RMS"
                                name={`quotationStock[${index}].rms`}
                                value={quoteItem.rms}
                                type="number"
                            />
                        </Grid>

                        <Grid item md={1.25}>
                            <FormikArrayField
                                formik={formik}
                                label="Feet"
                                name={`quotationStock[${index}].feet`}
                                value={quoteItem.feet}
                                type="number"
                                step="0.01"
                            />
                        </Grid>
                        <Grid item md={1.25}>
                            <Field
                                as={TextField}
                                label="Inches"
                                fullWidth
                                name={`quotationStock[${index}].inches`}
                                value={quoteItem.inches}
                                type="number"
                                step="0.01"
                            />
                        </Grid>
                        <Grid item sm={12} md={1.5}>
                            <TextField
                                label="Gross LB"
                                value={quoteItem.grossLB || 0}
                                type="number"
                                disabled
                            />
                        </Grid>
                        <Grid item sm={12} md={1.5}>
                            <TextField
                                label="Net LB"
                                value={quoteItem.netLB || 0}
                                type="number"
                                disabled
                            />
                        </Grid>
                        <Grid item sm={12} md={1.5}>
                            <TextField
                                label="Bars"
                                disabled
                                value={quoteItem.bars || 0}
                                type="number"
                            />
                        </Grid>
                        <Grid item sm={6} md={1.65}>
                            <FormikArrayField
                                select
                                formik={formik}
                                label="Price Type"
                                name={`quotationStock[${index}].priceType`}
                                value={quoteItem.priceType || ''}
                            >
                                {priceTypes.map((val) => (
                                    <MenuItem value={val.value}>
                                        {val.label}
                                    </MenuItem>
                                ))}
                            </FormikArrayField>
                        </Grid>
                        <Grid item sm={6} md={1.5}>
                            <FormikArrayField
                                formik={formik}
                                value={quoteItem.unitPrice}
                                label="Unit Price"
                                name={`quotationStock[${index}].unitPrice`}
                                type="number"
                                step="0.01"
                            />
                        </Grid>
                        <Grid item md={1.5}>
                            <FormikArrayField
                                formik={formik}
                                label="Box Price"
                                name={`quotationStock[${index}].boxingPrice`}
                                value={quoteItem.boxingPrice}
                                type="number"
                                step="0.01"
                            />
                        </Grid>

                        <Grid item sm={12} md={8}>
                            <FormikArrayField
                                formik={formik}
                                label="Notes"
                                name={`quotationStock[${index}].notes`}
                                value={quoteItem.notes}
                                type="notes"
                                multiline
                            />
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <FormikArrayField
                                formik={formik}
                                label="Total"
                                name={`quotationStock[${index}].total`}
                                value={quoteItem.total}
                                type="number"
                            />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    )
}

export const QuoteItems = ({ formik }: { formik: any }): ReactNode => {
    const { data: allProducts, loading } = useQuery(queryGetAllProducts)
    const [data, setData] = useState<any>([])

    useEffect(() => {
        if (!loading && allProducts && allProducts?.getAllProducts) {
            const temp = []
            // eslint-disable-next-line array-callback-return
            allProducts?.getAllProducts.map((prod): void => {
                // eslint-disable-next-line array-callback-return
                prod?.grades.map((grade): void => {
                    temp.push({
                        label: grade.name,
                        product: prod.name,
                        unitWeight: prod.unitWeight,
                    })
                })
            })

            setData(temp)
        }
    }, [loading, allProducts])

    return (
        <Grid container item spacing={2}>
            <FieldArray name="quotationStock">
                {({ remove, push }) => (
                    <Grid container item spacing={1}>
                        {formik.values?.quotationStock?.map(
                            (quoteItem: any, index: number) => (
                                <QuoteItemsRow
                                    formik={formik}
                                    quoteItem={quoteItem}
                                    data={data}
                                    index={index}
                                    remove={remove}
                                    loading
                                />
                            )
                        )}
                        <Grid item xs={12}>
                            <Button
                                startIcon={<Add />}
                                disabled={formik.isSubmitting}
                                onClick={() => push({})}
                            >
                                Add Item
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </FieldArray>
        </Grid>
    )
}
