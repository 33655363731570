import { Box, Button, Container, Chip, Stack } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { useMemo, useState, useEffect, useContext } from 'react'
import Add from '@mui/icons-material/Add'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { DynamicTable } from '../../components/Table/DynamicTable'
import { TableToolbar } from '../../components/Table/TableToolbar'
import { APP_NAME } from '../../config/config'
import { applySortFilter } from '../../utils/searchHelper'
import { DeleteModal } from '../../components/Modal/DeleteModal'
import { queryGetAllInvoiceAR } from '../../common/queries'
import { IMessageContext, MessageContext } from '../../context/MessageContext'
import { MutationDeleteInvoiceArById } from '../../common/Mutations'
import { GreenChip } from '../../common/ChipColors'
import { generatePDF } from '../../pdfs/pdf'
import { generatePDFContent } from '../../pdfs/InvoicePDF'

const columns = [
    { name: 'Invoice no', key: 'id' },
    { name: 'Order Id', key: 'orderAdmin.id' },
    { name: 'Customer Name', key: 'orderAdmin.customer.companyName' },
    { name: 'Total', key: 'invoiceTotal' },
    {
        name: 'Status',
        key: 'status',
        component: (item: IAR) => {
            return (
                <Stack direction="row" spacing={0.2}>
                    <Chip
                        sx={{ ...GreenChip, fontWeight: 400 }}
                        label={item.status}
                    />
                </Stack>
            )
        },
    },
    {
        name: 'Actions',
        key: 'action',
        download: true,
        downloadCallback(item: IAR) {
            generatePDF(generatePDFContent(item), {
                title: `Invoice ${item.id}`,
            })
        },
    },
]

export const AR = (): ReactNode => {
    const { data: getAllInvoiceARs, loading, refetch } = useQuery(
        queryGetAllInvoiceAR
    )
    const [deleteInvoiceARById] = useMutation(MutationDeleteInvoiceArById)
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<IAR[]>([])
    const [deleteInvoiceAR, setDeleteInvoiceAR] = useState<IAR | null>(null)
    const [deleting, setDeleting] = useState<boolean>(false)
    const [searchText, setSearchText] = useState('')
    const { showSnackbar } = useContext(MessageContext) as IMessageContext

    useEffect(() => {
        setLoading(loading)
        if (
            !loading &&
            getAllInvoiceARs &&
            getAllInvoiceARs?.getAllInvoiceARs
        ) {
            setData(getAllInvoiceARs?.getAllInvoiceARs)
        }
    }, [getAllInvoiceARs, loading])

    const handleSearchTextChanged = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ): void => {
        setSearchText(event.target.value)
    }

    const filteredData: IAR[] = applySortFilter<IAR>(
        data,
        'asc',
        'id',
        searchText,
        'id'
    )

    const handleDeleteInvoiceAR = async (): Promise<boolean> => {
        try {
            setLoading(true)
            setDeleting(true)
            await deleteInvoiceARById({
                variables: {
                    id: deleteInvoiceAR?.id,
                },
            })
            setDeleteInvoiceAR(null)
            setDeleting(false)
            showSnackbar('Success !! Invoice Deleted ')

            return true
        } catch (error) {
            setDeleting(false)
            setDeleteInvoiceAR(null)
            showSnackbar(error.message, true)

            return false
        } finally {
            refetch()
            setLoading(false)
        }
    }

    return (
        <>
            <Helmet>
                <title>AR | {APP_NAME}</title>
            </Helmet>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8,
                }}
            >
                <Container maxWidth={false}>
                    <TableToolbar
                        title="AR"
                        searchText={searchText}
                        onSearchTextChanged={handleSearchTextChanged}
                        placeHolder="Search ID"
                    >
                        <Button
                            component={RouterLink}
                            to="/dashboard/new-ar"
                            startIcon={<Add />}
                            color="primary"
                            variant="contained"
                            size="large"
                        >
                            Add Invoice
                        </Button>
                    </TableToolbar>
                    {useMemo(
                        () => (
                            <Box sx={{ mt: 3 }}>
                                <DynamicTable
                                    isLoading={isLoading}
                                    columns={columns}
                                    data={filteredData}
                                    onRowClick={(item) => {
                                        navigate(
                                            `/dashboard/edit-ar/${item.id}`,
                                            {
                                                state: {
                                                    ARItem: item,
                                                },
                                            }
                                        )
                                    }}
                                    onRowDelete={(item) => {
                                        setDeleteInvoiceAR(item)
                                    }}
                                />
                            </Box>
                        ),
                        [filteredData, isLoading, navigate]
                    )}
                </Container>
                <DeleteModal
                    dialogTitle="Delete Invoice"
                    open={Boolean(deleteInvoiceAR)}
                    onClose={() => setDeleteInvoiceAR(null)}
                    onDeleteConfirm={handleDeleteInvoiceAR}
                    deleting={deleting}
                >
                    Are You sure you want to delete this Invoice?
                    <br />
                    ID: {deleteInvoiceAR?.id}
                </DeleteModal>
            </Box>
        </>
    )
}
