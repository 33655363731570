import { LoadingButton } from '@mui/lab'
import { Grid, Button, Box, Card, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import moment from 'moment-timezone'
import { useMutation } from '@apollo/client'
import { FormikTextField } from '../../components/Form/FormikTextField'
import { IMessageContext, MessageContext } from '../../context/MessageContext'
import { MutationUpdateNonConfirmace } from '../../common/Mutations'

const validationSchema = Yup.object({
    customerCode: Yup.string().required('required'),
    invoiceTotal: Yup.string().required('required'),
    quantity: Yup.string().required('required'),
})

export const EditConformance = (): ReactNode => {
    const [updateConformance] = useMutation(MutationUpdateNonConfirmace)
    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const location = useLocation()
    const navigate = useNavigate()
    const { conformanceItem } = location.state as {
        conformanceItem: INonConformance
    }

    const handleConformanceUpdate = async (
        values,
        setSubmitting
    ): Promise<boolean> => {
        try {
            setSubmitting(true)
            await updateConformance({
                variables: values,
            })
            showSnackbar('Success !! Conformance Updated !! ')
            navigate('/dashboard/nonConformance', {
                replace: true,
            })
            setSubmitting(false)

            return true
        } catch (error) {
            setSubmitting(false)
            showSnackbar(error.message, true)
            return false
        }
    }

    const formikConfig = {
        initialValues: {
            id: conformanceItem?.id || '',
            nonConformanceType: conformanceItem?.nonConformanceType || '',
            nonConformanceCode: conformanceItem?.nonConformanceCode || '',
            invoiceTotal: conformanceItem?.invoiceTotal || 0,
            quantity: conformanceItem?.quantity || 0,
            customerCode: conformanceItem?.customerCode || '',
            createdAt:
                moment(Number(conformanceItem?.createdAt)).format(
                    'DD-MM-YYYY'
                ) || '',
        },
        validationSchema,
    }
    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                py: 8,
                px: 4,
            }}
        >
            <Card>
                <Typography variant="h4" sx={{ my: 2, mx: 2 }}>
                    Edit Quality Control
                </Typography>
                <Box>
                    <Formik
                        {...formikConfig}
                        onSubmit={(values, { setSubmitting }) => {
                            handleConformanceUpdate(values, setSubmitting)
                        }}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ py: 2, px: 2 }}
                                >
                                    <Grid item xs={12} sm={3} md={1.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="ID"
                                            name="id"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={2.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Date"
                                            name="createdAt"
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Type"
                                            name="nonConformanceType"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Code"
                                            name="nonConformanceCode"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Customer Code"
                                            name="customerCode"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Quantity"
                                            name="quantity"
                                            type="number"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Invoice Total"
                                            name="invoiceTotal"
                                            type="number"
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={6} sm={4} md={3}>
                                            <Button
                                                color="error"
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                variant="outlined"
                                                fullWidth
                                                onClick={() => {
                                                    formik.resetForm()
                                                    navigate(
                                                        '/dashboard/nonConformance'
                                                    )
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={3}>
                                            <LoadingButton
                                                color="success"
                                                loadingPosition="end"
                                                loading={formik.isSubmitting}
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                fullWidth
                                            >
                                                {formik.isSubmitting
                                                    ? 'Submitting'
                                                    : 'Submit'}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Card>
        </Box>
    )
}
