import { AceGrindingImageData } from '../icons/ace-grinding'

const imageScale = 0.15
export const generatePDFContent = (
    workOrder: IOrderAdmin,
    workOrderItem: IOrderAdminChs,
    notes: string,
    labels: ILabelItem[]
): Array<any> => {
    const labelItems = labels.filter(
        (item) =>
            item.pkg &&
            item.pkgType &&
            item.heatInfo &&
            item.net &&
            item.gross &&
            item.tare &&
            item.pieceCount
    )

    const totals = labelItems.reduce(
        (accumulator, item) => {
            accumulator.pkg += item.pkg
            accumulator.net += item.net
            accumulator.gross += item.gross
            accumulator.tare += item.tare
            accumulator.pieceCount += item.pieceCount

            return accumulator
        },
        {
            pkg: 0,
            net: 0,
            gross: 0,
            tare: 0,
            pieceCount: 0,
        }
    )

    return [
        {
            // columnGap: 10,
            margin: [0, 0, 0, 10],
            columns: [
                {
                    width: '50%',
                    columns: [
                        {
                            image: AceGrindingImageData.image,
                            width: AceGrindingImageData.width * imageScale,
                            height: AceGrindingImageData.height * imageScale,
                            style: ['center'],
                            alignment: 'center',
                        },
                    ],
                    style: ['center'],
                    alignment: 'center',
                },
                {
                    margin: [30, 30, 0, 0],
                    width: '50%',
                    text: `Ship Date #: ${workOrder.shipDate}`,
                    fontSize: 14,
                },
            ],
        },

        {
            // columnGap: 10,
            margin: [0, 10, 0, 0],
            columns: [
                {
                    margin: [20, 0, 0, 0],
                    width: '50%',
                    layout: 'noBorders',
                    table: {
                        widths: ['*'],
                        body: [
                            [
                                {
                                    text: workOrder?.shipToCustomerName,
                                    bold: true,
                                    fontSize: 10,
                                },
                            ],
                            [
                                {
                                    text: workOrder?.customer?.primaryContact,
                                    bold: true,
                                    fontSize: 10,
                                },
                            ],
                            [
                                {
                                    text: workOrder?.customer?.primaryAddress,
                                    bold: true,
                                    fontSize: 10,
                                },
                            ],
                            [
                                {
                                    text: `${
                                        workOrder?.customer?.city || ''
                                    }, ${workOrder?.customer?.state || ''}`,
                                    bold: true,
                                    fontSize: 10,
                                },
                            ],
                            [
                                {
                                    text: workOrder?.customer?.postalCode,
                                    bold: true,
                                    fontSize: 10,
                                },
                            ],
                        ],
                    },
                    // margin: [0, 10, 0, 0],
                },

                [
                    {
                        margin: [30, 10, 0, 0],
                        width: '50%',
                        text: `Packing Slip #:  ${workOrder.id}`,
                        fontSize: 12,
                    },
                    {
                        margin: [30, 10, 0, 0],
                        width: '50%',
                        text: `PO Number:  ${workOrder.pONumber}`,
                        fontSize: 12,
                    },
                ],
            ],
        },

        {
            text: 'Job Specs',
            bold: true,
            fontSize: 14,
            style: ['center'],
            margin: [0, 20, 0, 10],
        },
        {
            table: {
                widths: ['*', '*', '*'],
                body: [
                    [
                        {
                            text: 'FSH / FSL',
                            bold: true,
                            style: ['center'],
                            fontSize: 12,
                            margin: [0, 0, 0, 5],
                        },
                        {
                            text: 'Length',
                            bold: true,
                            style: ['center'],
                            fontSize: 12,
                            margin: [0, 0, 0, 5],
                        },
                        {
                            text: 'Grade',
                            bold: true,
                            style: ['center'],
                            fontSize: 12,
                            margin: [0, 0, 0, 5],
                        },
                    ],
                    [
                        {
                            text: `${workOrderItem.finishSizeHigh.toFixed(
                                5
                            )} / ${workOrderItem.finishSizeLow.toFixed(5)}`,
                            style: ['center'],
                            fontSize: 10,
                            margin: [0, 5, 0, 5],
                        },
                        {
                            text: `${workOrderItem.feet} FT  ${
                                workOrderItem.inches || 0
                            } INCHES`,
                            style: ['center'],
                            fontSize: 10,
                            margin: [0, 5, 0, 5],
                        },
                        {
                            text: workOrderItem.grade,
                            style: ['center'],
                            fontSize: 10,
                            margin: [0, 5, 0, 5],
                        },
                    ],
                ],
            },

            layout: 'noBorders',
            margin: [40, 0, 40, 0],
        },
        {
            text: 'Packing Information',
            bold: true,
            fontSize: 14,
            style: ['center'],
            margin: [0, 20, 0, 10],
        },
        {
            table: {
                widths: ['*', '*', '*', '*', '*', '*', '*'],
                body: [
                    [
                        {
                            text: 'Packages',
                            bold: true,
                            style: ['center'],
                            fontSize: 12,
                            margin: [0, 0, 0, 10],
                        },
                        {
                            text: 'Package Type',
                            bold: true,
                            style: ['center'],
                            fontSize: 12,
                            margin: [0, 0, 0, 10],
                        },
                        {
                            text: 'Scaled Qty',
                            bold: true,
                            style: ['center'],
                            fontSize: 12,
                            margin: [0, 0, 0, 10],
                        },
                        {
                            text: 'Heat Info',
                            bold: true,
                            style: ['center'],
                            fontSize: 12,
                            margin: [0, 0, 0, 10],
                        },
                        {
                            text: 'Tare',
                            bold: true,
                            style: ['center'],
                            fontSize: 12,
                            margin: [0, 0, 0, 10],
                        },
                        {
                            text: 'Net',
                            bold: true,
                            style: ['center'],
                            fontSize: 12,
                            margin: [0, 0, 0, 10],
                        },
                        {
                            text: 'Gross',
                            bold: true,
                            style: ['center'],
                            fontSize: 12,
                            margin: [0, 0, 0, 10],
                        },
                    ],
                    ...labelItems.map((item) => [
                        {
                            text: item.pkg,
                            style: ['center'],
                            fontSize: 10,
                            margin: [0, 5, 0, 5],
                        },
                        {
                            text: item.pkgType,
                            style: ['center'],
                            fontSize: 10,
                            margin: [0, 5, 0, 5],
                        },
                        {
                            text: item.pieceCount,
                            style: ['center'],
                            fontSize: 10,
                            margin: [0, 5, 0, 5],
                        },
                        {
                            text: item.heatInfo,
                            style: ['center'],
                            fontSize: 10,
                            margin: [0, 5, 0, 5],
                        },
                        {
                            text: item.tare.toFixed(5),
                            style: ['center'],
                            fontSize: 10,
                            margin: [0, 5, 0, 5],
                        },
                        {
                            text: item.net.toFixed(5),
                            style: ['center'],
                            fontSize: 10,
                            margin: [0, 5, 0, 5],
                        },
                        {
                            text: item.gross.toFixed(5),
                            style: ['center'],
                            fontSize: 10,
                            margin: [0, 5, 0, 5],
                        },
                    ]),
                    [
                        {
                            text: totals.pkg,
                            style: ['center'],
                            fontSize: 10,
                            margin: [0, 5, 0, 5],
                        },
                        {
                            text: '',
                            style: ['center'],
                            fontSize: 10,
                            margin: [0, 5, 0, 5],
                        },
                        {
                            text: totals.pieceCount,
                            style: ['center'],
                            fontSize: 10,
                            margin: [0, 5, 0, 5],
                        },
                        {
                            text: '',
                            style: ['center'],
                            fontSize: 10,
                            margin: [0, 5, 0, 5],
                        },
                        {
                            text: totals.tare.toFixed(5),
                            style: ['center'],
                            fontSize: 10,
                            margin: [0, 5, 0, 5],
                        },
                        {
                            text: totals.net.toFixed(5),
                            style: ['center'],
                            fontSize: 10,
                            margin: [0, 5, 0, 5],
                        },
                        {
                            text: totals.gross.toFixed(5),
                            style: ['center'],
                            fontSize: 10,
                            margin: [0, 5, 0, 5],
                        },
                    ],
                ],
            },

            layout: {
                hLineWidth(i, node) {
                    return i === node.table.body.length - 1 ? 1 : 0
                },
                vLineWidth() {
                    return 0
                },
            },
            // margin: [0, 10, 0, 0],
        },
        {
            text: 'Special Notes',
            fontSize: 12,
            margin: [10, 20, 10, 5],
        },
        {
            text: notes,
            fontSize: 10,
            margin: [10, 5, 10, 5],
        },
    ]
}
