import { Box, Button, Container } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { useMemo, useState, useEffect, useContext } from 'react'
import Add from '@mui/icons-material/Add'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { DynamicTable } from '../../components/Table/DynamicTable'
import { TableToolbar } from '../../components/Table/TableToolbar'
import { APP_NAME } from '../../config/config'
import { applySortFilter } from '../../utils/searchHelper'
import { queryGetAllInventory } from '../../common/queries'
import { DeleteModal } from '../../components/Modal/DeleteModal'
import { MutationDeleteInventoryById } from '../../common/Mutations'
import { IMessageContext, MessageContext } from '../../context/MessageContext'

const columns = [
    { name: 'ID', key: 'id' },
    { name: 'Grade', key: 'grade' },
    { name: 'Weight', key: 'weight' },
    { name: 'Heat no', key: 'heatNo' },
    { name: 'Country/Origin', key: 'country' },
    { name: 'Actions', key: 'action' },
]

export const Inventory = (): ReactNode => {
    const { data: allInventory, loading, refetch } = useQuery(
        queryGetAllInventory
    )

    const [deleteInventoryById] = useMutation(MutationDeleteInventoryById)

    const navigate = useNavigate()

    const [isLoading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<IInventory[]>([])
    const [deleteInventory, setInventory] = useState<IInventory | null>(null)
    const [deleting, setDeleting] = useState<boolean>(false)
    const [searchText, setSearchText] = useState('')
    const { showSnackbar } = useContext(MessageContext) as IMessageContext

    useEffect(() => {
        setLoading(loading)
        if (!loading && allInventory && allInventory?.getAllInventory) {
            setData(allInventory?.getAllInventory)
        }
    }, [loading, allInventory])

    const handleSearchTextChanged = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ): void => {
        setSearchText(event.target.value)
    }

    const handleDeleteInventory = async (): Promise<boolean> => {
        try {
            setLoading(true)
            setDeleting(true)
            await deleteInventoryById({
                variables: {
                    id: deleteInventory?.id,
                },
            })
            setInventory(null)
            setDeleting(false)
            showSnackbar('Success !! Inventory Deleted ')

            return true
        } catch (error) {
            setDeleting(false)
            setInventory(null)
            showSnackbar('Error !! unbale to delete inventory ')
            return false
        } finally {
            refetch()
            setLoading(false)
        }
    }

    const filteredData: IInventory[] = applySortFilter<IInventory>(
        data,
        'asc',
        'id',
        searchText,
        'id'
    )
    return (
        <>
            <Helmet>
                <title>Inventory | {APP_NAME}</title>
            </Helmet>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8,
                }}
            >
                <Container maxWidth={false}>
                    <TableToolbar
                        title="Inventory"
                        searchText={searchText}
                        onSearchTextChanged={handleSearchTextChanged}
                        placeHolder="Search Inventory"
                    >
                        <Button
                            component={RouterLink}
                            to="/dashboard/inventory"
                            startIcon={<Add />}
                            color="primary"
                            variant="contained"
                            size="large"
                        >
                            Add Inventory
                        </Button>
                    </TableToolbar>
                    {useMemo(
                        () => (
                            <Box sx={{ mt: 3 }}>
                                <DynamicTable<IInventory>
                                    isLoading={isLoading}
                                    columns={columns}
                                    data={filteredData}
                                    onRowClick={(item) => {
                                        navigate(
                                            `/dashboard/inventory/${item.id}`,
                                            {
                                                state: {
                                                    inventory: item,
                                                },
                                            }
                                        )
                                    }}
                                    onRowDelete={(item) => setInventory(item)}
                                />
                            </Box>
                        ),
                        [filteredData, isLoading, navigate]
                    )}
                </Container>

                <DeleteModal
                    dialogTitle="Delete Inventory"
                    open={Boolean(deleteInventory)}
                    onClose={() => setInventory(null)}
                    onDeleteConfirm={handleDeleteInventory}
                    deleting={deleting}
                >
                    Are You sure you want to delete this inventory?
                    <br />
                    ID: {deleteInventory?.id}
                    <br />
                    {/* Name: {deleteInventory?.name} */}
                </DeleteModal>
            </Box>
        </>
    )
}
