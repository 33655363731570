import { Grid, Button, Box, Card, Typography, TextField } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { LoadingButton } from '@mui/lab'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import MenuItem from '@mui/material/MenuItem'
import { createUserWithEmailAndPassword, signOut } from 'firebase/auth'
import { FormikTextField } from '../../components/Form/FormikTextField'
import { MessageContext, IMessageContext } from '../../context/MessageContext'
import { MutationAddUser } from '../../common/Mutations'
import { UserRoles } from '../../common/UserRoles'
import { addUserAuth } from '../../config/firebase-config.js'

const formikConfig = {
    initialValues: {
        name: '',
        email: '',
        role: UserRoles.ADMIN,
        password: '',
        confirmPassword: '',
    },
    validationSchema: Yup.object({
        name: Yup.string().max(255).required('Name is required'),
        email: Yup.string()
            .email('Invalid email format')
            .required('Email is required'),
        role: Yup.string().required('User Role is required'),
        password: Yup.string()
            .min(8, 'Password is too short - should be 8 chars minimum.')
            .required('Password is required'),
        confirmPassword: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'Passwords must match'
        ),
    }),
}

export function CreateUser(): ReactNode {
    const [addUser] = useMutation(MutationAddUser)

    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const navigate = useNavigate()

    const createUserSubmit = async (
        values,
        setSubmitting
    ): Promise<boolean> => {
        try {
            setSubmitting(true)
            const firebaseUser = await createUserWithEmailAndPassword(
                addUserAuth,
                values.email,
                values.password
            )
            const firebaseUid = firebaseUser?.user.uid

            await addUser({
                variables: {
                    name: values.name,
                    email: values.email,
                    role: values.role,
                    firebaseUid,
                },
            })
            await signOut(addUserAuth)
            setSubmitting(false)
            showSnackbar('Success !! User Created')
            navigate('/dashboard/users')

            return true
        } catch (error) {
            setSubmitting(false)
            showSnackbar(error.message, true)
            return false
        }
    }

    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexGrow: 1,
                py: 8,
                px: 4,
            }}
        >
            <Card>
                <Typography variant="h4" sx={{ my: 2, mx: 2 }}>
                    Create User
                </Typography>

                <Box>
                    <Formik
                        {...formikConfig}
                        onSubmit={(values, { setSubmitting }) => {
                            createUserSubmit(values, setSubmitting)
                        }}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ py: 2, px: 2 }}
                                >
                                    <Grid item sm={12} md={6}>
                                        <FormikTextField
                                            formik={formik}
                                            label="User Name"
                                            name="name"
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={6}>
                                        <Field
                                            error={Boolean(
                                                formik.touched.email &&
                                                    formik.errors.email
                                            )}
                                            fullWidth
                                            helperText={
                                                formik.touched.email &&
                                                formik.errors.email
                                            }
                                            as={TextField}
                                            label="Email"
                                            name="email"
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={6}>
                                        <Field
                                            as={TextField}
                                            error={Boolean(
                                                formik.touched.password &&
                                                    formik.errors.password
                                            )}
                                            fullWidth
                                            helperText={
                                                formik.touched.password &&
                                                formik.errors.password
                                            }
                                            label="Password"
                                            name="password"
                                            type="password"
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={6}>
                                        <Field
                                            as={TextField}
                                            error={Boolean(
                                                formik.touched
                                                    .confirmPassword &&
                                                    formik.errors
                                                        .confirmPassword
                                            )}
                                            fullWidth
                                            helperText={
                                                formik.touched
                                                    .confirmPassword &&
                                                formik.errors.confirmPassword
                                            }
                                            formik={formik}
                                            label="Confirm Password"
                                            name="confirmPassword"
                                            type="password"
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={6}>
                                        <Field
                                            as={TextField}
                                            select
                                            label="Role"
                                            name="role"
                                            fullWidth
                                            onBlur={formik.handleBlur}
                                            variant="outlined"
                                        >
                                            <MenuItem value={UserRoles.ADMIN}>
                                                {UserRoles.ADMIN}
                                            </MenuItem>
                                            <MenuItem value={UserRoles.SHOP}>
                                                {UserRoles.SHOP}
                                            </MenuItem>
                                        </Field>
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={6} sm={4} md={3}>
                                            <Button
                                                color="error"
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                variant="outlined"
                                                fullWidth
                                                onClick={() => {
                                                    formik.resetForm()
                                                    navigate('/dashboard/users')
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={3}>
                                            <LoadingButton
                                                color="success"
                                                loadingPosition="end"
                                                loading={formik.isSubmitting}
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                fullWidth
                                            >
                                                {formik.isSubmitting
                                                    ? 'Submitting'
                                                    : 'Submit'}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Card>
        </Box>
    )
}
