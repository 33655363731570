import { LoadingButton } from '@mui/lab'
import { Grid, Button, Box, Card, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import moment from 'moment-timezone'
import { useMutation } from '@apollo/client'
import { FormikTextField } from '../../components/Form/FormikTextField'
import { IMessageContext, MessageContext } from '../../context/MessageContext'
import { MutationUpdateEmployee } from '../../common/Mutations'

const validationSchema = Yup.object({
    name: Yup.string().max(255).required('Field is required'),
    email: Yup.string().max(255).required('Field is required'),
    address: Yup.string().notRequired(),
    contact: Yup.string().max(255).required('Field is required'),
    socialSecurity: Yup.string().max(255).required('Field is required'),
    notes: Yup.string().notRequired(),
})

export const EditEmployee = (): ReactNode => {
    const [updateEmployee] = useMutation(MutationUpdateEmployee)

    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const location = useLocation()
    const navigate = useNavigate()

    const { employee } = location.state as { employee: IEmployee }

    const updateEmployeeInfo = async (
        values,
        setSubmitting
    ): Promise<boolean> => {
        try {
            setSubmitting(true)

            await updateEmployee({
                variables: values,
            })

            showSnackbar('Success !! Employee Information Updated !! ')
            navigate('/dashboard/employees', {
                replace: true,
            })
            setSubmitting(false)
            return true
        } catch (error) {
            setSubmitting(false)
            showSnackbar(
                'Error !! Social security number already exist ..',
                true
            )
            return false
        }
    }
    const formikConfig = {
        initialValues: {
            id: employee?.id || '',
            name: employee?.name || '',
            email: employee?.email || '',
            address: employee?.address || '',
            contact: employee?.contact || '',
            socialSecurity: employee?.socialSecurity || '',
            notes: employee?.notes || '',
            createdAt:
                moment(Number(employee?.createdAt)).format('DD-MM-YYYY') || '',
        },
        validationSchema,
    }
    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                py: 8,
                px: 4,
            }}
        >
            <Card>
                <Typography variant="h4" sx={{ my: 2, mx: 2 }}>
                    Edit Employee
                </Typography>
                <Box>
                    <Formik
                        {...formikConfig}
                        onSubmit={(values, { setSubmitting }) => {
                            updateEmployeeInfo(values, setSubmitting)
                        }}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ py: 2, px: 2 }}
                                >
                                    <Grid item sm={12} md={1.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Employee ID"
                                            name="id"
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={2.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Created At"
                                            name="createdAt"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={4}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Name"
                                            name="name"
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={4}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Email"
                                            name="email"
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={4}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Address"
                                            name="address"
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={4}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Phone Number"
                                            name="contact"
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={4}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Social Security"
                                            name="socialSecurity"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Notes"
                                            name="notes"
                                            multiline
                                            rows="3"
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={6} sm={4} md={3}>
                                            <Button
                                                color="error"
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                variant="outlined"
                                                fullWidth
                                                onClick={() => {
                                                    formik.resetForm()
                                                    navigate(
                                                        '/dashboard/employees',
                                                        { replace: true }
                                                    )
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={3}>
                                            <LoadingButton
                                                color="success"
                                                loadingPosition="end"
                                                loading={formik.isSubmitting}
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                fullWidth
                                            >
                                                {formik.isSubmitting
                                                    ? 'Submitting'
                                                    : 'Submit'}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Card>
        </Box>
    )
}
