import { Remove, Add } from '@mui/icons-material'
import { Grid, IconButton, Button, Card, TextField } from '@mui/material'
import { FieldArray, Field } from 'formik'
import { useLazyQuery } from '@apollo/client'
import { queryGetEmployeeId } from '../../common/queries'

export const TimeSheet = ({
    formik,
    disableAll = false,
}: {
    formik: any
    disableAll?: boolean
}): ReactNode => {
    const [getEmployeeById] = useLazyQuery(queryGetEmployeeId)
    const onEmployeeIdChange = async (event, index): Promise<void> => {
        formik.setFieldValue(`timeSheet[${index}].id`, event.target.value)
        if (event.target.value !== '') {
            const { data } = await getEmployeeById({
                variables: {
                    id: event.target.value,
                },
            })
            const employeeName = data?.getEmployee.name

            formik.setFieldValue(`timeSheet[${index}].name`, employeeName)
        } else {
            formik.setFieldValue(`timeSheet[${index}].name`, '')
        }
    }
    return (
        <Grid container item spacing={2}>
            <FieldArray name="timeSheet">
                {({ remove, push }) => (
                    <Grid container item spacing={1}>
                        {formik.values?.timeSheet?.map(
                            (timeSheetItem: any, index: number) => (
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    alignItems="center"
                                >
                                    <Grid item xs={0.75}>
                                        <IconButton
                                            color="error"
                                            onClick={() => remove(index)}
                                        >
                                            <Remove />
                                        </IconButton>
                                    </Grid>
                                    <Grid
                                        item
                                        key={
                                            timeSheetItem.id?.toString() ||
                                            index
                                        }
                                        xs
                                    >
                                        <Card variant="outlined">
                                            <Grid
                                                item
                                                container
                                                alignItems="start"
                                                spacing={2}
                                                sx={{ py: 2, px: 2 }}
                                            >
                                                <Grid item sm={12} md={2}>
                                                    <Field
                                                        as={TextField}
                                                        fullWidth
                                                        disabled={disableAll}
                                                        label="Employee ID"
                                                        type="text"
                                                        // name={`timeSheet[${index}].employeeID`}
                                                        onChange={(event) => {
                                                            onEmployeeIdChange(
                                                                event,
                                                                index
                                                            )
                                                        }}
                                                        value={timeSheetItem.id}
                                                    />
                                                </Grid>
                                                <Grid item sm={12} md={4}>
                                                    <TextField
                                                        // as={TextField}
                                                        // fullWidth
                                                        // formik={formik}
                                                        label="Employee Name"
                                                        fullWidth
                                                        disabled
                                                        // name={`timeSheet[${index}].employeeName`}
                                                        value={
                                                            timeSheetItem.name ||
                                                            ''
                                                        }
                                                    />
                                                </Grid>

                                                {/* <Grid item sm={12} md={7}>
                                                    <FormikArrayField
                                                        disabled={disableAll}
                                                        formik={formik}
                                                        label="Notes"
                                                        name={`timeSheet[${index}].Notes`}
                                                        value={
                                                            timeSheetItem.Notes
                                                        }
                                                        multiline
                                                    />
                                                </Grid> */}
                                            </Grid>
                                        </Card>
                                    </Grid>
                                </Grid>
                            )
                        )}
                        <Grid item xs={12}>
                            <Button
                                startIcon={<Add />}
                                disabled={formik.isSubmitting}
                                onClick={() => push({})}
                            >
                                Add Employee
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </FieldArray>
        </Grid>
    )
}
