import { Box, Button, Container } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { useMemo, useState, useEffect, useContext } from 'react'
import Add from '@mui/icons-material/Add'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { DynamicTable } from '../../components/Table/DynamicTable'
import { TableToolbar } from '../../components/Table/TableToolbar'
import { APP_NAME } from '../../config/config'
import { applyFilter } from '../../utils/searchHelper'
import { DeleteModal } from '../../components/Modal/DeleteModal'
import { queryGetAllPurchaseOrders } from '../../common/queries'
import { MutationDeletePurchaseOrderById } from '../../common/Mutations'
import { IMessageContext, MessageContext } from '../../context/MessageContext'
import { generatePDF } from '../../pdfs/pdf'
import { generatePDFContent } from '../../pdfs/PurchaseOrderPDF'

// const purchaseOrders = null
const columns = [
    { name: 'P.Order #', key: 'id' },
    { name: 'Vendor ID', key: 'vendor.id' },
    { name: 'Vendor Name', key: 'vendor.companyName' },
    { name: 'Contact Name', key: 'contactName' },
    {
        name: 'Actions',
        key: 'action',
        download: true,
        downloadCallback(item: IPurchaseOrder) {
            generatePDF(generatePDFContent(item), {
                title: `Purchase Order ${item.id}`,
            })
        },
    },
]

export const PurchaseOrders = (): ReactNode => {
    const { data: allPurchaseOrders, loading, refetch } = useQuery(
        queryGetAllPurchaseOrders
    )
    const [deletePurchaseOrderById] = useMutation(
        MutationDeletePurchaseOrderById
    )
    const navigate = useNavigate()

    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const [isLoading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<IPurchaseOrder[]>([])

    const [
        deletePurchaseOrder,
        setDeletePurchaseOrder,
    ] = useState<IPurchaseOrder | null>(null)
    const [deleting, setDeleting] = useState<boolean>(false)
    const [searchText, setSearchText] = useState('')

    useEffect(() => {
        setLoading(loading)
        if (
            !loading &&
            allPurchaseOrders &&
            allPurchaseOrders?.getAllPurchaseOrders
        ) {
            setData(allPurchaseOrders?.getAllPurchaseOrders)
        }
    }, [allPurchaseOrders, loading])

    const handleSearchTextChanged = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ): void => {
        setSearchText(event.target.value)
    }

    const handleDeletePurchaseOrder = async (): Promise<boolean> => {
        try {
            setLoading(true)
            setDeleting(true)
            await deletePurchaseOrderById({
                variables: {
                    id: deletePurchaseOrder.id,
                },
            })
            showSnackbar('Success !! Purchase Order Deleted ')

            return true
        } catch (error) {
            showSnackbar('Error !! unable to delete purchase order')
            return false
        } finally {
            setDeleting(false)
            setDeletePurchaseOrder(null)
            refetch()
            setLoading(false)
        }
    }

    const filteredData = applyFilter(data, searchText, [
        'id',
        'vendor.companyName',
    ])
    return (
        <>
            <Helmet>
                <title>Purchase Orders | {APP_NAME}</title>
            </Helmet>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8,
                }}
            >
                <Container maxWidth={false}>
                    <TableToolbar
                        title="Purchase Orders"
                        searchText={searchText}
                        onSearchTextChanged={handleSearchTextChanged}
                        placeHolder="Search Purchase Orders"
                    >
                        <Button
                            component={RouterLink}
                            to="/dashboard/purchase-order"
                            startIcon={<Add />}
                            color="primary"
                            variant="contained"
                            size="large"
                        >
                            Add Purchase Order
                        </Button>
                    </TableToolbar>
                    {useMemo(
                        () => (
                            <Box sx={{ mt: 3 }}>
                                <DynamicTable<IPurchaseOrder>
                                    isLoading={isLoading}
                                    columns={columns}
                                    data={filteredData}
                                    onRowClick={(item) => {
                                        navigate(
                                            `/dashboard/purchase-order/${item.id}`,
                                            {
                                                state: {
                                                    purchaseOrder: item,
                                                },
                                            }
                                        )
                                    }}
                                    onRowDelete={(item) =>
                                        setDeletePurchaseOrder(item)
                                    }
                                />
                            </Box>
                        ),
                        [filteredData, isLoading, navigate]
                    )}
                </Container>
                <DeleteModal
                    open={Boolean(deletePurchaseOrder)}
                    onClose={() => setDeletePurchaseOrder(null)}
                    onDeleteConfirm={handleDeletePurchaseOrder}
                    dialogTitle="Delete Purchase Order"
                    deleting={deleting}
                >
                    Are You sure you want to delete this purchase order?
                    <br />
                    ID: {deletePurchaseOrder?.id}
                    <br />
                    Vendor Name: {deletePurchaseOrder?.vendor?.companyName}
                </DeleteModal>
            </Box>
        </>
    )
}
