/* eslint-disable prettier/prettier */
import { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Theme as MuiTheme } from '@mui/material/styles'
import {
    Box,
    Divider,
    Drawer,
    Link,
    Typography,
    useMediaQuery,
} from '@mui/material'
import { useLocation, Link as RouterLink } from 'react-router-dom'
import {
    Badge,
    Category,
    Label,
    LabelImportant,
    RequestQuote,
    ShoppingCart,
} from '@mui/icons-material'
import PersonIcon from '@mui/icons-material/Person'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined'
import GppGoodIcon from '@mui/icons-material/GppGood';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import { ShoppingBag as ShoppingBagIcon } from '../../icons/shopping-bag'
import { Users as UsersIcon } from '../../icons/users'
import { NavItem } from './NavItem'
import { APP_NAME } from '../../config/config'
import { IUserContext, UserContext } from '../../context/UserContext'
import { LogoutItem } from './LogoutItem'
import { ChartBar as ChartBarIcon } from '../../icons/chart-bar'

const adminItems = [
    {
        href: '/dashboard',
        icon: <ChartBarIcon fontSize="small" />,
        title: 'Dashboard',
    },
    {
        href: '/dashboard/customers',
        icon: <UsersIcon fontSize="small" />,
        title: 'Customers',
    },
    {
        href: '/dashboard/quotations',
        icon: <RequestQuote fontSize="small" />,
        title: 'Quotations',
    },

    {
        href: '/dashboard/vendors',
        icon: <PersonIcon fontSize="small" />,
        title: 'Vendors',
    },
    {
        href: '/dashboard/purchase-orders',
        icon: <ShoppingBagIcon fontSize="small" />,
        title: 'Purchase Orders',
    },
    {
        href: '/dashboard/all-inventory',
        icon: <InventoryOutlinedIcon fontSize="small" />,
        title: 'Inventory',
    },
    {
        href: '/dashboard/orders-admin',
        icon: <ShoppingCart fontSize="small" />,
        title: 'Orders',
    },
    {
        href:'/dashboard/shipping',
        icon: <LocalShippingIcon fontSize="small"/>,
        title : 'Shipping'
    },
    {
        href: '/dashboard/ar',
        icon: <RequestQuote fontSize="small" />,
        title: 'AR',
    },
    {
        href: '/dashboard/ap',
        icon: <RequestQuote fontSize="small" />,
        title: 'AP',
    },
    {
        href: '/dashboard/products',
        icon: <Category fontSize="small" />,
        title: 'Products',
    },
    {
        href: '/dashboard/users',
        icon: <ManageAccountsIcon fontSize="small" />,
        title: 'Users',
    },
    {
        href: '/dashboard/employees',
        icon: <Badge fontSize="small" />,
        title: 'Employees',
    },
    {
        href:'/dashboard/nonConformance',
        icon:<GppGoodIcon fontSize="small"/>,
        title: 'Quality Control'
    },
	{
		href:'/dashboard/labels',
        icon:<Label fontSize="small"/>,
        title: 'Labels'
	},
	{
		href:'/dashboard/packing-slip',
        icon:<LabelImportant fontSize="small"/>,
        title: 'Packing Slip'
	},

]
const shopItems = [
    {
        href: '/shop/work-orders',
        icon: <ShoppingCart fontSize="small" />,
        title: 'Work Orders',
    },
    {
        href:'/shop/add-attendance',
        icon:<CoPresentIcon fontSize='small'/>,
        title:'Add Attendance'
    },
]

interface IDashboardSidebar {
    open: boolean
    onClose?: () => void
}
export const DashboardSidebar = (props: IDashboardSidebar): ReactNode => {
    const { data } = useContext(UserContext) as IUserContext
    const { user } = data
    const { open, onClose } = props
    const router = useLocation()
    const lgUp = useMediaQuery(
        (theme: MuiTheme) => theme.breakpoints.up('lg'),
        {
            defaultMatches: true,
            noSsr: false,
        }
    )

    let linkTo = "/"
    if(user?.role === 'ADMIN') linkTo = "/dashboard"
    if(user?.role === 'SHOP') linkTo = "/shop/work-orders"

    useEffect(
        () => {
            if (open) {
                onClose?.()
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [router.pathname]
    )

    const content = (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
                textAlign='center'
            >
                <div>
                    <Box sx={{ p: 3 }}>
                        <Link component={RouterLink} to={linkTo} >
                            <img  alt="ace-grinding" src="/ace-grinding-2.png" height={105} width={150}/>
                            
                        </Link>
                    </Box>
                    <Box sx={{ px: 2 }}>
                        <Box
                            sx={{
                                alignItems: 'center',
                                backgroundColor: 'rgba(255, 255, 255, 0.04)',
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'space-between',
                                px: 3,
                                py: '11px',
                                borderRadius: 1,
                            }}
                        >
                            <div>
                                <Typography color="inherit" variant="subtitle1">
                                    {APP_NAME}
                                </Typography>
                                <Typography color="neutral.400" variant="body2">
                                    {user?.name}
                                </Typography>
                            </div>
                            
                        </Box>
                    </Box>
                </div>
                <Divider
                    sx={{
                        borderColor: '#2D3748',
                        my: 3,
                    }}
                />
                <Box sx={{ flexGrow: 1 }}>
                    {
                        user.role === 'ADMIN'  &&
                            adminItems.map((item) => (
                                <NavItem
                                    key={item.title}
                                    icon={item.icon}
                                    href={item.href}
                                    title={item.title}
                                />
                            ))
                    }
                    {
                        user.role === 'SHOP'  &&
                        shopItems.map((item) => (
                                <NavItem
                                    key={item.title}
                                    icon={item.icon}
                                    href={item.href}
                                    title={item.title}
                                />
                            ))
                    }
                    
                </Box>
                <LogoutItem />
            </Box>
        </>
    )

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        backgroundColor: 'neutral.900',
                        color: '#FFFFFF',
                        width: 280,
                    },
                }}
                variant="permanent"
            >
                {content}
            </Drawer>
        )
    }

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    backgroundColor: 'neutral.900',
                    color: '#FFFFFF',
                    width: 280,
                },
            }}
            sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
            variant="temporary"
        >
            {content}
        </Drawer>
    )
}

DashboardSidebar.defaultProps = {
    onClose: undefined,
    open: false,
}

DashboardSidebar.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
}
