import { InMemoryCache, ApolloClient } from '@apollo/client'

const client = new ApolloClient({
    cache: new InMemoryCache({
        addTypename: false,
    }),
    uri: process.env.REACT_APP_GRAPHQL_SERVER || 'http://localhost/graphql',
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-and-network',
            returnPartialData: true,
        },
        query: {
            fetchPolicy: 'cache-first',
        },
    },
})

export default client
