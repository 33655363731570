/* eslint-disable jsx-a11y/label-has-associated-control */
import { Add } from '@mui/icons-material'
import { Grid, Button, TextField } from '@mui/material'
import { useState, useContext } from 'react'
import { styled } from '@mui/material/styles'
import { useMutation } from '@apollo/client'
import { UploadFileModal } from '../Modal/UploadFileModal'
import { MutationUploadImageFile } from '../../common/Mutations'
import { IMessageContext, MessageContext } from '../../context/MessageContext'

const Input = styled('input')({
    display: 'none',
})

export interface IUploadFile {
    quotation?: string
    order?: string
    inventory?: string
}

const getBase64 = (file, cb): void => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
        cb(reader.result)
    }
}

export const UploadImage = ({
    quotation,
    order,
    inventory,
}: IUploadFile): ReactNode => {
    const [uploadFile] = useMutation(MutationUploadImageFile)
    const { showSnackbar } = useContext(MessageContext) as IMessageContext

    const [uploading, setUploading] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [imageFiles, setImageFiles] = useState({
        fileName: '',
        description: '',
        file: null,
    })

    const handleUpload = async (): Promise<void> => {
        if (!imageFiles.file) {
            showSnackbar('Please select file to upload', true)
            return
        }
        try {
            setUploading(true)
            getBase64(imageFiles.file, async (result) => {
                try {
                    await uploadFile({
                        variables: {
                            file: result,
                            bucketFileName: imageFiles.file.name,
                            fileName: imageFiles.fileName,
                            description: imageFiles.description,
                            quotation,
                            order,
                            inventory,
                        },
                    })
                } catch (error) {
                    setImageFiles({
                        fileName: '',
                        description: '',
                        file: null,
                    })
                    setOpenModal(false)
                    showSnackbar('File size is too large', true)
                }
            })
            setImageFiles({
                fileName: '',
                description: '',
                file: null,
            })
            setUploading(false)
            setOpenModal(false)
            showSnackbar(
                'Uploading in progress, it will be available for download once uploaded'
            )
        } catch (err) {
            setImageFiles({
                fileName: '',
                description: '',
                file: null,
            })
            setOpenModal(false)
            showSnackbar(err.message, true)
        }
    }

    return (
        <>
            <Grid item xs={12}>
                <Button
                    startIcon={<Add />}
                    style={{ color: 'green' }}
                    onClick={() => setOpenModal(true)}
                >
                    Upload File
                </Button>
            </Grid>
            <UploadFileModal
                dialogTitle="Upload File"
                open={openModal}
                onClose={() => {
                    setImageFiles({
                        fileName: '',
                        description: '',
                        file: null,
                    })
                    setOpenModal(false)
                }}
                onUpload={handleUpload}
                uploading={uploading}
            >
                <Grid item container alignItems="center" spacing={2}>
                    <Grid item sm={12} md={3}>
                        <TextField
                            label="File Name"
                            name="fileName"
                            fullWidth
                            onChange={(e) => {
                                setImageFiles({
                                    ...imageFiles,
                                    fileName: e.target.value,
                                })
                            }}
                            value={imageFiles.fileName}
                            type="text"
                        />
                    </Grid>
                    <Grid item sm={12} md={7.5}>
                        <TextField
                            label="Description"
                            name="description"
                            value={imageFiles.description}
                            onChange={(e) => {
                                setImageFiles({
                                    ...imageFiles,
                                    description: e.target.value,
                                })
                            }}
                            fullWidth
                            type="text"
                        />
                    </Grid>
                    <Grid item sm={12} md={1}>
                        <label>
                            <Input
                                style={{ display: 'none' }}
                                type="file"
                                onChange={(e) => {
                                    setImageFiles({
                                        ...imageFiles,
                                        file: e.target.files[0],
                                    })
                                }}
                            />
                            <Button
                                color="info"
                                variant="outlined"
                                size="large"
                                aria-label="upload File"
                                component="span"
                            >
                                Browse
                            </Button>
                        </label>
                    </Grid>
                </Grid>
            </UploadFileModal>
        </>
    )
}
