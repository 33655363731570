/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import { Box, Container, Grid } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import MoneyIcon from '@mui/icons-material/Money'
import PeopleIcon from '@mui/icons-material/PeopleOutlined'
import { RequestQuote, Badge } from '@mui/icons-material'
import { useQuery } from '@apollo/client'
import { format } from 'date-fns'
import { APP_NAME } from '../../config/config'
import { TopCard } from '../../components/dashboard/TopCard'
import { BarChart } from '../../components/dashboard/BarChart.js'
import { LineChart } from '../../components/dashboard/LineChart.js'
import { DataTable } from '../../components/dashboard/DataTable.js'
import {
    queryGetCount,
    queryGetFiveInvoiceAR,
    queryGetFiveOrders,
    queryGetLastMonthQuotationOrdersRevenue,
} from '../../common/queries'

const Dashboard = (): ReactNode => {
    const { data: countData, loading } = useQuery(queryGetCount)
    const { data: invoiceAR, loading: invoiceARLoading } = useQuery(
        queryGetFiveInvoiceAR
    )
    const { data: orders, loading: orderLoading } = useQuery(queryGetFiveOrders)
    const {
        data: LastMonthOrderAndQuotaionData,
        loading: RevDataLoading,
    } = useQuery(queryGetLastMonthQuotationOrdersRevenue)

    const [topData, setTopCount] = useState([])
    const [invoiceARData, setInvoiceARDate] = useState([])
    const [orderData, setOrderData] = useState([])

    const [OrderRevDates, setOrderRevDates] = useState([])
    const [OrderRevData, setRevData] = useState([])

    const [quotationDate, setQuotationDate] = useState([])
    const [quotaionAmount, setQuotationAmount] = useState([])

    useEffect(() => {
        const dateTemp = []
        const amountTemp = []

        const quotationDateTemp = []
        const quotationAmountTemp = []

        if (
            !RevDataLoading &&
            LastMonthOrderAndQuotaionData &&
            LastMonthOrderAndQuotaionData?.getLastMonthQuotationAndOrderRevenue
        ) {
            LastMonthOrderAndQuotaionData?.getLastMonthQuotationAndOrderRevenue?.orderRevenue?.map(
                (item) => {
                    dateTemp.push(format(Number(item.date), 'd LLL'))
                    amountTemp.push(item.amount)
                }
            )
            LastMonthOrderAndQuotaionData?.getLastMonthQuotationAndOrderRevenue?.quotationRevenue?.map(
                (item) => {
                    quotationDateTemp.push(format(Number(item.date), 'd LLL'))
                    quotationAmountTemp.push(item.amount)
                }
            )

            setRevData(amountTemp)
            setOrderRevDates(dateTemp)

            setQuotationDate(quotationDateTemp)
            setQuotationAmount(quotationAmountTemp)
        }
    }, [RevDataLoading, LastMonthOrderAndQuotaionData])

    useEffect(() => {
        if (!orderLoading && orders && orders?.getFiveOrders) {
            setOrderData(orders?.getFiveOrders)
        }
    }, [orders, orderLoading])

    useEffect(() => {
        if (!invoiceARLoading && invoiceAR && invoiceAR?.getFiveInvoicesAR) {
            setInvoiceARDate(invoiceAR?.getFiveInvoicesAR)
        }
    }, [invoiceAR, invoiceARLoading])

    useEffect(() => {
        if (!loading && countData && countData?.getCount) {
            const temp = [
                {
                    id: 1,
                    heading: 'Customers',
                    value: countData?.getCount?.customerCount,
                    icon: <PeopleIcon fontSize="medium" />,
                    iconBgColor: 'success.main',
                },
                {
                    id: 2,
                    heading: 'Orders',
                    value: countData?.getCount?.orderCount,
                    icon: <MoneyIcon fontSize="medium" />,
                    iconBgColor: 'secondary.dark',
                },
                {
                    id: 3,
                    heading: 'Quotations',
                    value: countData?.getCount?.quotationCount,
                    icon: <RequestQuote fontSize="medium" />,
                    iconBgColor: 'error.main',
                },
                {
                    id: 4,
                    heading: 'Vendors',
                    value: countData?.getCount?.vendorCount,
                    icon: <Badge fontSize="medium" />,
                    iconBgColor: 'warning.main',
                },
            ]
            setTopCount(temp)
        }
    }, [countData, loading])
    return (
        <>
            <Helmet>
                <title>Dashboard | {APP_NAME}</title>
            </Helmet>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8,
                }}
            >
                <Container maxWidth={false}>
                    <Grid container spacing={3}>
                        {topData.map((item) => (
                            <Grid item md={3}>
                                <TopCard
                                    key={item.id}
                                    heading={item.heading}
                                    value={item.value}
                                    iconBgColor={item.iconBgColor}
                                    icon={item.icon}
                                />
                            </Grid>
                        ))}
                        <Grid item md={6}>
                            <BarChart
                                dataSet={OrderRevData}
                                labelSet={OrderRevDates}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <LineChart
                                dataSet={quotaionAmount}
                                label={quotationDate}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <DataTable
                                data={invoiceARData}
                                heading="Latest Invoices"
                                navigateTo="/dashboard/ar"
                                buttonText="Invoices"
                            />
                        </Grid>
                        <Grid item md={6}>
                            <DataTable
                                data={orderData}
                                heading="Latest Orders"
                                navigateTo="/dashboard/orders-admin"
                                buttonText="Orders"
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default Dashboard
