import { Box, Button, Container } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { useMemo, useState, useEffect } from 'react'
import Add from '@mui/icons-material/Add'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { APP_NAME } from '../../config/config'
import { TableToolbar } from '../../components/Table/TableToolbar'
import { applySortFilter } from '../../utils/searchHelper'
import { DynamicTable } from '../../components/Table/DynamicTable'
import { queryGetAllUser } from '../../common/queries'

const columns = [
    { name: 'Name', key: 'name' },
    { name: 'Email', key: 'email' },
    { name: 'Role', key: 'role' },
]

const Users = (): ReactNode => {
    const { data: allUsers, loading } = useQuery(queryGetAllUser)

    const navigate = useNavigate()
    const [isLoading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<IUser[]>([])

    const [searchText, setSearchText] = useState('')

    useEffect(() => {
        setLoading(loading)
        if (!loading && allUsers && allUsers?.getAllUsers) {
            setData(allUsers?.getAllUsers)
        }
    }, [allUsers, loading])

    const handleSearchTextChanged = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ): void => {
        setSearchText(event.target.value)
    }

    const filteredData: IUser[] = applySortFilter<IUser>(
        data,
        'asc',
        'name',
        searchText,
        'name'
    )

    return (
        <>
            <Helmet>
                <title>Users | {APP_NAME}</title>
            </Helmet>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8,
                }}
            >
                <Container maxWidth={false}>
                    <TableToolbar
                        title="Users"
                        searchText={searchText}
                        onSearchTextChanged={handleSearchTextChanged}
                        placeHolder="Search Users"
                    >
                        <Button
                            component={RouterLink}
                            to="/dashboard/user"
                            startIcon={<Add />}
                            color="primary"
                            variant="contained"
                            size="large"
                        >
                            Add User
                        </Button>
                    </TableToolbar>

                    {useMemo(
                        () => (
                            <Box sx={{ mt: 3 }}>
                                <DynamicTable
                                    isLoading={isLoading}
                                    columns={columns}
                                    data={filteredData}
                                    onRowClick={(item) => {
                                        navigate(`/dashboard/user/${item.id}`, {
                                            state: {
                                                user: item,
                                            },
                                        })
                                    }}
                                />
                            </Box>
                        ),
                        [filteredData, isLoading, navigate]
                    )}
                </Container>
            </Box>
        </>
    )
}

export default Users
