import { AceGrindingImageData } from '../icons/ace-grinding'

const imageScale = 0.07
export const generatePDFContent = (
    workOrder: IOrderAdmin,
    workOrderItemDescription: string,
    labels: ILabelItem[]
): Array<any> => {
    const labelItems = labels.filter(
        (item) =>
            item.pkg &&
            item.pkgType &&
            item.heatInfo &&
            item.net &&
            item.gross &&
            item.tare &&
            item.pieceCount
    )
    return [
        {
            // columnGap: 10,
            margin: [0, 0, 0, 10],
            columns: [
                {
                    width: '38%',
                    columns: [
                        {
                            image: AceGrindingImageData.image,
                            width: AceGrindingImageData.width * imageScale,
                            height: AceGrindingImageData.height * imageScale,
                            style: ['center'],
                            alignment: 'center',
                        },
                    ],
                    style: ['center'],
                    alignment: 'center',
                },
                {
                    margin: [0, 10, 0, 0],
                    width: '24%',
                    text: `Order ID:  ${workOrder.id}`,
                    fontSize: 12,
                },
                {
                    width: '38%',
                    layout: 'noBorders',
                    table: {
                        widths: [100],
                        body: [
                            [
                                {
                                    text: workOrder?.shipToCustomerName,
                                    bold: true,
                                },
                            ],
                            [
                                {
                                    text: workOrder?.customer?.primaryContact,
                                    bold: true,
                                },
                            ],
                            [
                                {
                                    text: workOrder?.customer?.primaryAddress,
                                    bold: true,
                                },
                            ],
                            [
                                {
                                    text: `${
                                        workOrder?.customer?.city || ''
                                    }, ${workOrder?.customer?.state || ''}`,
                                    bold: true,
                                },
                            ],
                            [
                                {
                                    text: workOrder?.customer?.postalCode,
                                    bold: true,
                                },
                            ],
                        ],
                    },
                    // margin: [0, 10, 0, 0],
                },
            ],
        },
        {
            table: {
                widths: ['*', '*', '*', '*', '*', '*', '*'],
                body: [
                    [
                        {
                            text: 'Packages',
                            bold: true,
                            style: ['center'],
                            fontSize: 10,
                        },
                        {
                            text: 'Package Type',
                            bold: true,
                            style: ['center'],
                            fontSize: 10,
                        },
                        {
                            text: 'Scaled Qty',
                            bold: true,
                            style: ['center'],
                            fontSize: 10,
                        },
                        {
                            text: 'Heat Info',
                            bold: true,
                            style: ['center'],
                            fontSize: 10,
                        },
                        {
                            text: 'Tare',
                            bold: true,
                            style: ['center'],
                            fontSize: 10,
                        },
                        {
                            text: 'Net',
                            bold: true,
                            style: ['center'],
                            fontSize: 10,
                        },
                        {
                            text: 'Gross',
                            bold: true,
                            style: ['center'],
                            fontSize: 10,
                        },
                    ],
                    ...labelItems.map((item) => [
                        {
                            text: item.pkg,
                            style: ['center'],
                        },
                        {
                            text: item.pkgType,
                            style: ['center'],
                        },
                        {
                            text: item.pieceCount,
                            style: ['center'],
                        },
                        {
                            text: item.heatInfo,
                            style: ['center'],
                        },
                        {
                            text: item.tare.toFixed(5),
                            style: ['center'],
                        },
                        {
                            text: item.net.toFixed(5),
                            style: ['center'],
                        },
                        {
                            text: item.gross.toFixed(5),
                            style: ['center'],
                        },
                    ]),
                ],
            },
            // margin: [0, 10, 0, 0],
        },

        {
            text: '',
            pageBreak: 'after',
        },

        ...labelItems.map((item, index) => [
            {
                // columnGap: 10,
                margin: [0, 0, 0, 10],
                columns: [
                    {
                        width: '38%',
                        columns: [
                            {
                                image: AceGrindingImageData.image,
                                width: AceGrindingImageData.width * imageScale,
                                height:
                                    AceGrindingImageData.height * imageScale,
                                style: ['center'],
                                alignment: 'center',
                            },
                        ],
                        style: ['center'],
                        alignment: 'center',
                    },
                    {
                        margin: [0, 10, 0, 0],
                        width: '24%',
                        text: `Order ID:  ${workOrder.id}`,
                        fontSize: 12,
                    },
                    {
                        width: '38%',
                        layout: 'noBorders',
                        table: {
                            widths: [100],
                            body: [
                                [
                                    {
                                        text: workOrder?.shipToCustomerName,
                                        bold: true,
                                    },
                                ],
                                [
                                    {
                                        text:
                                            workOrder?.customer?.primaryContact,
                                        bold: true,
                                    },
                                ],
                                [
                                    {
                                        text:
                                            workOrder?.customer?.primaryAddress,
                                        bold: true,
                                    },
                                ],
                                [
                                    {
                                        text: `${
                                            workOrder?.customer?.city || ''
                                        }, ${workOrder?.customer?.state || ''}`,
                                        bold: true,
                                    },
                                ],
                                [
                                    {
                                        text: workOrder?.customer?.postalCode,
                                        bold: true,
                                    },
                                ],
                            ],
                        },
                        // margin: [0, 10, 0, 0],
                    },
                ],
            },
            {
                margin: [0, 0, 0, 10],
                columnGap: 5,
                columns: [
                    {
                        width: 80,

                        // layout: 'noBorders',
                        table: {
                            widths: ['*'],
                            body: [
                                [
                                    {
                                        text: 'PO Number',
                                        bold: true,
                                        style: ['center'],
                                    },
                                ],
                                [
                                    {
                                        text: workOrder.pONumber,
                                        style: ['center'],
                                        margin: [0, 5.25, 0, 5.25],
                                    },
                                ],
                            ],
                        },
                        // margin: [0, 10, 0, 0],
                    },
                    {
                        width: '*',
                        // layout: 'noBorders',
                        table: {
                            widths: ['*'],
                            body: [
                                [
                                    {
                                        text: 'Description',
                                        bold: true,
                                        style: ['center'],
                                    },
                                ],
                                [
                                    {
                                        // eslint-disable-next-line max-len
                                        text: workOrderItemDescription,
                                        style: ['center'],
                                        margin: [0, 5.25, 0, 5.25],
                                    },
                                ],
                            ],
                        },
                        // margin: [0, 10, 0, 0],
                    },
                ],
            },
            {
                table: {
                    widths: ['*', '*', '*', '*', '*', '*', '*'],
                    body: [
                        [
                            {
                                text: '',
                                bold: true,
                                style: ['center'],
                                border: [false, false, false, false],
                            },
                            {
                                text: '',
                                bold: true,
                                style: ['center'],
                                border: [false, false, false, false],
                            },
                            {
                                text: 'Scaled Qty',
                                bold: true,
                                style: ['center'],
                                fontSize: 10,
                            },
                            {
                                text: 'Heat Info',
                                bold: true,
                                style: ['center'],
                                fontSize: 10,
                            },
                            {
                                text: 'Tare',
                                bold: true,
                                style: ['center'],
                                fontSize: 10,
                            },
                            {
                                text: 'Net',
                                bold: true,
                                style: ['center'],
                                fontSize: 10,
                            },
                            {
                                text: 'Gross',
                                bold: true,
                                style: ['center'],
                                fontSize: 10,
                            },
                        ],
                        [
                            {
                                text: item.pkg,
                            },
                            {
                                text: item.pkgType,
                            },
                            {
                                text: item.pieceCount,
                            },
                            {
                                text: item.heatInfo,
                            },

                            {
                                text: item.tare,
                            },
                            {
                                text: item.net,
                            },
                            {
                                text: item.gross,
                            },
                        ],
                    ],
                },
                // margin: [0, 10, 0, 0],
            },
            {
                text: '',
                pageBreak: index < labelItems.length - 1 ? 'after' : '',
            },
        ]),
    ]
}
