/* eslint-disable no-param-reassign */
import { format } from 'date-fns'
import { AceGrindingImageData } from '../icons/ace-grinding'

const calculator = (order: IOrderAdmin): IOrderAdmin => {
    const orderAdmin: IOrderAdmin = JSON.parse(JSON.stringify(order))
    orderAdmin.orderAdminChs.forEach((item, index, array) => {
        const currValues = item

        let bars = 0
        let grossLB = 0
        let netLB = 0
        let stockRemoval = 0
        let tolerance = 0

        // if qty is in bars (typeLorB == B) then totalBars = qty enter by user
        if (currValues?.typeLorB === 'B') {
            bars = currValues.quantity
        } else if (currValues?.typeLorB === 'L') {
            grossLB = currValues.quantity
        }

        stockRemoval = item.startSize - item.finishSizeHigh
        tolerance = item.finishSizeHigh - item.finishSizeLow

        const unitWeight = (currValues?.grade as any)?.unitWeight || 0
        // if its Ace's material
        let netWeight = 0
        const firstPart =
            currValues?.finishSizeHigh *
            currValues?.finishSizeHigh *
            (unitWeight / 12) *
            (currValues?.feet / (currValues?.feet * 12))

        netWeight =
            firstPart *
            (currValues?.feet * 12 + currValues.inches) *
            currValues?.quantity

        // if (currValues.typeAorC === 'A') {
        //     if (currValues.typeLorB === 'B') {
        //         netLB = netWeight
        //         grossLB = netWeight
        //     } else {
        //         netLB = currValues.quantity

        //         const totalBars =
        //             currValues.quantity /
        //             (currValues.finishSizeHigh *
        //                 currValues.finishSizeHigh *
        //                 unitWeight)
        //         bars = Math.round(totalBars)
        //     }
        // } else if (currValues.typeAorC === 'C') {
        if (currValues.typeLorB === 'B') {
            netLB = netWeight

            const grossFirstPart =
                currValues?.startSize *
                currValues?.startSize *
                (unitWeight / 12) *
                (currValues?.feet / (currValues?.feet * 12))

            const grossWeight =
                grossFirstPart *
                (currValues?.feet * 12 + currValues?.inches) *
                currValues?.quantity
            grossLB = grossWeight
        } else {
            const totalBars =
                currValues.quantity /
                (currValues.startSize * currValues.startSize * unitWeight)
            bars = Math.round(totalBars)

            const netLb =
                totalBars *
                currValues.finishSizeHigh *
                currValues.finishSizeHigh *
                unitWeight

            netLB = netLb
        }
        // }

        ;(array[index] as any).bars = bars
        ;(array[index] as any).grossLB = grossLB
        ;(array[index] as any).netLB = netLB
        ;(array[index] as any).stockRemoval = stockRemoval
        ;(array[index] as any).tolerance = tolerance
    })

    return orderAdmin
}

const imageScale = 0.095
export const generatePDFContent = (orderAdmin: IOrderAdmin): Array<any> => {
    const workOrder = calculator(orderAdmin)

    return [
        ...workOrder.orderAdminChs.map((item: IOrderAdminChs, index) => [
            {
                columns: [
                    {
                        width: '*',
                        columns: [
                            {
                                image: AceGrindingImageData.image,
                                width: AceGrindingImageData.width * imageScale,
                                height:
                                    AceGrindingImageData.height * imageScale,
                                style: ['center'],
                                alignment: 'center',
                            },
                        ],
                        style: ['center'],
                        alignment: 'center',
                    },
                    {
                        width: '*',
                        text: 'WORK ORDER',
                        style: ['mainHeading', 'center'],
                    },
                    {
                        layout: 'noBorders',
                        table: {
                            widths: [60, '*'],
                            body: [
                                [
                                    {
                                        text: 'Order #:',
                                        bold: true,
                                        fontSize: 14,
                                    },
                                    { text: workOrder.id, fontSize: 14 },
                                ],
                            ],
                        },
                        margin: [0, 10, 0, 0],
                    },
                ],

                margin: [0, 0, 0, 10],
            },

            {
                margin: [0, 0, 0, 10],
                table: {
                    headerRows: 1,
                    widths: ['*', '*', '*'],
                    fontSize: 12,
                    body: [
                        [
                            {
                                text: 'Customer Name',
                                bold: true,
                                fontSize: 12,
                            },
                            {
                                text: 'PO Number',
                                bold: true,
                                fontSize: 12,
                            },
                            {
                                text: 'Due Date',
                                bold: true,
                                fontSize: 12,
                            },
                        ],
                        [
                            {
                                text: workOrder.customer.companyName,
                                fontSize: 12,
                            },
                            { text: workOrder.pONumber, fontSize: 12 },
                            {
                                text: format(
                                    new Date(item.dueDate),
                                    'dd-MM-yyyy'
                                ),
                                fontSize: 12,
                            },
                        ],
                    ],
                },
            },
            {
                table: {
                    // headers are automatically repeated if the table spans over multiple pages
                    // you can declare how many rows should be treated as headers
                    headerRows: 1,

                    widths: [35, '*'],

                    body: [
                        [
                            {
                                text: 'Item #',
                                bold: true,
                                fontSize: 12,
                            },
                            {
                                text: 'Description',
                                bold: true,
                                fontSize: 12,
                            },

                            // {
                            //     text: 'Unit Price',
                            //     bold: true,
                            // },
                            // {
                            //     text: 'Total Price',
                            //     bold: true,
                            // },
                        ],

                        [
                            { text: index + 1, margin: 2, fontSize: 12 },
                            [
                                {
                                    columns: [
                                        {
                                            width: 220,
                                            margin: [5, 5, 5, 0],
                                            lineHeight: 2,
                                            fontSize: 12,
                                            text: `Start Size: \t\t${item.startSize.toFixed(
                                                5
                                            )} 
								Finish Size:  ${item.finishSizeHigh.toFixed(5)} /  ${item.finishSizeLow.toFixed(
                                                5
                                            )}
								Grade: ${(item.grade as any).label || item.grade || ''}
								Heat #: ${item.heatNo}
								Gross Weight: ${item.grossLB.toFixed(0)}
                                Net Weight: ${item.netLB.toFixed(0)}
								Packaging: ${item.packaging || ''}`,
                                        },
                                        {
                                            width: '*',
                                            text: `${item.feet}' ${item.inches}"
								${item.rms} RMS`,

                                            // Net Weight: ${item.netLB.toFixed(5)}`,
                                            margin: [5, 5, 5, 0],
                                            lineHeight: 2,
                                            fontSize: 12,
                                        },
                                        {
                                            width: '*',
                                            text: `Stock Removal: .${
                                                item.stockRemoval
                                                    .toFixed(5)
                                                    .split('.')[1]
                                            }
								Total Tolerance: .${item.tolerance.toFixed(5).split('.')[1]}
								${
                                    item?.inventory
                                        ? `Vendor: ${item?.inventory?.vendor?.companyName}
								Material ID:  ${item?.inventory?.id}`
                                        : '\n'
                                }
								Bars: ${item.bars}`,
                                            margin: [5, 5, 5, 0],
                                            lineHeight: 2,
                                            fontSize: 12,
                                        },
                                    ],
                                },
                                {
                                    margin: [5, 5, 5, 0],
                                    text: `Job Notes: ${item.notes || ''}`,
                                    color: 'red',
                                    fontSize: 12,
                                },
                            ],
                        ],
                    ],
                },
                layout: {
                    // hLineWidth(i, node) {
                    //     return i === 0 || i === 1 || i === node.table.body.length
                    //         ? 1
                    //         : 0
                    // },
                    vLineWidth(i) {
                        return i === 0 || i === 1 || i === 2 ? 1 : 0
                    },
                    // paddingTop(i, node) {
                    //     if (i === node.table.body.length - 1) {
                    //         return 570 -
                    //             (node.positions[node.positions.length - 1].top - 60) >
                    //             0
                    //             ? 570 -
                    //                   (node.positions[node.positions.length - 1].top -
                    //                       60)
                    //             : 570
                    //     }
                    //     return 0
                    // },
                },
            },
            {
                text: '',
                pageBreak:
                    index < workOrder.orderAdminChs.length - 1 ? 'after' : '',
            },
        ]),
    ]
}
