import {
    Grid,
    Button,
    Box,
    Card,
    Typography,
    TextField,
    Autocomplete,
} from '@mui/material'
import { Formik, Form, Field } from 'formik'
import { LoadingButton } from '@mui/lab'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { useDebouncedCallback } from 'use-debounce'
import { MessageContext, IMessageContext } from '../../context/MessageContext'
import { queryGetOrderAdminById } from '../../common/queries'
import { NumberFormatter } from '../../utils/NumberFormatter'
import { LabelItems } from './LabelItems'
import { generatePDF } from '../../pdfs/pdf'
import { generatePDFContent } from '../../pdfs/LabelPDF'

declare global {
    interface ILabelItem {
        pkg: number
        pkgType: 'Box' | 'Bundled' | 'Tube'
        heatInfo: string
        pieceCount: number
        tare: number
        net: number
        gross: number
    }
}

// const MILLIMETERS_IN_INCH = 25.4
const POINTS_IN_INCH = 72

// function mmToPoints(mm: number): number {
//     const inches = mm / MILLIMETERS_IN_INCH
//     return inches * POINTS_IN_INCH
// }

function inchesToPoints(inches: number): number {
    return inches * POINTS_IN_INCH
}

export const Labels = (): ReactNode => {
    const [getOrderAdminById, { data: orderData, loading }] = useLazyQuery(
        queryGetOrderAdminById
    )

    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const navigate = useNavigate()

    const handleGeneratePDF = async (
        values,
        setSubmitting
    ): Promise<boolean> => {
        try {
            setSubmitting(true)
            generatePDF(
                generatePDFContent(
                    orderData.getOrderAdminById,
                    values.orderItem,
                    (values.labelItems as any) as ILabelItem[]
                ),
                null,
                {
                    pageOrientation: 'landscape',
                    pageSize: {
                        width: inchesToPoints(6),
                        height: inchesToPoints(4),
                    },
                    pageMargins: [20, 20, 20, 20],
                }
            )

            setSubmitting(false)
            showSnackbar('Success !!')

            return true
        } catch (error) {
            setSubmitting(false)
            showSnackbar(error.message, true)
            return false
        }
    }

    const formikConfig = {
        enableReinitialize: true,
        initialValues: {
            orderAdminId: '',
            orderItem: '',
            labelItems: Array(5).fill({}) as ILabelItem[],
        },
    }

    const descriptions: any[] =
        orderData?.getOrderAdminById?.orderAdminChs.map(
            (item: IOrderAdminChs, index) => ({
                label: `${item.grade}\t\t${item.finishSizeHigh.toFixed(
                    5
                )}  /  ${item.finishSizeLow.toFixed(5)}\t\t${item.feet}  FT`,
                value: index,
            })
        ) || []

    const debounced = useDebouncedCallback((value) => {
        getOrderAdminById({
            variables: {
                id: value,
            },
        })
    }, 800)

    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                py: 8,
                px: 4,
            }}
        >
            <Card>
                <Typography variant="h4" sx={{ my: 2, mx: 2 }}>
                    Print Labels
                </Typography>
                <Box>
                    <Formik
                        {...formikConfig}
                        onSubmit={(values, { setSubmitting }) => {
                            handleGeneratePDF(values, setSubmitting)
                        }}
                    >
                        {(formik) => (
                            <Form
                                onSubmit={(e) => {
                                    formik.handleSubmit(e)
                                }}
                            >
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ py: 2, px: 2 }}
                                >
                                    <Grid item xs={12} sm={6} md={2}>
                                        <Field
                                            error={Boolean(
                                                formik.touched.orderAdminId &&
                                                    formik.errors.orderAdminId
                                            )}
                                            helperText={
                                                formik.touched.orderAdminId &&
                                                formik.errors.orderAdminId
                                            }
                                            as={TextField}
                                            label="Order ID"
                                            name="orderAdminId"
                                            fullWidth
                                            onChange={(event) => {
                                                debounced(event.target.value)

                                                formik.setFieldValue(
                                                    'orderAdminId',
                                                    event.target.value
                                                )
                                                formik.setFieldValue(
                                                    'orderItem',
                                                    ''
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            label="Customer Name"
                                            value={
                                                orderData?.getOrderAdminById
                                                    ?.customer?.companyName ||
                                                ''
                                            }
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            label="Customer Address"
                                            value={
                                                orderData?.getOrderAdminById
                                                    ?.shipToCustomerAddress ||
                                                ''
                                            }
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            label="PO Number"
                                            value={
                                                orderData?.getOrderAdminById
                                                    ?.pONumber || ''
                                            }
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6}>
                                        <Autocomplete
                                            options={descriptions}
                                            autoSelect
                                            disableClearable
                                            value={
                                                descriptions[
                                                    formik.values.orderItem
                                                ]
                                            }
                                            loading={loading}
                                            isOptionEqualToValue={(
                                                option,
                                                value
                                            ) =>
                                                (option as any).label === value
                                            }
                                            onChange={(event, newValue) => {
                                                formik.setFieldValue(
                                                    'orderItem',
                                                    newValue.label
                                                )
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Order Item"
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <LabelItems formik={formik} />

                                    <Grid
                                        item
                                        container
                                        // alignItems="cent"
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Grid
                                            item
                                            xs="auto"
                                            sx={{ minWidth: 56 }}
                                        >
                                            Total
                                        </Grid>

                                        <Grid
                                            item
                                            container
                                            spacing={1}
                                            sm={11}
                                            md={11}
                                        >
                                            <Grid item xs={1.5}>
                                                <TextField
                                                    label="Total Packages"
                                                    value={NumberFormatter(
                                                        formik.values.labelItems.reduce(
                                                            (n, item) =>
                                                                n +
                                                                (item.pkg || 0),
                                                            0
                                                        ),
                                                        0
                                                    )}
                                                    type="text"
                                                    fullWidth
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={1.5} />
                                            <Grid item xs={2.5} />
                                            <Grid item xs={2}>
                                                <TextField
                                                    label="Total Piece Count"
                                                    value={NumberFormatter(
                                                        formik.values.labelItems.reduce(
                                                            (n, item) =>
                                                                n +
                                                                (item.pieceCount ||
                                                                    0),
                                                            0
                                                        )
                                                    )}
                                                    type="text"
                                                    fullWidth
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={1.5}>
                                                <TextField
                                                    label="Total Tare"
                                                    value={NumberFormatter(
                                                        formik.values.labelItems.reduce(
                                                            (n, item) =>
                                                                n +
                                                                (item.tare ||
                                                                    0),
                                                            0
                                                        ),
                                                        0
                                                    )}
                                                    type="text"
                                                    fullWidth
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={1.5}>
                                                <TextField
                                                    label="Total Net"
                                                    value={NumberFormatter(
                                                        formik.values.labelItems.reduce(
                                                            (n, item) =>
                                                                n +
                                                                (item.net || 0),
                                                            0
                                                        )
                                                    )}
                                                    type="text"
                                                    fullWidth
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={1.5}>
                                                <TextField
                                                    label="Total Gross"
                                                    value={NumberFormatter(
                                                        formik.values.labelItems.reduce(
                                                            (n, item) =>
                                                                n +
                                                                (item.gross ||
                                                                    0),
                                                            0
                                                        )
                                                    )}
                                                    type="text"
                                                    fullWidth
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={12} sm={6} md={4} />
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={6} sm={4} md={3}>
                                            <Button
                                                color="error"
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                variant="outlined"
                                                fullWidth
                                                onClick={() => {
                                                    formik.resetForm()
                                                    navigate('/dashboard')
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={3}>
                                            <LoadingButton
                                                color="success"
                                                loadingPosition="end"
                                                loading={formik.isSubmitting}
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                fullWidth
                                            >
                                                Generate PDF
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Card>
        </Box>
    )
}
