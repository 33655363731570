import { gql } from '@apollo/client'

export const MutationCreateEmployee = gql`
    mutation addEmployee(
        $name: String!
        $email: String
        $address: String
        $contact: String
        $socialSecurity: String
        $notes: String
        $isActive: Boolean
    ) {
        addEmployee(
            name: $name
            email: $email
            address: $address
            contact: $contact
            socialSecurity: $socialSecurity
            notes: $notes
            isActive: $isActive
        )
    }
`

export const MutationUpdateEmployee = gql`
    mutation updateEmployee(
        $id: ID!
        $name: String
        $email: String
        $address: String
        $contact: String
        $socialSecurity: String
        $notes: String
        $isActive: Boolean
    ) {
        updateEmployee(
            id: $id
            name: $name
            email: $email
            address: $address
            contact: $contact
            socialSecurity: $socialSecurity
            notes: $notes
            isActive: $isActive
        ) {
            name
            id
        }
    }
`

export const MutationDeleteEmployeeById = gql`
    mutation deleteEmployeeById($id: ID!) {
        deleteEmployeeById(id: $id)
    }
`

export const MutationAddCustomer = gql`
    mutation addCustomer(
        $companyName: String!
        $primaryAddress: String!
        $fax: String
        $primaryContact: String
        $termsOfPayment: String
        $shipVia: String
        $city: String
        $state: String
        $postalCode: String
        $notes: String
        $secondaryContacts: [ContactInput]
        $secondaryAddresses: [AddressInput]
    ) {
        addCustomer(
            companyName: $companyName
            primaryAddress: $primaryAddress
            fax: $fax
            primaryContact: $primaryContact
            termsOfPayment: $termsOfPayment
            shipVia: $shipVia
            city: $city
            state: $state
            postalCode: $postalCode
            notes: $notes
            secondaryContacts: $secondaryContacts
            secondaryAddresses: $secondaryAddresses
        )
    }
`

export const MutationDeleteCustomerById = gql`
    mutation deleteCustomer($id: ID!) {
        deleteCustomer(id: $id)
    }
`

export const MutationUpdateCustomer = gql`
    mutation updateCustomer(
        $id: ID!
        $companyName: String
        $primaryAddress: String
        $fax: String
        $primaryContact: String
        $termsOfPayment: String
        $shipVia: String
        $city: String
        $state: String
        $postalCode: String
        $notes: String
        $secondaryContacts: [ContactInput]
        $secondaryAddresses: [AddressInput]
    ) {
        updateCustomer(
            id: $id
            companyName: $companyName
            primaryAddress: $primaryAddress
            fax: $fax
            primaryContact: $primaryContact
            termsOfPayment: $termsOfPayment
            shipVia: $shipVia
            city: $city
            state: $state
            postalCode: $postalCode
            notes: $notes
            secondaryContacts: $secondaryContacts
            secondaryAddresses: $secondaryAddresses
        ) {
            id
            companyName
        }
    }
`

export const MutationDeleteVendor = gql`
    mutation deleteVendor($id: ID!) {
        deleteVendor(id: $id)
    }
`

export const MutationAddVendor = gql`
    mutation addVendor(
        $companyName: String!
        $primaryAddress: String!
        $fax: String
        $primaryContact: String!
        $termsOfPayment: String!
        $notes: String
        $state: String
        $city: String
        $zipCode: String
        $secondaryContacts: [ContactInput]
        $secondaryAddresses: [AddressInput]
    ) {
        addVendor(
            companyName: $companyName
            primaryAddress: $primaryAddress
            fax: $fax
            primaryContact: $primaryContact
            termsOfPayment: $termsOfPayment
            notes: $notes
            state: $state
            city: $city
            zipCode: $zipCode
            secondaryContacts: $secondaryContacts
            secondaryAddresses: $secondaryAddresses
        )
    }
`
export const MutationUpdateVendor = gql`
    mutation updateVendor(
        $id: ID!
        $companyName: String
        $primaryAddress: String
        $fax: String
        $primaryContact: String
        $termsOfPayment: String
        $notes: String
        $zipCode: String
        $state: String
        $city: String
        $secondaryContacts: [ContactInput]
        $secondaryAddresses: [AddressInput]
    ) {
        updateVendor(
            id: $id
            companyName: $companyName
            primaryAddress: $primaryAddress
            fax: $fax
            primaryContact: $primaryContact
            termsOfPayment: $termsOfPayment
            notes: $notes
            state: $state
            city: $city
            zipCode: $zipCode
            secondaryContacts: $secondaryContacts
            secondaryAddresses: $secondaryAddresses
        ) {
            id
            companyName
        }
    }
`

export const MutationDeleteUser = gql`
    mutation deleteUser($id: ID!) {
        deleteUser(id: $id)
    }
`

export const MutationAddUser = gql`
    mutation addUser(
        $name: String!
        $email: String!
        $role: String
        $firebaseUid: String
    ) {
        addUser(
            name: $name
            email: $email
            role: $role
            firebaseUid: $firebaseUid
        )
    }
`
export const MutationUpdateUSer = gql`
    mutation updateUser(
        $id: ID!
        $name: String
        $email: String
        $role: String
        $isActive: Boolean
    ) {
        updateUser(
            id: $id
            name: $name
            email: $email
            role: $role
            isActive: $isActive
        ) {
            name
        }
    }
`

export const MutationDeletePurchaseOrderById = gql`
    mutation deletePurchaseOrder($id: ID!) {
        deletePurchaseOrder(id: $id)
    }
`

export const MutationAddPurchaseOrder = gql`
    mutation addPurchaseOrder(
        $contactName: String
        $products: [PurchaseOrderProductsInput]
        $vendor: ID
    ) {
        addPurchaseOrder(
            contactName: $contactName
            products: $products
            vendor: $vendor
        )
    }
`

export const MutationUpdatePurchaseOrder = gql`
    mutation updatePurchaseOrder(
        $id: ID!
        $contactName: String
        $vendorId: ID
        $products: [PurchaseOrderProductsInput]
    ) {
        updatePurchaseOrder(
            id: $id
            contactName: $contactName
            vendorId: $vendorId
            products: $products
        )
    }
`
export const MutationAddProduct = gql`
    mutation addProduct(
        $name: String!
        $unitWeight: Float
        $grades: [GradesInput]
    ) {
        addProduct(name: $name, unitWeight: $unitWeight, grades: $grades)
    }
`

export const MutationDeleteProduct = gql`
    mutation deleteProduct($id: ID!) {
        deleteProduct(id: $id)
    }
`

export const MutationUpdateProduct = gql`
    mutation updateProduct(
        $id: ID!
        $name: String
        $unitWeight: Float
        $grades: [GradesInput]
    ) {
        updateProduct(
            id: $id
            name: $name
            unitWeight: $unitWeight
            grades: $grades
        )
    }
`

export const MutationCreateInventory = gql`
    mutation addInventory(
        $purchaseOrderNo: String
        $vendorId: ID!
        $productName: String!
        $grade: String
        $size: Float!
        $feet: Float!
        $inches: Float!
        $heatNo: String
        $notes: String!
        $quantity: [QuantityInput]!
        $weight: Float!
        $totalBars: Float!
        $country: String
    ) {
        addInventory(
            purchaseOrderNo: $purchaseOrderNo
            vendorId: $vendorId
            productName: $productName
            grade: $grade
            size: $size
            feet: $feet
            inches: $inches
            heatNo: $heatNo
            notes: $notes
            quantity: $quantity
            weight: $weight
            totalBars: $totalBars
            country: $country
        )
    }
`

export const MutationUpdateInvetory = gql`
    mutation InventoryResolver(
        $id: ID!
        $purchaseOrderNo: String
        $vendorId: ID!
        $productName: String!
        $grade: String
        $size: Float!
        $feet: Float!
        $inches: Float!
        $heatNo: String
        $notes: String!
        $quantity: [QuantityInput]!
        $weight: Float!
        $totalBars: Float!
        $country: String
    ) {
        updateInventory(
            id: $id
            purchaseOrderNo: $purchaseOrderNo
            vendorId: $vendorId
            productName: $productName
            grade: $grade
            size: $size
            feet: $feet
            inches: $inches
            heatNo: $heatNo
            notes: $notes
            quantity: $quantity
            weight: $weight
            totalBars: $totalBars
            country: $country
        ) {
            id
            feet
            weight
        }
    }
`

export const MutationDeleteInventoryById = gql`
    mutation deleteInventory($id: ID!) {
        deleteInventory(id: $id)
    }
`

export const MutationAddOrderAdmin = gql`
    mutation OrderAdmin(
        $customerId: ID!
        $shipToCustomerName: String
        $shipToCustomerAddress: String
        $pONumber: String
        $buyerName: String
        $orderAdminChs: [OrderAdminChsInput]!
        $invoiceNo: String
        $shipDate: String
        $grandTotal: Float!
        $notes: String
    ) {
        addOrderAdmin(
            customerId: $customerId
            shipToCustomerName: $shipToCustomerName
            shipToCustomerAddress: $shipToCustomerAddress
            pONumber: $pONumber
            buyerName: $buyerName
            orderAdminChs: $orderAdminChs
            invoiceNo: $invoiceNo
            shipDate: $shipDate
            grandTotal: $grandTotal
            notes: $notes
        )
    }
`

export const MutationUpdateOrderAdmin = gql`
    mutation OrderAdmin(
        $id: ID!
        $customerId: ID!
        $shipToCustomerName: String
        $shipToCustomerAddress: String
        $pONumber: String
        $buyerName: String
        $orderAdminChs: [OrderAdminChsInput]!
        $invoiceNo: String
        $shipDate: String
        $grandTotal: Float!
        $notes: String
    ) {
        updateOrderAdmin(
            id: $id
            customerId: $customerId
            shipToCustomerName: $shipToCustomerName
            shipToCustomerAddress: $shipToCustomerAddress
            pONumber: $pONumber
            buyerName: $buyerName
            orderAdminChs: $orderAdminChs
            invoiceNo: $invoiceNo
            shipDate: $shipDate
            grandTotal: $grandTotal
            notes: $notes
        ) {
            id
            grandTotal
        }
    }
`

export const MutationDeleteOrderAdminById = gql`
    mutation OrderAdmin($id: ID!) {
        deleteOrderAdmin(id: $id)
    }
`

export const MutationAddWorkOrder = gql`
    mutation addPurchaseOrder(
        $contactName: String
        $products: [PurchaseOrderProductsInput]
        $vendor: ID
    ) {
        addPurchaseOrder(
            contactName: $contactName
            products: $products
            vendor: $vendor
        )
    }
`

export const MutationUpdateWorkOrder = gql`
    mutation updateProduct(
        $id: ID!
        $name: String
        $unitWeight: Float
        $grades: [GradesInput]
    ) {
        updateProduct(
            id: $id
            name: $name
            unitWeight: $unitWeight
            grades: $grades
        )
    }
`

export const MutationDeleteWorkOrderById = gql`
    mutation deleteOrderAdmin($id: ID!) {
        deleteOrderAdmin(id: $id)
    }
`

export const MutationAddQuotation = gql`
    mutation addQuotation(
        $customerId: ID!
        $req: String
        $contactName: String
        $contactNumber: String
        $leadTime: String
        $faxNumber: String
        $shipVia: String
        $quotationStock: [QuotationStockInput]!
        $grandTotal: Float!
        $quotedBy: ID
    ) {
        addQuotation(
            customerId: $customerId
            req: $req
            contactName: $contactName
            contactNumber: $contactNumber
            leadTime: $leadTime
            faxNumber: $faxNumber
            shipVia: $shipVia
            quotationStock: $quotationStock
            grandTotal: $grandTotal
            quotedBy: $quotedBy
        )
    }
`

export const MutationDeleteQuotationById = gql`
    mutation deleteQuotation($id: ID!) {
        deleteQuotation(id: $id)
    }
`

export const MutationAddTime = gql`
    mutation OrderAdmin($orderAdminId: ID!, $status: String!) {
        addTime(orderAdminId: $orderAdminId, status: $status)
    }
`
export const MutationUpdateOrderEmployee = gql`
    mutation OrderAdmin($orderAdminId: ID!, $employeeId: [EmployeeInput]!) {
        updateEmployeeRecord(
            orderAdminId: $orderAdminId
            employeeId: $employeeId
        )
    }
`

export const MutationUpdateQuotation = gql`
    mutation updateQuotation(
        $id: ID!
        $customer: ID!
        $req: String
        $contactName: String
        $contactNumber: String
        $leadTime: String
        $faxNumber: String
        $shipVia: String
        $quotationStock: [QuotationStockInput]!
        $grandTotal: Float!
        $quotedBy: ID
    ) {
        updateQuotation(
            id: $id
            customer: $customer
            req: $req
            contactName: $contactName
            contactNumber: $contactNumber
            leadTime: $leadTime
            faxNumber: $faxNumber
            shipVia: $shipVia
            quotationStock: $quotationStock
            grandTotal: $grandTotal
            quotedBy: $quotedBy
        ) {
            id
        }
    }
`

export const MutationDeleteInvoiceArById = gql`
    mutation deleteInvoiceARById($id: ID!) {
        deleteInvoiceARById(id: $id)
    }
`

export const MutationUpdateShipping = gql`
    mutation updateShipping(
        $id: ID!
        $orderId: ID!
        $shippingCharges: Float
        $shipVia: String
        $quantity: Float
        $containerOptions: String
        $hasCustomerBilled: Boolean
        $notes: String
    ) {
        updateShipping(
            id: $id
            orderId: $orderId
            shippingCharges: $shippingCharges
            shipVia: $shipVia
            quantity: $quantity
            containerOptions: $containerOptions
            notes: $notes
            hasCustomerBilled: $hasCustomerBilled
        ) {
            id
            notes
            hasCustomerBilled
        }
    }
`

export const MutationDeleteShippingById = gql`
    mutation deleteShippingById($id: ID!) {
        deleteShippingById(id: $id)
    }
`

export const MutationAddInvoiceAR = gql`
    mutation addInvoiceAR(
        $orderId: ID!
        $shippingCharges: Float
        $status: String
        $invoiceTotal: Float
        $salesTaxAmount: Float
        $ignoreMinimumChanges: Boolean
        $periodNo: String
    ) {
        addInvoiceAR(
            orderId: $orderId
            shippingCharges: $shippingCharges
            status: $status
            invoiceTotal: $invoiceTotal
            salesTaxAmount: $salesTaxAmount
            ignoreMinimumChanges: $ignoreMinimumChanges
            periodNo: $periodNo
        )
    }
`

export const MutationUpdateInvoiceAR = gql`
    mutation updateInvoiceAR(
        $id: ID!
        $orderId: ID
        $shippingCharges: Float
        $status: String
        $invoiceTotal: Float
        $salesTaxAmount: Float
        $ignoreMinimumChanges: Boolean
        $periodNo: String
    ) {
        updateInvoiceAR(
            id: $id
            orderId: $orderId
            shippingCharges: $shippingCharges
            status: $status
            invoiceTotal: $invoiceTotal
            salesTaxAmount: $salesTaxAmount
            ignoreMinimumChanges: $ignoreMinimumChanges
            periodNo: $periodNo
        ) {
            id
            shippingCharges
        }
    }
`

export const MutationAddShipping = gql`
    mutation addShipping(
        $orderId: ID!
        $shippingCharges: Float!
        $shipVia: String
        $quantity: Float
        $containerOptions: String
        $notes: String
        $hasCustomerBilled: Boolean
    ) {
        addShipping(
            orderId: $orderId
            shippingCharges: $shippingCharges
            shipVia: $shipVia
            quantity: $quantity
            containerOptions: $containerOptions
            notes: $notes
            hasCustomerBilled: $hasCustomerBilled
        )
    }
`
export const MutationAddInvoiceAP = gql`
    mutation addInvoiceAP(
        $vendorId: ID!
        $shippingCharges: Float
        $status: String
        $invoiceTotal: Float
    ) {
        addInvoiceAP(
            vendorId: $vendorId
            shippingCharges: $shippingCharges
            status: $status
            invoiceTotal: $invoiceTotal
        )
    }
`
export const MutationUpdateInvoiceAP = gql`
    mutation updateInvoiceAP(
        $id: ID!
        $vendorId: ID
        $shippingCharges: Float
        $status: String
        $invoiceTotal: Float
    ) {
        updateInvoiceAP(
            id: $id
            vendorId: $vendorId
            shippingCharges: $shippingCharges
            status: $status
            invoiceTotal: $invoiceTotal
        ) {
            id
        }
    }
`
export const MutationDeleteInvoiceAP = gql`
    mutation deleteInvoiceAPById($id: ID!) {
        deleteInvoiceAPById(id: $id)
    }
`
export const MutationAddNonConfimance = gql`
    mutation addNC(
        $nonConformanceType: String
        $nonConformanceCode: String
        $invoiceTotal: Float!
        $quantity: Float!
        $customerCode: String
    ) {
        addNC(
            nonConformanceType: $nonConformanceType
            nonConformanceCode: $nonConformanceCode
            invoiceTotal: $invoiceTotal
            quantity: $quantity
            customerCode: $customerCode
        )
    }
`
export const MutationUpdateNonConfirmace = gql`
    mutation updateNC(
        $id: ID!
        $nonConformanceType: String
        $nonConformanceCode: String
        $invoiceTotal: Float!
        $quantity: Float!
        $customerCode: String
    ) {
        updateNC(
            id: $id
            nonConformanceType: $nonConformanceType
            nonConformanceCode: $nonConformanceCode
            invoiceTotal: $invoiceTotal
            quantity: $quantity
            customerCode: $customerCode
        ) {
            id
            createdAt
            nonConformanceType
            nonConformanceCode
        }
    }
`
export const MutationDeleteNonConfirmance = gql`
    mutation deleteNCById($id: ID!) {
        deleteNCById(id: $id)
    }
`
export const MutationAddAttendance = gql`
    mutation addAttendance(
        $employeeId: ID!
        $attendanceStatus: AttendanceStatus!
        $notes: String
    ) {
        addAttendance(
            employeeId: $employeeId
            attendanceStatus: $attendanceStatus
            notes: $notes
        )
    }
`

export const MutationSendEmail = gql`
    mutation sendQuotationEmail(
        $email: String!
        $contact_name: String!
        $sales_rep: String!
        $file: String!
        $quotation_number: String
    ) {
        sendQuotationEmail(
            email: $email
            contact_name: $contact_name
            sales_rep: $sales_rep
            file: $file
            quotation_number: $quotation_number
        )
    }
`
export const MutationUploadImageFile = gql`
    mutation addImageFile(
        $file: String!
        $fileName: String
        $bucketFileName: String!
        $description: String
        $quotation: ID
        $order: ID
        $inventory: ID
    ) {
        addImageFile(
            file: $file
            fileName: $fileName
            description: $description
            quotation: $quotation
            order: $order
            bucketFileName: $bucketFileName
            inventory: $inventory
        )
    }
`
