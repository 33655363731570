import { Remove, Add } from '@mui/icons-material'
import { Grid, IconButton, Button } from '@mui/material'
import { FieldArray } from 'formik'
import { FormikArrayField } from '../../components/Form/FormikArrayFeild'

interface IInventoryQuantity {
    formik: any
    setTotalQuantity?: React.Dispatch<React.SetStateAction<number>>
}

export const InventoryQuantity = ({
    formik,
    setTotalQuantity,
}: IInventoryQuantity): ReactNode => {
    return (
        <Grid item container spacing={2}>
            {/* <Grid item container spacing={2}>
                <Grid item xs="auto" sx={{ visibility: 'hidden' }}>
                    <IconButton color="error">
                        <Remove />
                    </IconButton>
                </Grid>
            </Grid> */}
            <FieldArray name="quantity">
                {({ remove, push }) => (
                    <>
                        {formik.values?.quantity?.map(
                            (qty: any, index: number) => (
                                <Grid
                                    key={qty.id?.toString() || index}
                                    item
                                    container
                                    // alignItems="cent"
                                    spacing={2}
                                    alignItems="center"
                                >
                                    <Grid item xs="auto">
                                        <IconButton
                                            color="error"
                                            onClick={() => remove(index)}
                                        >
                                            <Remove />
                                        </IconButton>
                                    </Grid>

                                    <Grid item sm={12} md={5.3}>
                                        <FormikArrayField
                                            formik={formik}
                                            label="Quantity"
                                            name={`quantity[${index}].quantity`}
                                            value={qty.quantity}
                                            type="number"
                                            step="0.01"
                                            onChange={(e: any) => {
                                                formik.handleChange(e)
                                                let total = Number(
                                                    e.target.value
                                                )
                                                formik.values?.quantity.forEach(
                                                    (item: any, i: number) => {
                                                        if (i !== index) {
                                                            total += Number(
                                                                item.quantity
                                                            )
                                                        }
                                                    }
                                                )
                                                setTotalQuantity?.(total)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            )
                        )}
                        <Grid item xs={12}>
                            <Button
                                startIcon={<Add />}
                                disabled={formik.isSubmitting}
                                onClick={() =>
                                    push({
                                        quantity: '',
                                    })
                                }
                            >
                                Add Quantity
                            </Button>
                        </Grid>
                    </>
                )}
            </FieldArray>
        </Grid>
    )
}
