import moment from 'moment-timezone'
import { AceGrindingImageData } from '../icons/ace-grinding'
import { NumberFormatter } from '../utils/NumberFormatter'

const imageScale = 0.095
export const generatePDFContent = (
    purchaseOrder: IPurchaseOrder
): Array<any> => [
    {
        columns: [
            {
                width: '*',
                columns: [
                    {
                        image: AceGrindingImageData.image,
                        width: AceGrindingImageData.width * imageScale,
                        height: AceGrindingImageData.height * imageScale,
                        style: ['center'],
                        alignment: 'center',
                    },
                ],
                style: ['center'],
                alignment: 'center',
            },
            {
                width: '*',
                text: 'PURCHASE ORDER',
                style: ['mainHeading', 'center'],
            },
            {
                // layout: 'noBorders',
                table: {
                    widths: [60, '*'],
                    body: [
                        [
                            { text: 'PO #:', bold: true },
                            { text: purchaseOrder.id },
                        ],
                        [
                            { text: 'Order Date:', bold: true },
                            {
                                text: moment(
                                    Number(purchaseOrder.createdAt)
                                ).format('MM/DD/yyyy'),
                            },
                        ],
                        [
                            { text: 'Contact:', bold: true },
                            { text: `${purchaseOrder.contactName}` },
                        ],
                    ],
                },
                margin: [0, 10, 0, 0],
            },
        ],
    },
    {
        margin: [0, 20, 0, 0],
        columns: [
            {
                margin: [0, 0, 5, 10],
                table: {
                    widths: ['*'],

                    body: [
                        [
                            [
                                {
                                    text: 'BILL FROM VENDOR',
                                    style: ['subHeading'],
                                },
                                {
                                    text: `${
                                        purchaseOrder.vendor.companyName
                                    }\n${purchaseOrder.vendor.primaryAddress}
                                    ${
                                        purchaseOrder.vendor?.city
                                            ? purchaseOrder.vendor.city
                                            : ''
                                    } ${
                                        purchaseOrder.vendor?.state
                                            ? purchaseOrder.vendor?.state
                                            : ''
                                    } ${
                                        purchaseOrder.vendor.zipCode
                                            ? purchaseOrder.vendor.zipCode
                                            : ''
                                    }\n${
                                        purchaseOrder.vendor.primaryContact
                                    }\n`,
                                },
                            ],
                        ],
                    ],
                },
            },
            {
                margin: [5, 0, 0, 10],
                table: {
                    widths: ['*'],

                    body: [
                        [
                            [
                                {
                                    text: 'SHIP TO',
                                    style: ['subHeading'],
                                },
                                {
                                    text: `ACE GRINDING INC.\n5017 W LAKE ST\nMELROSE PARK IL 60160\n708-345-2252\n`,
                                },
                            ],
                        ],
                    ],
                },
            },
        ],
    },
    // {
    //     margin: [0, 0, 0, 10],
    //     table: {
    //         headerRows: 1,
    //         widths: ['*', '*', '*', '*'],

    //         body: [
    //             [
    //                 {
    //                     text: 'Sales Rep',
    //                     bold: true,
    //                 },
    //                 {
    //                     text: 'Lead time',
    //                     bold: true,
    //                 },
    //                 {
    //                     text: 'Ship Via',
    //                     bold: true,
    //                 },
    //                 {
    //                     text: 'Terms',
    //                     bold: true,
    //                 },
    //             ],
    //             [
    //                 quotation.quotedBy.name,
    //                 quotation.leadTime,
    //                 quotation.shipVia,
    //                 quotation.customer.termsOfPayment,
    //             ],
    //         ],
    //     },
    // },
    {
        table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,

            widths: [30, 260, 40, 45, '*', '*'],

            body: [
                [
                    {
                        text: 'Item #',
                        bold: true,
                    },
                    {
                        text: 'Part Description',
                        bold: true,
                    },
                    {
                        text: 'Quantity',
                        bold: true,
                    },
                    {
                        text: 'UM',
                        bold: true,
                    },
                    {
                        text: 'Unit Price',
                        bold: true,
                    },
                    {
                        text: 'Total Price',
                        bold: true,
                    },
                ],

                ...purchaseOrder.products.map(
                    (item: IPurchaseOrderProduct, index) => [
                        { text: index + 1, margin: 2 },
                        {
                            text: `${item.description}`,
                            margin: [5, 5, 5, 0],
                        },
                        {
                            text: `${item.quantity}`,
                            margin: 5,
                            style: ['right'],
                        },
                        {
                            text: `${item.unitOfMeasure}`,
                            margin: 5,
                        },
                        {
                            text: `$ ${NumberFormatter(item.unitPrice)}`,
                            margin: 5,
                            style: ['right'],
                        },
                        {
                            text: `$ ${NumberFormatter(
                                item.quantity * item.unitPrice || 0
                            )}`,
                            margin: 5,
                            style: ['right'],
                        },
                    ]
                ),
                [
                    {},
                    {},
                    {},
                    {},
                    {},
                    {
                        text: `$ ${NumberFormatter(
                            purchaseOrder.products.reduce(
                                (n, item) =>
                                    n + item.quantity * item.unitPrice || 0,
                                0
                            )
                        )}`,
                        margin: 5,
                        style: ['right'],
                    },
                ],
            ],
        },
        layout: {
            hLineWidth(i, node) {
                return i === 0 || i === 1 || i === node.table.body.length
                    ? 1
                    : 0
            },
            paddingTop(i, node) {
                if (i === node.table.body.length - 1) {
                    return 570 -
                        (node.positions[node.positions.length - 1].top - 60) >
                        0
                        ? 570 -
                              (node.positions[node.positions.length - 1].top -
                                  60)
                        : 570
                }
                return 0
            },
        },
    },

    {
        margin: [0, 10, 0, 0],
        table: {
            widths: ['*'],

            body: [
                [
                    [
                        {
                            text: `Note:`,
                            bold: true,
                        },
                        {
                            text:
                                'All material supplied to Ace Grinding must be DFARS/RoHS Complaint. ',
                        },
                    ],
                ],
            ],
        },
    },
]
