import { Box, Button, Container } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { useMemo, useState, useEffect, useContext } from 'react'
import Add from '@mui/icons-material/Add'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { DynamicTable } from '../../components/Table/DynamicTable'
import { TableToolbar } from '../../components/Table/TableToolbar'
import { APP_NAME } from '../../config/config'

import { applySortFilter } from '../../utils/searchHelper'
import { queryGetAllProducts } from '../../common/queries'
import { DeleteModal } from '../../components/Modal/DeleteModal'
import { MutationDeleteProduct } from '../../common/Mutations'
import { IMessageContext, MessageContext } from '../../context/MessageContext'

const columns = [
    { name: 'ID', key: 'id' },
    { name: 'Product Name', key: 'name' },
    { name: 'Unit Weight', key: 'unitWeight' },
    { name: 'Actions', key: 'action' },
]

export const Products = (): ReactNode => {
    const { data: allProducts, loading, refetch } = useQuery(
        queryGetAllProducts
    )

    const [deleteProductById] = useMutation(MutationDeleteProduct)

    const navigate = useNavigate()

    const [isLoading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<IProduct[]>([])
    const [deleteProduct, setDeleteProduct] = useState<IProduct | null>(null)
    const [deleting, setDeleting] = useState<boolean>(false)
    const [searchText, setSearchText] = useState('')
    const { showSnackbar } = useContext(MessageContext) as IMessageContext

    useEffect(() => {
        setLoading(loading)
        if (!loading && allProducts && allProducts?.getAllProducts) {
            setData(allProducts?.getAllProducts)
        }
    }, [loading, allProducts])

    const handleSearchTextChanged = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ): void => {
        setSearchText(event.target.value)
    }

    const handleDeleteProduct = async (): Promise<boolean> => {
        try {
            setLoading(true)
            setDeleting(true)
            await deleteProductById({
                variables: {
                    id: deleteProduct?.id,
                },
            })
            setDeleteProduct(null)
            setDeleting(false)
            showSnackbar('Success !! Product Deleted ')

            return true
        } catch (error) {
            setDeleting(false)
            setDeleteProduct(null)
            showSnackbar('Error !! unbale to delete product ', true)
            return false
        } finally {
            refetch()
            setLoading(false)
        }
    }

    const filteredData: IProduct[] = applySortFilter<IProduct>(
        data,
        'asc',
        'name',
        searchText,
        'name'
    )
    return (
        <>
            <Helmet>
                <title>Products | {APP_NAME}</title>
            </Helmet>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8,
                }}
            >
                <Container maxWidth={false}>
                    <TableToolbar
                        title="Products"
                        searchText={searchText}
                        onSearchTextChanged={handleSearchTextChanged}
                        placeHolder="Search Products"
                    >
                        <Button
                            component={RouterLink}
                            to="/dashboard/product"
                            startIcon={<Add />}
                            color="primary"
                            variant="contained"
                            size="large"
                        >
                            Add Product
                        </Button>
                    </TableToolbar>
                    {useMemo(
                        () => (
                            <Box sx={{ mt: 3 }}>
                                <DynamicTable<IProduct>
                                    isLoading={isLoading}
                                    columns={columns}
                                    data={filteredData}
                                    onRowClick={(item) => {
                                        navigate(
                                            `/dashboard/product/${item.id}`,
                                            {
                                                state: {
                                                    product: item,
                                                },
                                            }
                                        )
                                    }}
                                    onRowDelete={(item) =>
                                        setDeleteProduct(item)
                                    }
                                />
                            </Box>
                        ),
                        [filteredData, isLoading, navigate]
                    )}
                </Container>

                <DeleteModal
                    dialogTitle="Delete Produc"
                    open={Boolean(deleteProduct)}
                    onClose={() => setDeleteProduct(null)}
                    onDeleteConfirm={handleDeleteProduct}
                    deleting={deleting}
                >
                    Are You sure you want to delete this product?
                    <br />
                    ID: {deleteProduct?.id}
                    <br />
                    Name: {deleteProduct?.name}
                </DeleteModal>
            </Box>
        </>
    )
}
