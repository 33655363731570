import moment from 'moment-timezone'
import { priceTypes } from '../common/PriceTypes'
import { AceGrindingImageData } from '../icons/ace-grinding'
import { NumberFormatter } from '../utils/NumberFormatter'

const imageScale = 0.095
export const generatePDFContent = (
    quotation: IQuotation,
    heading?: string
): Array<any> => [
    {
        columns: [
            {
                width: '*',
                columns: [
                    {
                        image: AceGrindingImageData.image,
                        width: AceGrindingImageData.width * imageScale,
                        height: AceGrindingImageData.height * imageScale,
                        style: ['center'],
                        alignment: 'center',
                    },
                ],
                style: ['center'],
                alignment: 'center',
            },
            {
                width: '*',
                text: heading || 'QUOTATION',
                style: ['mainHeading', 'center'],
            },
            {
                // layout: 'noBorders',
                table: {
                    widths: [60, '*'],
                    body: [
                        [
                            { text: 'Customer ID:', bold: true },
                            { text: quotation.customer.id },
                        ],
                        [
                            { text: 'Quote #:', bold: true },
                            { text: quotation.id },
                        ],
                        [
                            { text: 'Quote Date:', bold: true },
                            {
                                text: moment(
                                    Number(quotation.createdAt)
                                ).format('MM/DD/yyyy'),
                            },
                        ],
                        [
                            { text: 'Contact:', bold: true },
                            { text: `${quotation.contactName}` },
                        ],
                        [
                            { text: 'Inquiry #:', bold: true },
                            { text: `${quotation.req}` },
                        ],
                    ],
                },
                margin: [0, 10, 0, 0],
            },
        ],
    },
    {
        margin: [0, 20, 0, 0],
        columns: [
            {
                margin: [0, 0, 5, 10],
                table: {
                    widths: ['*'],

                    body: [
                        [
                            [
                                {
                                    text: 'BILL TO',
                                    style: ['subHeading'],
                                },
                                {
                                    text: `${quotation.customer.companyName}\n${
                                        quotation.customer.primaryAddress
                                    }
                                    ${
                                        quotation.customer.city
                                            ? quotation.customer.city
                                            : ''
                                    } ${
                                        quotation.customer.state
                                            ? quotation.customer.state
                                            : ''
                                    } ${
                                        quotation.customer.postalCode
                                            ? quotation.customer.postalCode
                                            : ''
                                    }\n${quotation.customer.primaryContact}\n`,
                                },
                            ],
                        ],
                    ],
                },
            },
            {
                margin: [5, 0, 0, 10],
                table: {
                    widths: ['*'],

                    body: [
                        [
                            [
                                {
                                    text: 'SHIP TO',
                                    style: ['subHeading'],
                                },
                                {
                                    text: `${quotation.customer.companyName}\n${
                                        quotation.customer.primaryAddress
                                    }
                                    ${
                                        quotation.customer.city
                                            ? quotation.customer.city
                                            : ''
                                    } ${
                                        quotation.customer.state
                                            ? quotation.customer.state
                                            : ''
                                    } ${
                                        quotation.customer.postalCode
                                            ? quotation.customer.postalCode
                                            : ''
                                    }\n${quotation.customer.primaryContact}\n`,
                                },
                            ],
                        ],
                    ],
                },
            },
        ],
    },
    {
        margin: [0, 0, 0, 10],
        table: {
            headerRows: 1,
            widths: ['*', '*', '*', '*'],

            body: [
                [
                    {
                        text: 'Sales Rep',
                        bold: true,
                    },
                    {
                        text: 'Lead time',
                        bold: true,
                    },
                    {
                        text: 'Ship Via',
                        bold: true,
                    },
                    {
                        text: 'Terms',
                        bold: true,
                    },
                ],
                [
                    quotation.quotedBy.name,
                    quotation.leadTime,
                    quotation.shipVia,
                    quotation.customer.termsOfPayment,
                ],
            ],
        },
    },
    {
        table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,

            widths: [30, 260, 40, 45, '*', '*'],

            body: [
                [
                    {
                        text: 'Item #',
                        bold: true,
                    },
                    {
                        text: 'Part Description',
                        bold: true,
                    },
                    {
                        text: 'Quantity',
                        bold: true,
                    },
                    {
                        text: 'UM',
                        bold: true,
                    },
                    {
                        text: 'Unit Price',
                        bold: true,
                    },
                    {
                        text: 'Total Price',
                        bold: true,
                    },
                ],
                ...quotation.quotationStock.map(
                    (item: IQuotationStock, index) => [
                        { text: index + 1, margin: 2 },
                        [
                            {
                                text: `${item.grade} ${
                                    item.typeAorC === 'C'
                                        ? item.startSize?.toFixed(5)
                                        : ''
                                } CG & P TO ${item.finishSizeHigh?.toFixed(
                                    5
                                )} / ${item.finishSizeLow?.toFixed(5)} X ${
                                    item.feet
                                }' ${item.inches}"`,
                                margin: [5, 5, 5, 0],
                            },
                            {
                                text: `${item.notes || ''}`,
                                margin: [5, 0, 5, 5],
                                color: 'red',
                            },
                        ],
                        {
                            text: `${item.quantity}`,
                            margin: 5,
                            style: ['right'],
                        },

                        {
                            text: `${
                                priceTypes.find(
                                    (pt) => pt.value === item.priceType
                                ).label
                            }`,
                            margin: 5,
                        },

                        {
                            text: `$ ${NumberFormatter(item.unitPrice)}`,
                            margin: 5,
                            style: ['right'],
                        },

                        {
                            text: `$ ${NumberFormatter(item.total)}`,
                            margin: 5,
                            style: ['right'],
                        },
                    ]
                ),
                [
                    {},
                    {},
                    {},
                    {},
                    {},
                    {
                        text: `$ ${NumberFormatter(quotation.grandTotal)}`,
                        margin: 5,
                        style: ['right'],
                    },
                ],
            ],
        },

        layout: {
            hLineWidth(i, node) {
                return i === 0 || i === 1 || i === node.table.body.length
                    ? 1
                    : 0
            },
            paddingTop(i, node) {
                if (i === node.table.body.length - 1) {
                    const row =
                        node.table.body[
                            node.table.body.length - 2
                        ][1] /* .stack[1] */

                    const rowPosition = row.positions[row.positions.length - 1]

                    // if (!rowPosition?.top) {
                    //     ;[row] = node.table.body[
                    //         node.table.body.length - 2
                    //     ][1].stack

                    //     rowPosition = row.positions[row.positions.length - 1]
                    // }

                    // const limit = node.positions[node.positions.length - 1].top
                    // return 570 -
                    //     (node.positions[node.positions.length - 1].top - 60) >
                    //     0
                    //     ? 570 -
                    //           (node.positions[node.positions.length - 1].top -
                    //               60)
                    //     : 570

                    return 630 - (rowPosition?.top || 265)
                }
                return 0
            },
        },
    },

    {
        margin: [0, 10, 0, 0],
        table: {
            widths: ['*'],

            body: [
                [
                    [
                        {
                            text: `Note:`,
                            bold: true,
                        },
                        {
                            text:
                                'All items are subject to prior sale. Delivery date based upon lead time ' +
                                'at date of quotation and subject to change at time of order. Standard billing ' +
                                'unit is in lbs and shipping allowance is +/- 10%.',
                        },
                    ],
                ],
            ],
        },
    },
]
