import React, { useEffect, useState } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import { useLazyQuery } from '@apollo/client'
import { auth } from '../config/firebase-config.js'
import { queryGetUserByEmail } from '../common/queries'

export const UserContext = React.createContext({})
export const UserProvider = UserContext.Provider
export const UserConsumer = UserContext.Consumer

export interface IUserContainer {
    children: ReactNode[] | ReactNode
}

export interface IUserContext {
    data: IUserData
    setUserData: (user: IUser) => void
}

export interface IUserData {
    isLoading: boolean
    user: IUser | null
}

export function UserContainer({ children }: IUserContainer): ReactNode {
    const [email, setUserEmail] = useState<string>('')
    const [loggedIn, setLoggedIn] = useState<boolean>(true)

    const [getDBUser, { data: dbUser, loading }] = useLazyQuery(
        queryGetUserByEmail
    )

    useEffect(() => {
        if (email) {
            getDBUser({
                variables: {
                    email,
                },
            })
        }
    }, [email, getDBUser])

    onAuthStateChanged(auth, (currentUser) => {
        if (currentUser) {
            setUserEmail(currentUser.email)
        } else {
            setLoggedIn(false)
        }
    })

    const [data, setData] = useState<IUserData>({
        isLoading: true,
        user: null,
    })

    useEffect(() => {
        if (!loggedIn) {
            setData({
                isLoading: false,
                user: null,
            })
        }
        if (!loading && dbUser && dbUser?.getUserByEmail) {
            setData({
                isLoading: false,
                user: dbUser?.getUserByEmail,
            })
        }
    }, [dbUser, loading, email, loggedIn])

    const setUserData = (user: IUser): void => {
        setData({ isLoading: false, user })
    }

    return (
        <UserProvider
            value={{
                data,
                setUserData,
            }}
        >
            {children}
        </UserProvider>
    )
}

export default UserContainer
