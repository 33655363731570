/* eslint-disable no-param-reassign */
import { LoadingButton } from '@mui/lab'
import {
    Grid,
    Button,
    Box,
    Card,
    Typography,
    TextField,
    Stack,
} from '@mui/material'
import { Form, Formik, Field } from 'formik'
import { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import moment from 'moment-timezone'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useDebouncedCallback } from 'use-debounce'
import { FormikTextField } from '../../components/Form/FormikTextField'
import { IMessageContext, MessageContext } from '../../context/MessageContext'
import { QuoteItems } from './QuoteItems'
import { queryGetCustomerById } from '../../common/queries'
import { MutationUpdateQuotation } from '../../common/Mutations'
import { NumberFormatter } from '../../utils/NumberFormatter'
import { UploadImage } from '../../components/uploadImage/uploadImage'
import { UploadImagesList } from '../../components/uploadImage/uploadedImagesList'

const validationSchema = Yup.object({
    customer: Yup.string().required('required'),
    quotedBy: Yup.string().required('required'),
    quotationStock: Yup.array().of(
        Yup.object().shape({
            quantity: Yup.string().required('required'),
            grade: Yup.string().required('required'),
            finishSizeHigh: Yup.string().required('required'),
            finishSizeLow: Yup.string().required('required'),
            rms: Yup.string().required('required'),
            feet: Yup.string().required('required'),
            inches: Yup.string().required('required'),
            grossLB: Yup.string().required('required'),
            netLB: Yup.string().required('required'),
            bars: Yup.string().required('required'),
            priceType: Yup.string().required('required'),
            total: Yup.string().required('required'),
            unitPrice: Yup.string().required('required'),
        })
    ),
})

export const EditQuotation = (): ReactNode => {
    const [updateQuotation] = useMutation(MutationUpdateQuotation)
    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const location = useLocation()
    const navigate = useNavigate()
    const { quotation } = location.state as {
        quotation: IQuotation
    }

    const uploadedFiles = quotation?.imageUrl

    const [getCustomerById, { data: customerData }] = useLazyQuery(
        queryGetCustomerById
    )

    const updateQuotationSubmit = async (
        values: IQuotation,
        setSubmitting
    ): Promise<boolean> => {
        try {
            setSubmitting(true)
            const gTotal = values.quotationStock.reduce(
                (n, item) => n + item.total || 0,
                0
            )
            // eslint-disable-next-line no-param-reassign
            values.grandTotal = gTotal
            await updateQuotation({
                variables: values,
            })
            showSnackbar('Success !! Quotation Updated  ')
            navigate('/dashboard/quotations', {
                replace: true,
            })
            setSubmitting(false)

            return true
        } catch (error) {
            setSubmitting(false)
            showSnackbar(error.message, true)
            return false
        }
    }

    useEffect(() => {
        getCustomerById({
            variables: {
                id: quotation?.customer?.id,
            },
        })
    }, [getCustomerById, quotation])

    const formikConfig = {
        initialValues: {
            id: quotation?.id || '',
            customer: quotation?.customer?.id || '',
            req: quotation?.req || '',
            contactName: quotation?.contactName || '',
            contactNumber: quotation?.contactNumber || '',
            leadTime: quotation?.leadTime || '',
            faxNumber: quotation?.faxNumber || '',
            shipVia: quotation?.shipVia || '',
            quotationStock: quotation?.quotationStock || [],
            // imageFile: quotation?.imageFile || [],
            // unitPrice: quotation?.unitPrice || null,
            // priceType: quotation?.priceType || '',
            // boxingPrice: quotation?.boxingPrice || null,
            grandTotal: quotation?.grandTotal || 0,
            quotedBy: quotation?.quotedBy?.id || '',
            companyName: quotation?.customer?.companyName || '',
            notes: quotation?.notes || '',
            createdAt:
                moment(Number(quotation?.createdAt)).format('DD-MM-YYYY') || '',
        },
        validationSchema,
    }

    const debounced = useDebouncedCallback((value) => {
        getCustomerById({
            variables: {
                id: value,
            },
        })
    }, 800)

    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                py: 8,
                px: 4,
            }}
        >
            <Card>
                <Grid container alignItems="center">
                    <Grid item>
                        <Typography variant="h4" sx={{ my: 2, mx: 2 }}>
                            Edit Quotation
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                            sx={{ px: 2 }}
                        />
                    </Grid>
                </Grid>
                <Box>
                    <Formik
                        {...formikConfig}
                        onSubmit={(values, { setSubmitting }) => {
                            updateQuotationSubmit(
                                (values as any) as IQuotation,
                                setSubmitting
                            )
                        }}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ py: 2, px: 2 }}
                                >
                                    <Grid item xs={12} sm={6} md={1.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Quote ID"
                                            name="id"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Date"
                                            name="createdAt"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={1.5}>
                                        <Field
                                            error={Boolean(
                                                formik.touched.customer &&
                                                    formik.errors.customer
                                            )}
                                            helperText={
                                                formik.touched.customer &&
                                                formik.errors.customer
                                            }
                                            as={TextField}
                                            label="Customer ID"
                                            name="customer"
                                            fullWidth
                                            onChange={(event) => {
                                                formik.setFieldValue(
                                                    'customer',
                                                    event.target.value
                                                )
                                                debounced(event.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2.5}>
                                        <TextField
                                            label="Customer Name"
                                            name="companyName"
                                            value={
                                                customerData?.getCustomer
                                                    ?.companyName
                                                    ? customerData?.getCustomer
                                                          ?.companyName
                                                    : ''
                                            }
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="REQ"
                                            name="req"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Contact Name"
                                            name="contactName"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Contact Number"
                                            name="contactNumber"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Lead Time"
                                            name="leadTime"
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={1.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Quoted By"
                                            name="quotedBy"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Ship Via"
                                            name="shipVia"
                                        />
                                    </Grid>

                                    <QuoteItems formik={formik} />

                                    <Grid
                                        item
                                        container
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                label="Grand Total"
                                                fullWidth
                                                disabled
                                                value={NumberFormatter(
                                                    formik.values.quotationStock.reduce(
                                                        (n, item) =>
                                                            n + item.total || 0,
                                                        0
                                                    )
                                                )}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={6} sm={4} md={3}>
                                            <Button
                                                color="error"
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                variant="outlined"
                                                fullWidth
                                                onClick={() => {
                                                    formik.resetForm()
                                                    navigate(
                                                        '/dashboard/quotations'
                                                    )
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={3}>
                                            <LoadingButton
                                                color="success"
                                                loadingPosition="end"
                                                loading={formik.isSubmitting}
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                fullWidth
                                            >
                                                {formik.isSubmitting
                                                    ? 'Submitting'
                                                    : 'Submit'}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                    <UploadImage quotation={quotation?.id} />
                                    <UploadImagesList
                                        imageFiles={uploadedFiles}
                                    />
                                </Grid>
                                <br />
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Card>
        </Box>
    )
}
