import { LoadingButton } from '@mui/lab'
import { Grid, Button, Box, Card, Typography, TextField } from '@mui/material'
import { Form, Formik, Field } from 'formik'
import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import moment from 'moment-timezone'
import { useLazyQuery, useMutation } from '@apollo/client'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { useDebouncedCallback } from 'use-debounce'
import { FormikTextField } from '../../components/Form/FormikTextField'
import { IMessageContext, MessageContext } from '../../context/MessageContext'
import { ArItems } from './ArItems'
import { queryGetOrderAdminById } from '../../common/queries'
import { MutationUpdateInvoiceAR } from '../../common/Mutations'
import { NumberFormatter } from '../../utils/NumberFormatter'

const validationSchema = Yup.object({
    orderId: Yup.string().required('required'),
})

export const EditAR = (): ReactNode => {
    const [updateInvoiceAR] = useMutation(MutationUpdateInvoiceAR)
    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const location = useLocation()
    const navigate = useNavigate()
    const { ARItem } = location.state as {
        ARItem: IAR
    }
    const [getOrderById, { data: orderData }] = useLazyQuery(
        queryGetOrderAdminById
    )
    const [totalInvoice, setTotalAmount] = useState<number>()

    const handleInvoiceARUpdate = async (
        values,
        setSubmitting
    ): Promise<boolean> => {
        try {
            setSubmitting(true)
            // eslint-disable-next-line
            values.invoiceTotal = Number(
                Number(values.salesTaxAmount) +
                    Number(values.shippingCharges) +
                    Number(values.invoiceTotal)
            )
            await updateInvoiceAR({
                variables: values,
            })

            showSnackbar('Success !! Invoice AR Updated !! ')
            navigate('/dashboard/ar', {
                replace: true,
            })
            setSubmitting(false)

            return true
        } catch (error) {
            setSubmitting(false)
            showSnackbar(error.message, true)
            return false
        }
    }

    useEffect(() => {
        getOrderById({
            variables: {
                id: ARItem?.orderAdmin.id,
            },
        })
    }, [getOrderById, ARItem])

    useEffect(() => {
        setTotalAmount(orderData?.getOrderAdminById?.grandTotal)
    }, [orderData])

    const formikConfig = {
        initialValues: {
            id: ARItem?.id || '',
            orderId: ARItem?.orderAdmin?.id || '',
            periodNo: ARItem?.periodNo || '',
            ignoreMinimumChanges: ARItem?.ignoreMinimumChanges || true,
            salesTaxAmount: ARItem?.salesTaxAmount || 0,
            shippingCharges: ARItem?.shippingCharges || 0,
            status: ARItem?.status || '',
            invoiceTotal: ARItem?.invoiceTotal || 0,
            createdAt:
                moment(Number(ARItem?.createdAt)).format('DD-MM-YYYY') || '',
        },
        validationSchema,
    }

    const debounced = useDebouncedCallback((value) => {
        getOrderById({
            variables: {
                id: value,
            },
        })
    }, 800)

    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                py: 8,
                px: 4,
            }}
        >
            <Card>
                <Typography variant="h4" sx={{ my: 2, mx: 2 }}>
                    Edit AR
                </Typography>
                <Box>
                    <Formik
                        {...formikConfig}
                        onSubmit={(values, { setSubmitting }) => {
                            handleInvoiceARUpdate(values, setSubmitting)
                        }}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ py: 2, px: 2 }}
                                >
                                    <Grid item xs={12} sm={3} md={1.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Invoice ID"
                                            name="id"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={2.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Date"
                                            name="createdAt"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={1.5}>
                                        <Field
                                            error={Boolean(
                                                formik.touched.orderId &&
                                                    formik.errors.orderId
                                            )}
                                            helperText={
                                                formik.touched.orderId &&
                                                formik.errors.orderId
                                            }
                                            as={TextField}
                                            label="Order ID"
                                            name="orderId"
                                            fullWidth
                                            onChange={(event) => {
                                                formik.setFieldValue(
                                                    'orderId',
                                                    event.target.value
                                                )
                                                debounced(event.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2.5}>
                                        <TextField
                                            fullWidth
                                            label="Customer"
                                            value={
                                                orderData?.getOrderAdminById
                                                    ?.customer?.companyName ||
                                                ''
                                            }
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                        <TextField
                                            fullWidth
                                            label="PO Number"
                                            value={
                                                orderData?.getOrderAdminById
                                                    ?.pONumber || ''
                                            }
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Period No"
                                            name="periodNo"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2.2}>
                                        <FormControlLabel
                                            control={
                                                <Field
                                                    as={Checkbox}
                                                    name="ignoreMinimumChanges"
                                                    defaultChecked={
                                                        formik.values
                                                            .ignoreMinimumChanges
                                                    }
                                                />
                                            }
                                            label="Ignore min charges"
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={2.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Sales Tax Amount"
                                            name="salesTaxAmount"
                                            type="number"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Shipping Charges"
                                            name="shippingCharges"
                                            type="number"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Status"
                                            name="status"
                                        />
                                    </Grid>

                                    <ArItems
                                        formik={
                                            orderData?.getOrderAdminById || ''
                                        }
                                        disableAll
                                    />

                                    <Grid
                                        item
                                        container
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                fullWidth
                                                label="Invoice Total"
                                                onChange={(event) => {
                                                    setTotalAmount(
                                                        Number(
                                                            event.target.value
                                                        )
                                                    )
                                                }}
                                                disabled
                                                value={NumberFormatter(
                                                    Number(
                                                        formik.values
                                                            .salesTaxAmount
                                                    ) +
                                                        Number(
                                                            formik.values
                                                                .shippingCharges
                                                        ) +
                                                        Number(totalInvoice),
                                                    2
                                                )}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={6} sm={4} md={3}>
                                            <Button
                                                color="error"
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                variant="outlined"
                                                fullWidth
                                                onClick={() => {
                                                    formik.resetForm()
                                                    navigate('/dashboard/ar')
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={3}>
                                            <LoadingButton
                                                color="success"
                                                loadingPosition="end"
                                                loading={formik.isSubmitting}
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                fullWidth
                                            >
                                                {formik.isSubmitting
                                                    ? 'Submitting'
                                                    : 'Submit'}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Card>
        </Box>
    )
}
