/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/jsx-filename-extension */
import { format } from 'date-fns'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
    Box,
    Button,
    Card,
    CardHeader,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { useNavigate } from 'react-router-dom'
import { SeverityPill } from '../severity-pill.js'
import { NumberFormatter } from '../../utils/NumberFormatter'

export const DataTable = ({ data, heading, navigateTo, buttonText }) => {
    const navigate = useNavigate()
    return (
        <Card style={{ height: '423px' }}>
            <CardHeader
                action={
                    <Button
                        color="primary"
                        endIcon={<ArrowRightIcon fontSize="small" />}
                        size="small"
                        onClick={() => {
                            navigate(navigateTo)
                        }}
                    >
                        {buttonText}
                    </Button>
                }
                title={heading}
            />
            <PerfectScrollbar>
                <Box sx={{ minWidth: 800 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Invoice Id</TableCell>
                                <TableCell>Customer</TableCell>
                                <TableCell sortDirection="desc">Date</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((item) => (
                                <TableRow hover key={item.id}>
                                    <TableCell>{item.id}</TableCell>
                                    <TableCell>
                                        {item?.orderAdmin?.customer?.id ||
                                            item?.customer?.id}
                                    </TableCell>
                                    <TableCell>
                                        {format(
                                            Number(item.createdAt),
                                            'dd/MM/yyyy'
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {NumberFormatter(
                                            item.invoiceTotal
                                                ? item.invoiceTotal
                                                : item?.grandTotal,
                                            2
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <SeverityPill color="success">
                                            {item.status}
                                        </SeverityPill>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </PerfectScrollbar>
        </Card>
    )
}
