import { Box, Button, Container } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { useMemo, useState, useEffect, useContext } from 'react'
import Add from '@mui/icons-material/Add'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { APP_NAME } from '../../config/config'
import { TableToolbar } from '../../components/Table/TableToolbar'
import { IMessageContext, MessageContext } from '../../context/MessageContext'
import { applySortFilter } from '../../utils/searchHelper'
import { DynamicTable } from '../../components/Table/DynamicTable'
import { queryGetAllVendors } from '../../common/queries'
import { DeleteModal } from '../../components/Modal/DeleteModal'
import { MutationDeleteVendor } from '../../common/Mutations'

const columns = [
    { name: 'ID', key: 'id' },
    { name: 'Company Name', key: 'companyName' },
    { name: 'Company Address', key: 'primaryAddress' },
    { name: 'Contact', key: 'primaryContact' },
    { name: 'Actions', key: 'action' },
]

const Vendors = (): ReactNode => {
    const { data: allVendors, loading, refetch } = useQuery(queryGetAllVendors)
    const [deleteVendorById] = useMutation(MutationDeleteVendor)

    const navigate = useNavigate()
    const [isLoading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<IVendor[]>([])
    const [deleteVendor, setDeleteVendor] = useState<IVendor | null>(null)
    const [deleting, setDeleting] = useState<boolean>(false)
    const [searchText, setSearchText] = useState('')
    const { showSnackbar } = useContext(MessageContext) as IMessageContext

    useEffect(() => {
        setLoading(loading)
        if (!loading && allVendors && allVendors?.getAllVendors) {
            setData(allVendors?.getAllVendors)
        }
    }, [allVendors, loading])

    const handleDeleteVendor = async (): Promise<boolean> => {
        try {
            setLoading(true)
            setDeleting(true)
            await deleteVendorById({
                variables: {
                    id: deleteVendor?.id,
                },
            })
            setDeleteVendor(null)
            setDeleting(false)
            showSnackbar('Success !! Vendor Deleted ')

            return true
        } catch (error) {
            setDeleting(false)
            setDeleteVendor(null)
            showSnackbar('Error !! unbale to delete Vendor ')

            return false
        } finally {
            refetch()
            setLoading(false)
        }
    }

    const handleSearchTextChanged = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ): void => {
        setSearchText(event.target.value)
    }

    const filteredData: IVendor[] = applySortFilter<IVendor>(
        data,
        'asc',
        'companyName',
        searchText,
        'companyName'
    )

    return (
        <>
            <Helmet>
                <title>Vendors | {APP_NAME}</title>
            </Helmet>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8,
                }}
            >
                <Container maxWidth={false}>
                    <TableToolbar
                        title="Vendors"
                        searchText={searchText}
                        onSearchTextChanged={handleSearchTextChanged}
                        placeHolder="Search Vendor"
                    >
                        <Button
                            component={RouterLink}
                            to="/dashboard/vendor"
                            startIcon={<Add />}
                            color="primary"
                            variant="contained"
                            size="large"
                        >
                            Add Vendor
                        </Button>
                    </TableToolbar>

                    {useMemo(
                        () => (
                            <Box sx={{ mt: 3 }}>
                                <DynamicTable
                                    isLoading={isLoading}
                                    columns={columns}
                                    data={filteredData}
                                    onRowClick={(item) => {
                                        navigate(
                                            `/dashboard/vendor/${item.id}`,
                                            {
                                                state: {
                                                    vendor: item,
                                                },
                                            }
                                        )
                                    }}
                                    onRowDelete={(item) =>
                                        setDeleteVendor(item)
                                    }
                                />
                            </Box>
                        ),
                        [filteredData, isLoading, navigate]
                    )}
                </Container>
                <DeleteModal
                    dialogTitle="Delete Vendor"
                    open={Boolean(deleteVendor)}
                    onClose={() => setDeleteVendor(null)}
                    onDeleteConfirm={handleDeleteVendor}
                    deleting={deleting}
                >
                    Are You sure you want to delete this vendor?
                    <br />
                    ID: {deleteVendor?.id}
                    <br />
                    Name: {deleteVendor?.companyName}
                </DeleteModal>
            </Box>
        </>
    )
}

export default Vendors
