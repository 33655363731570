import { useRef, useState, useContext } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { alpha } from '@mui/material/styles'
import {
    Button,
    Box,
    Divider,
    MenuItem,
    Typography,
    Avatar,
    IconButton,
} from '@mui/material'
import { Home, Person } from '@mui/icons-material'
import { signOut } from 'firebase/auth'
import { auth } from '../../config/firebase-config.js'
import MenuPopover from '../../components/MenuPopover'
import { UserContext, IUserContext } from '../../context/UserContext'
import { MessageContext, IMessageContext } from '../../context/MessageContext'
import { UserCircle } from '../../icons/user-circle'

const MENU_OPTIONS = [
    {
        label: 'Dashboard',
        icon: Home,
        linkTo: '/dashboard',
    },
    {
        label: 'Users',
        icon: Person,
        linkTo: 'users',
    },
]

function AccountPopover(): ReactNode {
    const anchorRef = useRef<HTMLButtonElement>(null)
    const [open, setOpen] = useState(false)
    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const {
        data: { user },
    } = useContext(UserContext) as IUserContext
    const navigate = useNavigate()
    const handleLogout = async (): Promise<void> => {
        try {
            await signOut(auth)
            showSnackbar('Logout successful !! ')
            navigate('/', { replace: true })
        } catch (error) {
            showSnackbar('Error !! unable to logged out ....', true)
        }
    }

    const handleOpen = (): void => {
        setOpen(true)
    }
    const handleClose = (): void => {
        setOpen(false)
    }

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    width: 44,
                    height: 44,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) =>
                                alpha(theme.palette.grey[900], 0.72),
                        },
                    }),
                }}
            >
                <Avatar
                    sx={{
                        height: 40,
                        width: 40,
                        ml: 1,
                    }}
                    src="/static/images/avatars/avatar_1.png"
                >
                    <UserCircle fontSize="small" />
                </Avatar>
            </IconButton>

            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current as Element}
                sx={{ width: 220 }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle1" noWrap>
                        {user.name}
                    </Typography>
                </Box>

                <Divider sx={{ my: 1 }} />

                {user.role === 'ADMIN' &&
                    MENU_OPTIONS.map((option) => (
                        <MenuItem
                            key={option.label}
                            to={option.linkTo}
                            component={RouterLink}
                            onClick={handleClose}
                            sx={{ typography: 'body2', py: 1, px: 2.5 }}
                        >
                            {}
                            <option.icon
                                sx={{
                                    mr: 2,
                                    width: 24,
                                    height: 24,
                                }}
                            />

                            {option.label}
                        </MenuItem>
                    ))}

                <Box sx={{ p: 2, pt: 1.5 }}>
                    <Button
                        fullWidth
                        color="inherit"
                        variant="outlined"
                        onClick={handleLogout}
                    >
                        Logout
                    </Button>
                </Box>
            </MenuPopover>
        </>
    )
}

export default AccountPopover
