import { LoadingButton } from '@mui/lab'
import { Grid, Button, Box, Card, Typography, TextField } from '@mui/material'
import { Form, Formik, Field } from 'formik'
import { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import moment from 'moment-timezone'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useDebouncedCallback } from 'use-debounce'
import { FormikTextField } from '../../components/Form/FormikTextField'
import { IMessageContext, MessageContext } from '../../context/MessageContext'
import { OrderAdminItems } from './OrderAdminItems'
import { queryGetCustomerById } from '../../common/queries'
import { MutationUpdateOrderAdmin } from '../../common/Mutations'
import { NumberFormatter } from '../../utils/NumberFormatter'
import { FormikDateField } from '../../components/Form/FormikDateField'
import { UploadImage } from '../../components/uploadImage/uploadImage'
import { UploadImagesList } from '../../components/uploadImage/uploadedImagesList'

const validationSchema = Yup.object({
    customerId: Yup.string().required('required'),
    orderAdminChs: Yup.array().of(
        Yup.object().shape({
            quantity: Yup.string().required('required'),
            finishSizeHigh: Yup.string().required('required'),
            finishSizeLow: Yup.string().required('required'),
            unitPrice: Yup.string().required('required'),
            grade: Yup.string().required('required'),
            feet: Yup.string().required('required'),
        })
    ),
})

export const EditOrderAdmin = (): ReactNode => {
    const [updateOrderAdmin] = useMutation(MutationUpdateOrderAdmin)
    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const location = useLocation()
    const navigate = useNavigate()
    const { orderAdmin } = location.state as {
        orderAdmin: IOrderAdmin
    }
    const uploadedFiles = orderAdmin?.imageUrl

    const [getCustomerById, { data: customerData }] = useLazyQuery(
        queryGetCustomerById
    )

    const handleOrderAdminUpdate = async (
        values,
        setSubmitting
    ): Promise<boolean> => {
        try {
            values.orderAdminChs.forEach((item: any, index, array) => {
                item.dueDate = moment(item.dueDate).format('DD-MM-YYYY')
                delete array[index].id
            })

            values.shipDate = moment(values.shipDate).format('DD-MM-YYYY')
            values.orderAdminChs.forEach((item, index, arr) => {
                arr[index].grade = item.grade?.label || item.grade
            })

            await updateOrderAdmin({
                variables: values,
            })
            showSnackbar('Success !! Order Updated !! ')
            navigate('/dashboard/orders-admin', {
                replace: true,
            })
            setSubmitting(false)

            return true
        } catch (error) {
            setSubmitting(false)
            showSnackbar(error.message, true)
            return false
        }
    }

    useEffect(() => {
        getCustomerById({
            variables: {
                id: orderAdmin?.customer.id,
            },
        })
    }, [getCustomerById, orderAdmin])

    orderAdmin?.orderAdminChs?.forEach((item: any, index, array) => {
        item.dueDate = moment(item.dueDate, 'DD-MM') || ''
        if (item.inventory)
            (array[index].inventory as any) = array[index].inventory.id
    })
    const formikConfig = {
        initialValues: {
            id: orderAdmin?.id || '',
            customerId: orderAdmin?.customer.id || '',
            shipToCustomerName: orderAdmin?.shipToCustomerName || '',
            shipToCustomerAddress: orderAdmin?.shipToCustomerAddress || '',
            pONumber: orderAdmin?.pONumber || '',
            buyerName: orderAdmin?.buyerName || '',
            shipDate: moment(orderAdmin?.shipDate, 'DD-MM-YYYY') || '',
            invoiceNo: orderAdmin?.invoiceNo || '',
            orderAdminChs: orderAdmin?.orderAdminChs || [],
            grandTotal: orderAdmin?.grandTotal || null,
            createdAt:
                moment(Number(orderAdmin?.createdAt)).format('DD-MM-YYYY') ||
                '',
        },
        validationSchema,
    }
    const debounced = useDebouncedCallback((value) => {
        getCustomerById({
            variables: {
                id: value,
            },
        })
    }, 800)
    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                py: 8,
                px: 4,
            }}
        >
            <Card>
                <Typography variant="h4" sx={{ my: 2, mx: 2 }}>
                    Edit Order
                </Typography>
                <Box>
                    <Formik
                        {...formikConfig}
                        onSubmit={(values, { setSubmitting }) => {
                            handleOrderAdminUpdate(values, setSubmitting)
                        }}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ py: 2, px: 2 }}
                                >
                                    <Grid item xs={12} sm={3} md={1.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Order ID"
                                            name="id"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={2.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Date"
                                            name="createdAt"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={1.5}>
                                        <Field
                                            error={Boolean(
                                                formik.touched.customerId &&
                                                    formik.errors.customerId
                                            )}
                                            helperText={
                                                formik.touched.customerId &&
                                                formik.errors.customerId
                                            }
                                            as={TextField}
                                            label="Customer ID"
                                            name="customerId"
                                            fullWidth
                                            onChange={(event) => {
                                                formik.setFieldValue(
                                                    'customerId',
                                                    event.target.value
                                                )
                                                debounced(event.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2.5}>
                                        <TextField
                                            label="Customer Name"
                                            value={
                                                customerData?.getCustomer
                                                    ?.companyName || ''
                                            }
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                            label="Customer Address"
                                            value={
                                                customerData?.getCustomer
                                                    ?.primaryAddress || ''
                                            }
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Ship To Customer Name"
                                            name="shipToCustomerName"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Ship To Customer Address"
                                            name="shipToCustomerAddress"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <FormikTextField
                                            formik={formik}
                                            label="PO Number"
                                            name="pONumber"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Buyer Name"
                                            name="buyerName"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <FormikDateField
                                            formik={formik}
                                            label="Ship Date"
                                            name="shipDate"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Invoice Number"
                                            name="invoiceNo"
                                        />
                                    </Grid>

                                    <OrderAdminItems formik={formik} />

                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={12} sm={6} md={4}>
                                            <FormikTextField
                                                formik={formik}
                                                label="Grand Total"
                                                name="grandTotal"
                                                value={NumberFormatter(
                                                    formik.values.orderAdminChs.reduce(
                                                        (n, item) =>
                                                            n +
                                                                item.quantity *
                                                                    item.unitPrice ||
                                                            0,
                                                        0
                                                    )
                                                )}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={6} sm={4} md={3}>
                                            <Button
                                                color="error"
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                variant="outlined"
                                                fullWidth
                                                onClick={() => {
                                                    formik.resetForm()
                                                    navigate(
                                                        '/dashboard/orders-admin'
                                                    )
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={3}>
                                            <LoadingButton
                                                color="success"
                                                loadingPosition="end"
                                                loading={formik.isSubmitting}
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                fullWidth
                                            >
                                                {formik.isSubmitting
                                                    ? 'Submitting'
                                                    : 'Submit'}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>

                                    <UploadImage order={orderAdmin?.id} />
                                    <UploadImagesList
                                        imageFiles={uploadedFiles}
                                    />
                                    <br />
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Card>
        </Box>
    )
}
