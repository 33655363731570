import { Grid, Card, MenuItem } from '@mui/material'
import { FieldArray } from 'formik'
import { FormikArrayField } from '../../components/Form/FormikArrayFeild'
import { NumberFormatter } from '../../utils/NumberFormatter'

export const ArItems = ({
    formik,
    disableAll = false,
}: {
    formik: any
    disableAll?: boolean
}): ReactNode => {
    return (
        <Grid container item spacing={2}>
            <FieldArray name="orderAdminChs">
                {() => (
                    <Grid container item spacing={1}>
                        {formik.orderAdminChs?.map(
                            (orderItem: any, index: number) => (
                                <Grid
                                    item
                                    container
                                    key={orderItem.id?.toString() || index}
                                >
                                    <Card variant="outlined">
                                        <Grid
                                            item
                                            container
                                            alignItems="start"
                                            spacing={2}
                                            sx={{ py: 2, px: 2 }}
                                        >
                                            <Grid item sm={12} md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="A or C"
                                                    name={`orderAdminChs[${index}].typeAorC`}
                                                    value={
                                                        orderItem.typeAorC || ''
                                                    }
                                                    select
                                                >
                                                    <MenuItem value="A">
                                                        A
                                                    </MenuItem>
                                                    <MenuItem value="C">
                                                        C
                                                    </MenuItem>
                                                </FormikArrayField>
                                            </Grid>
                                            <Grid item sm={12} md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="L or B"
                                                    name={`orderAdminChs[${index}].typeLorB`}
                                                    value={
                                                        orderItem.typeLorB || ''
                                                    }
                                                    select
                                                >
                                                    <MenuItem value="L">
                                                        L
                                                    </MenuItem>
                                                    <MenuItem value="B">
                                                        B
                                                    </MenuItem>
                                                </FormikArrayField>
                                            </Grid>
                                            <Grid item sm={12} md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="Quantity"
                                                    name={`orderAdminChs[${index}].quantity`}
                                                    value={orderItem.quantity}
                                                />
                                            </Grid>
                                            <Grid item sm={12} md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="Grade"
                                                    name={`orderAdminChs[${index}].grade`}
                                                    value={orderItem.grade}
                                                />
                                            </Grid>
                                            <Grid item sm={12} md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="Start Size"
                                                    name={`orderAdminChs[${index}].startSize`}
                                                    value={orderItem.startSize}
                                                    type="number"
                                                />
                                            </Grid>

                                            <Grid item sm={12} md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="F.S High"
                                                    name={`orderAdminChs[${index}].finishSizeHigh`}
                                                    value={
                                                        orderItem.finishSizeHigh
                                                    }
                                                    type="number"
                                                />
                                            </Grid>
                                            <Grid item sm={12} md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="F.S Low"
                                                    name={`orderAdminChs[${index}].finishSizeLow`}
                                                    value={
                                                        orderItem.finishSizeLow
                                                    }
                                                    type="number"
                                                />
                                            </Grid>

                                            <Grid item md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="Feet"
                                                    name={`orderAdminChs[${index}].feet`}
                                                    value={orderItem.feet}
                                                />
                                            </Grid>
                                            <Grid item md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="Inches"
                                                    name={`orderAdminChs[${index}].inches`}
                                                    value={orderItem.inches}
                                                />
                                            </Grid>

                                            <Grid item sm={12} md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="RMS"
                                                    name={`orderAdminChs[${index}].rms`}
                                                    value={orderItem.rms}
                                                    type="number"
                                                />
                                            </Grid>
                                            <Grid item sm={12} md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="Inventory ID"
                                                    name={`orderAdminChs[${index}].inventoryNo`}
                                                    value={
                                                        orderItem.inventoryNo
                                                    }
                                                />
                                            </Grid>

                                            <Grid item sm={12} md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="Heat No"
                                                    name={`orderAdminChs[${index}].heatNo`}
                                                    value={orderItem.heatNo}
                                                    type="string"
                                                />
                                            </Grid>
                                            <Grid item sm={12} md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="UM"
                                                    name={`orderAdminChs[${index}].um`}
                                                    value={orderItem.um}
                                                    type="string"
                                                />
                                            </Grid>
                                            <Grid item sm={12} md={1.5}>
                                                <FormikArrayField
                                                    formik={formik}
                                                    label="Unit Price"
                                                    name={`orderAdminChs[${index}].unitPrice`}
                                                    value={orderItem.unitPrice}
                                                    type="number"
                                                    onChange={(e) => {
                                                        formik.handleChange(e)

                                                        formik.setFieldValue(
                                                            `orderAdminChs[${index}].total`,
                                                            orderItem.quantity *
                                                                e.target.value
                                                        )
                                                    }}
                                                    disabled={disableAll}
                                                />
                                            </Grid>

                                            <Grid item sm={12} md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="Part No"
                                                    name={`orderAdminChs[${index}].partNumber`}
                                                    value={orderItem.partNumber}
                                                />
                                            </Grid>
                                            <Grid item sm={12} md={1.5}>
                                                <FormikArrayField
                                                    disabled={disableAll}
                                                    formik={formik}
                                                    label="Due Date"
                                                    name={`orderAdminChs[${index}].dueDate`}
                                                    value={orderItem.dueDate}
                                                    type="string"
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                container
                                                justifyContent="flex-end"
                                            >
                                                <Grid item sm={12} md={4}>
                                                    <FormikArrayField
                                                        formik={formik}
                                                        label="Total"
                                                        name={`orderAdminChs[${index}].total`}
                                                        value={NumberFormatter(
                                                            orderItem.total,
                                                            2
                                                        )}
                                                        type="text"
                                                        disabled
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            )
                        )}
                    </Grid>
                )}
            </FieldArray>
        </Grid>
    )
}
