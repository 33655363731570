import { gql } from '@apollo/client'

export const queryGetAllEmployees = gql`
    query getAllEmployee {
        getAllEmployee {
            id
            name
            email
            address
            contact
            notes
            socialSecurity
            isActive
            createdAt
        }
    }
`

export const queryGetAllCustomers = gql`
    query getAllCustomers {
        getAllCustomers {
            id
            companyName
            fax
            createdAt
            notes
            termsOfPayment
            shipVia
            city
            state
            postalCode
            primaryContact
            primaryAddress
            secondaryContacts {
                id
                name
                contact
                position
            }
            secondaryAddresses {
                id
                name
                address
            }
        }
    }
`

export const queryGetCustomerById = gql`
    query getCustomer($id: ID!) {
        getCustomer(id: $id) {
            id
            companyName
            primaryAddress
            primaryContact
            shipVia
            city
            state
            postalCode
        }
    }
`

export const queryGetAllVendors = gql`
    query getAllVendors {
        getAllVendors {
            id
            companyName
            fax
            notes
            termsOfPayment
            createdAt
            primaryAddress
            primaryContact
            state
            city
            zipCode
            secondaryContacts {
                name
                contact
                position
            }
            secondaryAddresses {
                name
                address
            }
        }
    }
`

export const queryGetAllUser = gql`
    query getAllUsers {
        getAllUsers {
            id
            createdAt
            name
            email
            role
            isActive
        }
    }
`

export const queryGetUserByEmail = gql`
    query getUserByEmail($email: String!) {
        getUserByEmail(email: $email) {
            id
            createdAt
            name
            email
            role
        }
    }
`

export const queryGetAllPurchaseOrders = gql`
    query getAllPurchaseOrders {
        getAllPurchaseOrders {
            id
            createdAt
            contactName
            products {
                quantity
                unitPrice
                unitOfMeasure
                description
            }
            vendor {
                id
                companyName
                primaryAddress
                primaryContact
                state
                zipCode
                city
            }
        }
    }
`

export const queryGetProductbyName = gql`
    query getProductByName($name: String!) {
        getProductByName(name: $name) {
            name
            unitWeight
            grades {
                name
            }
        }
    }
`

export const queryGetVendorById = gql`
    query getVendor($id: ID!) {
        getVendor(id: $id) {
            id
            companyName
            primaryAddress
        }
    }
`
export const queryGetAllProducts = gql`
    query getAllProducts {
        getAllProducts {
            id
            createdAt
            name
            unitWeight
            grades {
                name
            }
        }
    }
`

export const queryGetAllInventory = gql`
    query getAllInventory {
        getAllInventory {
            imageUrl {
                id
                fileName
                url
                description
                bucketFileName
            }
            id
            createdAt
            grade
            weight
            heatNo
            country
            size
            feet
            inches
            totalBars
            notes
            purchaseOrderNo
            quantity {
                quantity
            }
            vendor {
                id
                companyName
            }
            product {
                name
                grades {
                    name
                }
            }
        }
    }
`

export const queryGetAllQuotations = gql`
    query getAllQuotations {
        getAllQuotations {
            imageUrl {
                id
                fileName
                url
                description
                bucketFileName
            }
            id
            createdAt
            customer {
                id
                secondaryContacts {
                    name
                    contact
                }
                companyName
                primaryAddress
                primaryContact
                termsOfPayment
                state
                city
                postalCode
            }
            req
            contactName
            contactNumber
            leadTime
            faxNumber
            quotedBy {
                id
                name
            }
            shipVia
            quotationStock {
                typeAorC
                typeLorB
                quantity
                grade
                startSize
                finishSizeHigh
                finishSizeLow
                rms
                feet
                inches
                tolerance
                grossLB
                netLB
                stockRemoval
                bars
                unitPrice
                priceType
                boxingPrice
                total
                notes
            }
            grandTotal
        }
    }
`

export const queryGetAllOrdersAdmin = gql`
    query OrderAdmin {
        getAllOrderAdmins {
            imageUrl {
                id
                fileName
                url
                description
                bucketFileName
            }
            id
            createdAt
            customer {
                id
                companyName
            }
            shipToCustomerName
            shipToCustomerAddress
            pONumber
            buyerName
            shipDate
            orderAdminChs {
                inventory {
                    id
                    heatNo
                    size
                    vendor {
                        companyName
                    }
                    product {
                        id
                        name
                    }
                }
                id
                typeAorC
                typeLorB
                quantity
                grade
                startSize
                finishSizeHigh
                finishSizeLow
                rms
                feet
                inches
                inventoryNo
                heatNo
                dueDate
                um
                unitPrice
                total
                partNumber
                notes
                packaging
            }
            invoiceNo
            employee {
                id
                name
                email
            }
            grandTotal
            status
            notes
        }
    }
`

export const queryGetAllOrdersShop = gql`
    query OrderAdmin {
        getAllOrdersShop {
            id
            createdAt
            customer {
                id
                companyName
            }
            shipToCustomerName
            shipToCustomerAddress
            pONumber
            buyerName
            shipDate
            orderAdminChs {
                inventory {
                    id
                    heatNo
                    size
                    vendor {
                        companyName
                    }
                    product {
                        id
                        name
                    }
                }
                id
                typeAorC
                typeLorB
                quantity
                grade
                startSize
                finishSizeHigh
                finishSizeLow
                rms
                feet
                inches
                inventoryNo
                heatNo
                dueDate
                um
                unitPrice
                total
                partNumber
            }
            invoiceNo
            employee {
                id
                name
                email
            }
            grandTotal
            status
            notes
        }
    }
`

export const queryGetEmployeeId = gql`
    query getEmployee($id: ID!) {
        getEmployee(id: $id) {
            id
            name
            email
            address
            contact
            socialSecurity
            notes
            isActive
            attendanceStatus
        }
    }
`

export const queryGetAllInvoiceAR = gql`
    query getAllInvoiceARs {
        getAllInvoiceARs {
            id
            createdAt
            shippingCharges
            periodNo
            salesTaxAmount
            invoiceTotal
            status
            ignoreMinimumChanges

            orderAdmin {
                id
                pONumber
                buyerName
                grandTotal
                customer {
                    id
                    companyName
                    primaryAddress
                    city
                    state
                    postalCode
                    primaryContact
                    termsOfPayment
                }
                orderAdminChs {
                    id
                    typeAorC
                    typeLorB
                    quantity
                    grade
                    startSize
                    finishSizeHigh
                    finishSizeLow
                    rms
                    feet
                    inches
                    inventoryNo
                    heatNo
                    dueDate
                    um
                    unitPrice
                    total
                    partNumber
                }
            }
        }
    }
`

export const queryGetOrderAdminById = gql`
    query getOrderAdminById($id: ID!) {
        getOrderAdminById(id: $id) {
            id
            createdAt
            customer {
                id
                companyName
                shipVia
                primaryAddress
                primaryContact
                city
                state
                postalCode
            }
            shipToCustomerName
            shipToCustomerAddress
            pONumber
            buyerName
            shipDate
            orderAdminChs {
                id
                typeAorC
                typeLorB
                quantity
                grade
                startSize
                finishSizeHigh
                finishSizeLow
                rms
                feet
                inches
                inventoryNo
                heatNo
                dueDate
                um
                unitPrice
                total
                partNumber
            }
            invoiceNo
            employee {
                id
                name
                email
            }
            grandTotal
            status
            notes
        }
    }
`

export const queryGetAllShipping = gql`
    query getAllShipping {
        getAllShipping {
            id
            shippingCharges
            shipVia
            containerOptions
            quantity
            notes
            createdAt
            hasCustomerBilled
            orderAdmin {
                id
                customer {
                    id
                    companyName
                    shipVia
                    primaryAddress
                    primaryContact
                    city
                    state
                    postalCode
                }
                pONumber
                shipToCustomerAddress
                shipToCustomerName
                shipDate
                orderAdminChs {
                    id
                    typeAorC
                    typeLorB
                    quantity
                    grade
                    startSize
                    finishSizeHigh
                    finishSizeLow
                    rms
                    feet
                    inches
                    inventory {
                        id
                        country
                        notes
                    }
                    inventoryNo
                    heatNo
                    dueDate
                    um
                    unitPrice
                    total
                    partNumber
                    notes
                }
            }
        }
    }
`

export const queryGetAllInvoiceAP = gql`
    query getAllInvoiceAPs {
        getAllInvoiceAPs {
            id
            createdAt
            shippingCharges
            status
            invoiceTotal
            vendor {
                id
                companyName
                primaryAddress
            }
        }
    }
`
export const queryGetInvoiceAPByID = gql`
    query getInvoiceAPById($id: ID!) {
        getInvoiceAPById(id: $id) {
            id
            createdAt
            shippingCharges
            status
            invoiceTotal
            vendor {
                companyName
                primaryAddress
            }
        }
    }
`
export const queryGetAllNonConfirmance = gql`
    query getAllNCs {
        getAllNCs {
            id
            createdAt
            nonConformanceType
            nonConformanceCode
            invoiceTotal
            quantity
            customerCode
        }
    }
`
export const queryGetNonConfirmanceByID = gql`
    query getNCById($id: ID!) {
        getNCById(id: $id) {
            id
            createdAt
            nonConformanceType
            nonConformanceCode
            invoiceTotal
            quantity
            customerCode
        }
    }
`
export const queryGetInventoryByGrade = gql`
    query getInventoryByGrade($grade: String!, $size: Float, $heatNo: String) {
        getInventoryByGrade(grade: $grade, size: $size, heatNo: $heatNo) {
            id
            grade
            weight
            size
            feet
            inches
            heatNo
            vendor {
                companyName
            }
            product {
                id
                name
            }
        }
    }
`
export const queryGetCount = gql`
    query getCount {
        getCount {
            customerCount
            quotationCount
            orderCount
            vendorCount
            employeeCount
        }
    }
`
export const queryGetFiveOrders = gql`
    query getFiveOrders {
        getFiveOrders {
            id
            createdAt
            status
            grandTotal
            customer {
                id
                companyName
            }
        }
    }
`

export const queryGetFiveInvoiceAR = gql`
    query getFiveInvoicesAR {
        getFiveInvoicesAR {
            status
            invoiceTotal
            id
            createdAt
            orderAdmin {
                customer {
                    id
                    companyName
                }
            }
        }
    }
`
export const queryGetLastMonthQuotationOrdersRevenue = gql`
    query getLastMonthQuotationAndOrderRevenue {
        getLastMonthQuotationAndOrderRevenue {
            orderRevenue {
                date
                amount
            }
            quotationRevenue {
                date
                amount
            }
        }
    }
`
export const queryGetCurrDayAttendance = gql`
    query getCurrentDayAttendance {
        getCurrentDayAttendance {
            id
            attendanceStatus
            name
            attendance {
                id
                createdAt
                attendanceStatus
            }
        }
    }
`
export const queryDownloadFileFromCloud = gql`
    query downloadFile($bucketFileName: String!) {
        downloadFile(bucketFileName: $bucketFileName)
    }
`
