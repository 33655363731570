import { Remove, Add } from '@mui/icons-material'
import { Grid, IconButton, Button, MenuItem } from '@mui/material'
import { FieldArray } from 'formik'
import { FormikArrayField } from '../../components/Form/FormikArrayFeild'

const pkgTypes = [
    { value: 'Box', label: 'Box' },
    { value: 'Bundled', label: 'Bundled' },
    { value: 'Tube', label: 'Tube' },
]

interface ILabelItems {
    formik: any
}

export const LabelItems = ({ formik }: ILabelItems): ReactNode => {
    return (
        <Grid item container spacing={2}>
            <FieldArray name="labelItems">
                {({ remove, push }) => (
                    <>
                        {formik.values?.labelItems?.map(
                            (qty: any, index: number) => (
                                <Grid
                                    key={qty.id?.toString() || index}
                                    item
                                    container
                                    // alignItems="cent"
                                    spacing={2}
                                    alignItems="center"
                                >
                                    <Grid item xs="auto">
                                        <IconButton
                                            color="error"
                                            disabled={index === 0}
                                            onClick={() => remove(index)}
                                        >
                                            <Remove />
                                        </IconButton>
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        spacing={1}
                                        sm={11}
                                        md={11}
                                    >
                                        <Grid item xs={1.5}>
                                            <FormikArrayField
                                                formik={formik}
                                                label="Packages"
                                                name={`labelItems[${index}].pkg`}
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item xs={1.5}>
                                            <FormikArrayField
                                                formik={formik}
                                                label="Package Type"
                                                name={`labelItems[${index}].pkgType`}
                                                select
                                            >
                                                {pkgTypes.map((option) => (
                                                    <MenuItem
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </FormikArrayField>
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <FormikArrayField
                                                formik={formik}
                                                label="Heat Info"
                                                name={`labelItems[${index}].heatInfo`}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormikArrayField
                                                formik={formik}
                                                label="Piece Count"
                                                name={`labelItems[${index}].pieceCount`}
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item xs={1.5}>
                                            <FormikArrayField
                                                formik={formik}
                                                label="Tare"
                                                name={`labelItems[${index}].tare`}
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item xs={1.5}>
                                            <FormikArrayField
                                                formik={formik}
                                                label="Net"
                                                name={`labelItems[${index}].net`}
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item xs={1.5}>
                                            <FormikArrayField
                                                formik={formik}
                                                label="Gross"
                                                name={`labelItems[${index}].gross`}
                                                type="number"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        )}
                        <Grid item xs={12}>
                            <Button
                                startIcon={<Add />}
                                disabled={formik.isSubmitting}
                                onClick={() => push({})}
                            >
                                Add Item
                            </Button>
                        </Grid>
                    </>
                )}
            </FieldArray>
        </Grid>
    )
}
