import { Grid, Button, Box, Card, Typography, TextField } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { LoadingButton } from '@mui/lab'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useLazyQuery } from '@apollo/client'
import { useDebouncedCallback } from 'use-debounce'
import { queryGetVendorById } from '../../common/queries'
import { MutationAddInvoiceAP } from '../../common/Mutations'
import { NumberFormatter } from '../../utils/NumberFormatter'
import { IMessageContext, MessageContext } from '../../context/MessageContext'
import { FormikTextField } from '../../components/Form/FormikTextField'

export const CreateInvoiceAP = (): ReactNode => {
    const [getVendor, { data: vendorData }] = useLazyQuery(queryGetVendorById)

    const [vendorId, setVendorId] = useState<string>('')
    const formikConfig = {
        enableReinitialize: true,
        initialValues: {
            vendorId,
            shippingCharges: '',
            companyName: '',
            primaryAddress: '',
            invoiceTotal: '',
            status: '',
        },
        validationSchema: Yup.object({
            vendorId: Yup.string().required('required'),
        }),
    }

    const [addInvoiceAP] = useMutation(MutationAddInvoiceAP)

    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const navigate = useNavigate()

    const createAPSubmit = async (values, setSubmitting): Promise<boolean> => {
        try {
            setSubmitting(true)
            await addInvoiceAP({
                variables: values,
            })
            setSubmitting(false)
            showSnackbar('Success !! New Invoice AP Created')
            navigate('/dashboard/ap')
            return true
        } catch (error) {
            setSubmitting(false)
            showSnackbar(error.message, true)
            return false
        }
    }
    const debounced = useDebouncedCallback((value) => {
        getVendor({
            variables: {
                id: value,
            },
        })
    }, 800)
    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                py: 8,
                px: 4,
            }}
        >
            <Card>
                <Typography variant="h4" sx={{ my: 2, mx: 2 }}>
                    Create Invoice AP
                </Typography>
                <Box>
                    <Formik
                        {...formikConfig}
                        onSubmit={(values, { setSubmitting }) => {
                            createAPSubmit(values, setSubmitting)
                        }}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ py: 2, px: 2 }}
                                >
                                    <Grid item xs={12} sm={6} md={2}>
                                        <Field
                                            error={Boolean(
                                                formik.touched.vendorId &&
                                                    formik.errors.vendorId
                                            )}
                                            helperText={
                                                formik.touched.vendorId &&
                                                formik.errors.vendorId
                                            }
                                            as={TextField}
                                            label="Vendor ID"
                                            name="vendorId"
                                            fullWidth
                                            onChange={(event) => {
                                                debounced(event.target.value)
                                                setVendorId(event.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <TextField
                                            fullWidth
                                            label="Vendor Name"
                                            value={
                                                vendorData?.getVendor
                                                    ?.companyName || ''
                                            }
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <TextField
                                            fullWidth
                                            label="Company Address"
                                            value={
                                                vendorData?.getVendor
                                                    ?.primaryAddress || ''
                                            }
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Shipping Charges"
                                            name="shippingCharges"
                                            type="number"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Invoice Total"
                                            name="invoiceTotal"
                                            type="number"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Status"
                                            name="status"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                fullWidth
                                                label="Grand Total"
                                                disabled
                                                value={NumberFormatter(
                                                    Number(
                                                        formik.values
                                                            .invoiceTotal
                                                    ) +
                                                        Number(
                                                            formik.values
                                                                .shippingCharges
                                                        ),
                                                    2
                                                )}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={6} sm={4} md={3}>
                                            <Button
                                                color="error"
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                variant="outlined"
                                                fullWidth
                                                onClick={() => {
                                                    formik.resetForm()
                                                    navigate('/dashboard/ap')
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={3}>
                                            <LoadingButton
                                                color="success"
                                                loadingPosition="end"
                                                loading={formik.isSubmitting}
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                fullWidth
                                            >
                                                {formik.isSubmitting
                                                    ? 'Submitting'
                                                    : 'Submit'}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Card>
        </Box>
    )
}
