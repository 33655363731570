import {
    Grid,
    Button,
    Box,
    Card,
    Typography,
    TextField,
    Autocomplete,
} from '@mui/material'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { LoadingButton } from '@mui/lab'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation, useLazyQuery, useQuery } from '@apollo/client'
import { useDebouncedCallback } from 'use-debounce'
import { FormikTextField } from '../../components/Form/FormikTextField'
import { MessageContext, IMessageContext } from '../../context/MessageContext'
import { MutationCreateInventory } from '../../common/Mutations'
import { InventoryQuantity } from './InventoryQuantity'
import { queryGetAllProducts, queryGetVendorById } from '../../common/queries'

const calculator = (formik, quantity, selectedGrade): void => {
    const { values } = formik

    const { unitWeight } = selectedGrade
    const totalBars = quantity / (values.size * values.size * unitWeight)
    // const firstPart =
    //     values.size *
    //     values.size *
    //     (unitWeight / 12) *
    //     (values.feet / (values.feet * 12))
    // const weight = firstPart * (values.feet * 12 + values.inches) * quantity
    formik.setFieldValue('totalBars', totalBars)
    formik.setFieldValue('weight', quantity)
}

const CreateInventoryForm = ({ formik }: { formik: any }): ReactNode => {
    const navigate = useNavigate()

    const [getVendorById, { data: vendorData }] = useLazyQuery(
        queryGetVendorById
    )

    const { data: allProducts, loading } = useQuery(queryGetAllProducts)
    const [allGrades, setGrades] = useState<any>([])

    useEffect(() => {
        if (!loading && allProducts && allProducts?.getAllProducts) {
            const temp = []
            // eslint-disable-next-line array-callback-return
            allProducts?.getAllProducts.map((prod): void => {
                // eslint-disable-next-line array-callback-return
                prod?.grades.map((grade): void => {
                    temp.push({
                        label: grade.name,
                        product: prod.name,
                        unitWeight: prod.unitWeight,
                    })
                })
            })
            setGrades(temp)
        }
    }, [loading, allProducts])

    const [totalQuantity, setTotalQuantity] = useState(0)
    const [selectedGrade, setSelectedGrade] = useState<any>({})
    useEffect(() => {
        calculator(formik, totalQuantity, selectedGrade)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        totalQuantity,
        formik.values.size,
        formik.values.feet,
        formik.values.inches,
        selectedGrade,
    ])
    const debounced = useDebouncedCallback((value) => {
        getVendorById({
            variables: {
                id: value,
            },
        })
    }, 800)

    return (
        <Form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} sx={{ py: 2, px: 2 }}>
                <Grid item sm={12} md={1.5}>
                    <FormikTextField
                        formik={formik}
                        label="P.O No"
                        name="purchaseOrderNo"
                    />
                </Grid>

                <Grid item sm={12} md={1.5}>
                    <Field
                        error={Boolean(
                            formik.touched.vendorId && formik.errors.vendorId
                        )}
                        helperText={
                            formik.touched.vendorId && formik.errors.vendorId
                        }
                        as={TextField}
                        label="Vendor ID"
                        name="vendorId"
                        fullWidth
                        onChange={(event) => {
                            formik.setFieldValue('vendorId', event.target.value)
                            debounced(event.target.value)
                        }}
                    />
                </Grid>

                <Grid item sm={12} md={2}>
                    <TextField
                        fullWidth
                        label="Vendor Name"
                        value={
                            vendorData?.getVendor?.companyName
                                ? vendorData?.getVendor?.companyName
                                : ''
                        }
                        disabled
                    />
                </Grid>
                <Grid item sm={12} md={2}>
                    <Autocomplete
                        options={allGrades}
                        autoSelect
                        disableClearable
                        isOptionEqualToValue={(option, value) =>
                            option.label === value.label
                        }
                        onChange={(event: any, newValue: any) => {
                            setSelectedGrade(newValue)
                            formik.setFieldValue(`grade`, newValue.label)
                            formik.setFieldValue(
                                'productName',
                                newValue.product
                            )
                        }}
                        value={formik.values.grade || ''}
                        loading={loading}
                        renderInput={(params) => (
                            <TextField {...params} label="Grade" />
                        )}
                    />
                </Grid>

                <Grid item sm={12} md={2}>
                    <Field
                        error={Boolean(
                            formik.touched.vendorId && formik.errors.vendorId
                        )}
                        helperText={
                            formik.touched.vendorId && formik.errors.vendorId
                        }
                        as={TextField}
                        label="Product Name"
                        name="productName"
                        fullWidth
                        disabled
                    />
                </Grid>

                <Grid item sm={12} md={2}>
                    <FormikTextField
                        formik={formik}
                        label="Size"
                        name="size"
                        type="number"
                        step="0.01"
                    />
                </Grid>

                <Grid item sm={6} md={2}>
                    <FormikTextField
                        formik={formik}
                        label="Feet"
                        name="feet"
                        type="number"
                        step="0.01"
                    />
                </Grid>

                <Grid item sm={6} md={2}>
                    <FormikTextField
                        formik={formik}
                        label="Inches"
                        name="inches"
                        type="number"
                        step="0.01"
                    />
                </Grid>

                <InventoryQuantity
                    formik={formik}
                    setTotalQuantity={setTotalQuantity}
                />

                <Grid item sm={12} md={6}>
                    <FormikTextField
                        formik={formik}
                        label="Heat no"
                        name="heatNo"
                    />
                </Grid>

                <Grid item sm={6} md={3}>
                    <FormikTextField
                        formik={formik}
                        label="Weight"
                        name="weight"
                        type="number"
                        step="0.01"
                        disabled
                        value={(parseFloat(formik.values.weight) || 0).toFixed(
                            2
                        )}
                    />
                </Grid>

                <Grid item sm={6} md={3}>
                    <FormikTextField
                        formik={formik}
                        label="Total Bars"
                        name="totalBars"
                        type="number"
                        disabled
                        value={(
                            parseFloat(formik.values.totalBars) || 0
                        ).toFixed(2)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormikTextField
                        formik={formik}
                        label="Material Spec"
                        name="notes"
                        multiline
                        rows="3"
                    />
                </Grid>

                <Grid item xs={6}>
                    <FormikTextField
                        formik={formik}
                        label="Country/Origin"
                        name="country"
                    />
                </Grid>

                <Grid item container spacing={2} justifyContent="flex-end">
                    <Grid item xs={6} sm={4} md={3}>
                        <Button
                            color="error"
                            disabled={formik.isSubmitting}
                            size="large"
                            variant="outlined"
                            fullWidth
                            onClick={() => {
                                formik.resetForm()
                                navigate('/dashboard/all-inventory', {
                                    replace: true,
                                })
                            }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                        <LoadingButton
                            color="success"
                            loadingPosition="end"
                            loading={formik.isSubmitting}
                            disabled={formik.isSubmitting}
                            size="large"
                            type="submit"
                            variant="contained"
                            fullWidth
                        >
                            {formik.isSubmitting ? 'Submitting' : 'Submit'}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        </Form>
    )
}

const formikConfig = {
    initialValues: {
        purchaseOrderNo: '',
        vendorId: '',
        productName: '',
        grade: '',
        size: '',
        feet: '',
        inches: '',
        heatNo: '',
        notes: '',
        quantity: [{ quantity: '' }],
        weight: '',
        totalBars: '',
        country: '',
    },
    validationSchema: Yup.object({
        vendorId: Yup.string().max(255).required('Field is required'),
        productName: Yup.string().required('Field is required'),
        size: Yup.number().required('Field is required'),
        feet: Yup.number().required('Field is required'),
        inches: Yup.number().required('Field is required'),
        quantity: Yup.array().of(
            Yup.object().shape({
                quantity: Yup.number().required('required'),
            })
        ),
    }),
}

export const CreateInventory = (): ReactNode => {
    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const navigate = useNavigate()

    const [addInventory] = useMutation(MutationCreateInventory)

    const createInventorySubmit = async (
        values,
        setSubmitting
    ): Promise<boolean> => {
        try {
            setSubmitting(true)
            await addInventory({
                variables: values,
            })

            setSubmitting(false)
            showSnackbar('Success !! Inventory Created')
            navigate('/dashboard/all-inventory', {
                replace: true,
            })
            return true
        } catch (error) {
            setSubmitting(false)
            showSnackbar(error.message, true)
            return false
        }
    }

    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                py: 8,
                px: 4,
            }}
        >
            <Card>
                <Typography variant="h4" sx={{ my: 2, mx: 2 }}>
                    Create Inventory
                </Typography>
                <Box>
                    <Formik
                        {...formikConfig}
                        onSubmit={(values, { setSubmitting }) => {
                            createInventorySubmit(values, setSubmitting)
                        }}
                    >
                        {(formik) => <CreateInventoryForm formik={formik} />}
                    </Formik>
                </Box>
            </Card>
        </Box>
    )
}
