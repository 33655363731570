import { Remove, Add } from '@mui/icons-material'
import { Grid, Typography, IconButton, Button } from '@mui/material'
import { FieldArray } from 'formik'
import { FormikArrayField } from '../../components/Form/FormikArrayFeild'

export const SecondaryContacts = ({ formik }: { formik: any }): ReactNode => {
    return (
        <Grid item container spacing={2}>
            <Grid item>
                <Typography variant="h6">Secondary Contacts</Typography>
            </Grid>
            <FieldArray name="secondaryContacts">
                {({ remove, push }) => (
                    <>
                        {formik.values.secondaryContacts.map(
                            (secondaryContact: any, index: number) => (
                                <Grid
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index.toString()}
                                    item
                                    container
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Grid item xs="auto">
                                        <IconButton
                                            color="error"
                                            onClick={() => remove(index)}
                                        >
                                            <Remove />
                                        </IconButton>
                                    </Grid>

                                    <Grid item sm={12} md={4}>
                                        <FormikArrayField
                                            formik={formik}
                                            label="Name"
                                            name={`secondaryContacts[${index}].name`}
                                            value={secondaryContact.name}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md>
                                        <FormikArrayField
                                            formik={formik}
                                            label="Email"
                                            name={`secondaryContacts[${index}].contact`}
                                            value={secondaryContact.contact}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md>
                                        <FormikArrayField
                                            formik={formik}
                                            label="Position"
                                            name={`secondaryContacts[${index}].position`}
                                            value={secondaryContact.position}
                                        />
                                    </Grid>
                                </Grid>
                            )
                        )}
                        <Grid item xs={12}>
                            <Button
                                startIcon={<Add />}
                                disabled={formik.isSubmitting}
                                onClick={() =>
                                    push({
                                        name: '',
                                        contact: '',
                                    })
                                }
                            >
                                Add Contact
                            </Button>
                        </Grid>
                    </>
                )}
            </FieldArray>
        </Grid>
    )
}
