import { LoadingButton } from '@mui/lab'
import { Grid, Box, Card, Typography, TextField } from '@mui/material'
import { Form, Formik, Field } from 'formik'
import { useContext } from 'react'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { updatePassword } from 'firebase/auth'
import { IMessageContext, MessageContext } from '../../context/MessageContext'
import { auth } from '../../config/firebase-config.js'

const validationSchema = Yup.object({
    password: Yup.string()
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .required('Password is required'),
    confirmPassword: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Passwords must match'
    ),
})

export const UpdatePassword = (): ReactNode => {
    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const navigate = useNavigate()
    const updateUserPassword = async (
        values,
        setSubmitting
    ): Promise<boolean> => {
        try {
            setSubmitting(true)
            const user = auth.currentUser
            await updatePassword(user, values.password)

            showSnackbar('Success !! Password Updated !! ')
            navigate('/dashboard/users', {
                replace: true,
            })
            setSubmitting(false)

            return true
        } catch (error) {
            setSubmitting(false)
            showSnackbar(error.message, true)
            return false
        }
    }

    const formikConfig = {
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validationSchema,
    }
    return (
        <Card>
            <Typography variant="h4" sx={{ my: 2, mx: 2 }}>
                Update Password
            </Typography>
            <Box>
                <Formik
                    {...formikConfig}
                    onSubmit={(values, { setSubmitting }) => {
                        updateUserPassword(values, setSubmitting)
                    }}
                >
                    {(formik) => (
                        <Form onSubmit={formik.handleSubmit}>
                            <Grid container spacing={2} sx={{ py: 2, px: 2 }}>
                                <Grid item sm={12} md={6}>
                                    <Field
                                        as={TextField}
                                        error={Boolean(
                                            formik.touched.password &&
                                                formik.errors.password
                                        )}
                                        fullWidth
                                        helperText={
                                            formik.touched.password &&
                                            formik.errors.password
                                        }
                                        label="Password"
                                        name="password"
                                        type="password"
                                    />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <Field
                                        as={TextField}
                                        error={Boolean(
                                            formik.touched.confirmPassword &&
                                                formik.errors.confirmPassword
                                        )}
                                        fullWidth
                                        helperText={
                                            formik.touched.confirmPassword &&
                                            formik.errors.confirmPassword
                                        }
                                        formik={formik}
                                        label="Confirm Password"
                                        name="confirmPassword"
                                        type="password"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    container
                                    spacing={2}
                                    justifyContent="flex-end"
                                >
                                    <Grid item xs={6} sm={4} md={3}>
                                        <LoadingButton
                                            color="info"
                                            loadingPosition="end"
                                            loading={formik.isSubmitting}
                                            disabled={formik.isSubmitting}
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            fullWidth
                                        >
                                            {formik.isSubmitting
                                                ? 'Updating'
                                                : 'Update'}
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Card>
    )
}
