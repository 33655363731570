import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { AppBar, Box, IconButton, Toolbar } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import AccountPopover from './AccountPopover'

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
}))

interface IDashboardNavbar {
    onSidebarOpen?: () => void
}

export const DashboardNavbar = (props: IDashboardNavbar): ReactNode => {
    const { onSidebarOpen, ...rest } = props

    return (
        <>
            <DashboardNavbarRoot
                sx={{
                    left: {
                        lg: 280,
                    },
                    width: {
                        lg: 'calc(100% - 280px)',
                    },
                }}
                {...rest}
            >
                <Toolbar
                    disableGutters
                    sx={{
                        minHeight: 64,
                        left: 0,
                        px: 2,
                    }}
                >
                    <IconButton
                        onClick={onSidebarOpen}
                        sx={{
                            display: {
                                xs: 'inline-flex',
                                lg: 'none',
                            },
                        }}
                    >
                        <MenuIcon fontSize="small" />
                    </IconButton>
                    {/* <Tooltip title="Search">
                        <IconButton sx={{ ml: 1 }}>
                            <SearchIcon fontSize="small" />
                        </IconButton>
                    </Tooltip> */}
                    <Box sx={{ flexGrow: 1 }} />
                    {/* <Tooltip title="Contacts">
                        <IconButton sx={{ ml: 1 }}>
                            <UsersIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Notifications">
                        <IconButton sx={{ ml: 1 }}>
                            <Badge
                                badgeContent={4}
                                color="primary"
                                variant="dot"
                            >
                                <BellIcon fontSize="small" />
                            </Badge>
                        </IconButton>
                    </Tooltip> */}
                    <AccountPopover />
                </Toolbar>
            </DashboardNavbarRoot>
        </>
    )
}

DashboardNavbar.defaultProps = {
    onSidebarOpen: undefined,
}
DashboardNavbar.propTypes = {
    onSidebarOpen: PropTypes.func,
}
