import { LoadingButton } from '@mui/lab'
import { Grid, Button, Box, Card, Typography, TextField } from '@mui/material'
import { Form, Formik, Field } from 'formik'
import { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import moment from 'moment-timezone'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useDebouncedCallback } from 'use-debounce'
import { FormikTextField } from '../../components/Form/FormikTextField'
import { IMessageContext, MessageContext } from '../../context/MessageContext'
import { PurchaseItems } from './PurchaseItems'
import { queryGetVendorById } from '../../common/queries'
import { MutationUpdatePurchaseOrder } from '../../common/Mutations'
import { NumberFormatter } from '../../utils/NumberFormatter'

const validationSchema = Yup.object({
    vendorId: Yup.string().required('Vendor is required'),
    products: Yup.array().of(
        Yup.object().shape({
            quantity: Yup.string().required('required'),
            unitOfMeasure: Yup.string().required('required'),
            unitPrice: Yup.string().required('required'),
        })
    ),
})

export const EditPurchaseOrder = (): ReactNode => {
    const [updatePurchaseOrder] = useMutation(MutationUpdatePurchaseOrder)
    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const location = useLocation()
    const navigate = useNavigate()
    const { purchaseOrder } = location.state as {
        purchaseOrder: IPurchaseOrder
    }

    const [getVendorById, { data: vendorData }] = useLazyQuery(
        queryGetVendorById
    )

    const updatePurchaseOrderSubmit = async (
        values,
        setSubmitting
    ): Promise<boolean> => {
        try {
            await updatePurchaseOrder({
                variables: values,
            })
            showSnackbar('Success !! Purhcase Order Updated !! ')
            navigate('/dashboard/purchase-orders', {
                replace: true,
            })
            setSubmitting(false)

            return true
        } catch (error) {
            setSubmitting(false)
            showSnackbar('Error !! unable to update purchase order ..', true)
            return false
        }
    }

    useEffect(() => {
        getVendorById({
            variables: {
                id: purchaseOrder?.vendor?.id,
            },
        })
    }, [getVendorById, purchaseOrder])

    const formikConfig = {
        initialValues: {
            id: purchaseOrder?.id || '',
            vendorId: purchaseOrder?.vendor?.id || '',
            primaryAddress: purchaseOrder?.vendor?.primaryAddress || '',
            createdAt:
                moment(Number(purchaseOrder?.createdAt)).format('DD-MM-YYYY') ||
                '',
            products: purchaseOrder?.products || [],
            contactName: purchaseOrder?.contactName,
            companyName: purchaseOrder?.vendor?.companyName || '',
        },
        validationSchema,
    }

    const debounced = useDebouncedCallback((value) => {
        getVendorById({
            variables: {
                id: value,
            },
        })
    }, 800)

    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                py: 8,
                px: 4,
            }}
        >
            <Card>
                <Typography variant="h4" sx={{ my: 2, mx: 2 }}>
                    Edit Purchase Order
                </Typography>
                <Box>
                    <Formik
                        {...formikConfig}
                        onSubmit={(values, { setSubmitting }) => {
                            updatePurchaseOrderSubmit(values, setSubmitting)
                        }}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ py: 2, px: 2 }}
                                >
                                    <Grid item sm={12} md={1.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Purchase Order ID"
                                            name="id"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Date"
                                            name="createdAt"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={1.5}>
                                        <Field
                                            error={Boolean(
                                                formik.touched.vendorId &&
                                                    formik.errors.vendorId
                                            )}
                                            helperText={
                                                formik.touched.vendorId &&
                                                formik.errors.vendorId
                                            }
                                            as={TextField}
                                            label="Vendor ID"
                                            name="vendorId"
                                            fullWidth
                                            onChange={(event) => {
                                                formik.setFieldValue(
                                                    'vendorId',
                                                    event.target.value
                                                )
                                                debounced(event.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2.5}>
                                        <TextField
                                            fullWidth
                                            label="Company/Vendor Name"
                                            value={
                                                vendorData?.getVendor
                                                    ?.companyName
                                                    ? vendorData?.getVendor
                                                          ?.companyName
                                                    : ''
                                            }
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={4}>
                                        <TextField
                                            fullWidth
                                            label="Company/Vendor Address"
                                            value={
                                                vendorData?.getVendor
                                                    ?.primaryAddress
                                                    ? vendorData?.getVendor
                                                          ?.primaryAddress
                                                    : ''
                                            }
                                            disabled
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={3}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Contact Name"
                                            name="contactName"
                                        />
                                    </Grid>

                                    <PurchaseItems formik={formik} />

                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent="flex-end"
                                        alignItems="center"
                                    >
                                        {/* <Grid item xs={4} /> */}
                                        <Grid item>
                                            <Typography variant="h5">
                                                Total
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={9} sm={9} md={3}>
                                            <TextField
                                                fullWidth
                                                disabled
                                                value={NumberFormatter(
                                                    formik.values.products.reduce(
                                                        (n, item) =>
                                                            n +
                                                                item.quantity *
                                                                    item.unitPrice ||
                                                            0,
                                                        0
                                                    )
                                                )}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={6} sm={4} md={3}>
                                            <Button
                                                color="error"
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                variant="outlined"
                                                fullWidth
                                                onClick={() => {
                                                    formik.resetForm()
                                                    navigate(
                                                        '/dashboard/purchase-orders'
                                                    )
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={3}>
                                            <LoadingButton
                                                color="success"
                                                loadingPosition="end"
                                                loading={formik.isSubmitting}
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                fullWidth
                                            >
                                                {formik.isSubmitting
                                                    ? 'Submitting'
                                                    : 'Submit'}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Card>
        </Box>
    )
}
