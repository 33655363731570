import { LoadingButton } from '@mui/lab'
import { Typography, Button, Grid } from '@mui/material'
import { Modal } from './Modal'

export interface IUploadFileModal {
    open: boolean
    uploading: boolean
    dialogTitle: string
    onClose: () => void
    onUpload: () => void
    children?: ReactNode[] | ReactNode
}

export const UploadFileModal = ({
    open,
    uploading,
    dialogTitle,
    onClose,
    onUpload,
    children,
}: IUploadFileModal): ReactNode => {
    return (
        <Modal
            open={open}
            onClose={() => !uploading && onClose()}
            dialogTitle={dialogTitle}
            closeIcon={!uploading}
            maxWidth="md"
        >
            <br />
            <Typography variant="body1">{children}</Typography>
            <Grid
                container
                spacing={2}
                justifyContent="flex-end"
                alignItems="center"
                sx={{ pt: 4 }}
            >
                <Grid item xs={6} sm={5} md={2}>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                        disabled={uploading}
                        fullWidth
                        color="error"
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6} sm={5} md={2}>
                    <LoadingButton
                        loadingPosition="end"
                        loading={uploading}
                        disabled={uploading}
                        variant="contained"
                        color="success"
                        onClick={onUpload}
                        fullWidth
                    >
                        {uploading ? 'Uploading' : 'Upload'}
                    </LoadingButton>
                </Grid>
            </Grid>
        </Modal>
    )
}
