import { LoadingButton } from '@mui/lab'
import { Grid, Button, Box, Card, Typography, TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import { useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment-timezone'
import { useMutation } from '@apollo/client'
import { PlayArrow, Pause, Stop } from '@mui/icons-material'
import { FormikTextField } from '../../components/Form/FormikTextField'
import { IMessageContext, MessageContext } from '../../context/MessageContext'
import { WorkOrderItems } from './WorkOrderItems'
import {
    MutationUpdateOrderEmployee,
    MutationAddTime,
} from '../../common/Mutations'
import { TimeSheet } from './TimeSheet'
import { DeleteModal } from '../../components/Modal/DeleteModal'

export const EditWorkOrder = (): ReactNode => {
    const [updateEmployeeOrder] = useMutation(MutationUpdateOrderEmployee)
    const [addTime] = useMutation(MutationAddTime)

    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const location = useLocation()
    const navigate = useNavigate()
    const { workOrder } = location.state as {
        workOrder: IOrderAdmin
    }
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [btnLoading, setBtnLoading] = useState<boolean>(false)
    const [action, setAction] = useState<string>('')

    const timerAction = async (): Promise<void> => {
        let status = ''
        if (action === 'start') {
            status = 'IN PROGRESS'
        } else if (action === 'pause') {
            status = 'PAUSED'
        } else {
            status = 'READY TO SHIP'
        }
        try {
            const { data } = await addTime({
                variables: {
                    orderAdminId: workOrder.id,
                    status,
                },
            })
            if (data.addTime) {
                showSnackbar(`Action updated ..`)
            }
            if (status === 'READY TO SHIP') {
                navigate('/shop/work-orders', {
                    replace: true,
                })
            }
            workOrder.status = status
            setAction('')
            setBtnLoading(false)
            setModalOpen(false)
        } catch (error) {
            showSnackbar(error.message, true)
        }
    }

    const handleWorkOrderUpdate = async (
        values,
        setSubmitting
    ): Promise<boolean> => {
        try {
            await updateEmployeeOrder({
                variables: {
                    orderAdminId: workOrder.id,
                    employeeId: values.timeSheet,
                },
            })
            showSnackbar('Success !! Employee(s) added with the order ')
            setSubmitting(false)

            return true
        } catch (error) {
            setSubmitting(false)
            showSnackbar(error.message, true)
            return false
        }
    }

    const formikConfig = {
        reinitialize: true,
        initialValues: {
            id: workOrder?.id || '',
            customer: workOrder?.customer,
            shipToCustomerName: workOrder?.shipToCustomerName || '',
            shipToCustomerAddress: workOrder?.shipToCustomerAddress || '',
            pONumber: workOrder?.pONumber || '',
            buyerName: workOrder?.buyerName || '',
            shipDate: workOrder?.shipDate || '',
            invoiceNo: workOrder?.invoiceNo || '',
            orderAdminChs: workOrder?.orderAdminChs || [],
            timeSheet: workOrder?.employee || [],
            createdAt:
                moment(Number(workOrder?.createdAt)).format('DD-MM-YYYY') || '',
        },
    }
    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                py: 8,
                px: 4,
            }}
        >
            <Card>
                <Typography variant="h4" sx={{ my: 2, mx: 2 }}>
                    Work Order
                </Typography>
                <Box>
                    <Formik
                        {...formikConfig}
                        onSubmit={(values, { setSubmitting }) => {
                            handleWorkOrderUpdate(values, setSubmitting)
                        }}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ py: 2, px: 2 }}
                                >
                                    <Grid item xs={12} sm={6} md={1.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Order ID"
                                            name="id"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Date"
                                            name="createdAt"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={1.5}>
                                        <TextField
                                            label="Customer ID"
                                            value={
                                                formik.values.customer?.id || ''
                                            }
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2.5}>
                                        <TextField
                                            label="Customer Name"
                                            value={
                                                formik.values.customer
                                                    ?.companyName || ''
                                            }
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                            label="Customer Address"
                                            value={
                                                formik.values.customer
                                                    ?.primaryAddress || ''
                                            }
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Ship To Customer Name"
                                            name="shipToCustomerName"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Ship To Customer Address"
                                            name="shipToCustomerAddress"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="PO Number"
                                            name="pONumber"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Buyer Name"
                                            name="buyerName"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Ship Date"
                                            name="shipDate"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Invoice Number"
                                            name="invoiceNo"
                                            disabled
                                        />
                                    </Grid>

                                    <WorkOrderItems
                                        formik={formik}
                                        disableAll
                                    />
                                    <Grid item xs={12} textAlign="left">
                                        <Typography variant="h4">
                                            Time Tracker
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent="center"
                                    >
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="success"
                                                size="large"
                                                onClick={() => {
                                                    setAction('start')
                                                    setModalOpen(true)
                                                }}
                                                disabled={
                                                    workOrder.status !==
                                                        'OPEN' &&
                                                    workOrder.status !==
                                                        'PAUSED'
                                                }
                                                endIcon={
                                                    <PlayArrow fontSize="large" />
                                                }
                                            >
                                                Start Order
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="info"
                                                size="large"
                                                onClick={() => {
                                                    setAction('pause')
                                                    setModalOpen(true)
                                                }}
                                                disabled={
                                                    workOrder.status !==
                                                    'IN PROGRESS'
                                                }
                                                endIcon={
                                                    <Pause fontSize="large" />
                                                }
                                            >
                                                Pause Order
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="error"
                                                size="large"
                                                onClick={() => {
                                                    setAction('stop')
                                                    setModalOpen(true)
                                                }}
                                                disabled={
                                                    workOrder.status !==
                                                        'IN PROGRESS' &&
                                                    workOrder.status !==
                                                        'PAUSED'
                                                }
                                                endIcon={
                                                    <Stop fontSize="large" />
                                                }
                                            >
                                                Complete Order
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <TimeSheet formik={formik} />

                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={6} sm={4} md={3}>
                                            <Button
                                                color="error"
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                variant="outlined"
                                                fullWidth
                                                onClick={() => {
                                                    formik.resetForm()
                                                    navigate(
                                                        '/shop/work-orders'
                                                    )
                                                }}
                                            >
                                                Back
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={3}>
                                            <LoadingButton
                                                color="success"
                                                loadingPosition="end"
                                                loading={formik.isSubmitting}
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                fullWidth
                                            >
                                                {formik.isSubmitting
                                                    ? 'Submitting'
                                                    : 'Submit'}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                    <DeleteModal
                        dialogTitle="Confirm Action"
                        open={Boolean(modalOpen)}
                        onClose={() => {
                            setModalOpen(false)
                            setAction('')
                        }}
                        onDeleteConfirm={timerAction}
                        deleting={btnLoading}
                    >
                        Are you sure to {action} this order?
                    </DeleteModal>
                </Box>
            </Card>
        </Box>
    )
}
