import { Grid, Button, Box, Card, Typography } from '@mui/material'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { LoadingButton } from '@mui/lab'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { FormikTextField } from '../../components/Form/FormikTextField'
import { MessageContext, IMessageContext } from '../../context/MessageContext'
import { MutationAddVendor } from '../../common/Mutations'
import { SecondaryAddresses } from '../Customer/SecondaryAddresses'
import { SecondaryContacts } from '../Customer/SecondaryContacts'

const formikConfig = {
    initialValues: {
        companyName: '',
        primaryAddress: '',
        secondaryAddresses: [],
        fax: '',
        primaryContact: '',
        secondaryContacts: [],
        termsOfPayment: '',
        notes: '',
        state: '',
        city: '',
        zipCode: '',
    },
    validationSchema: Yup.object({
        companyName: Yup.string().max(255).required('Company Name is required'),
        primaryAddress: Yup.string()
            .max(255)
            .required('Company Address is required'),
        secondaryAddresses: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required('Name is required'),
                address: Yup.string().required('Address is required'),
            })
        ),
        secondaryContacts: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required('Name is required'),
                contact: Yup.string().required('Contact is required'),
            })
        ),
        notes: Yup.string().notRequired(),
    }),
}

export function CreateVendor(): ReactNode {
    const [addVendor] = useMutation(MutationAddVendor)

    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const navigate = useNavigate()

    const createVendorSubmit = async (
        values,
        setSubmitting
    ): Promise<boolean> => {
        try {
            setSubmitting(true)
            await addVendor({
                variables: values,
            })
            setSubmitting(false)
            showSnackbar('Success !! Vendor Created')
            navigate('/dashboard/vendors')

            return true
        } catch (error) {
            setSubmitting(false)
            showSnackbar(error.message, true)
            return false
        }
    }

    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                py: 8,
                px: 4,
            }}
        >
            <Card>
                <Typography variant="h4" sx={{ my: 2, mx: 2 }}>
                    Create Vendor
                </Typography>

                <Box>
                    <Formik
                        {...formikConfig}
                        onSubmit={(values, { setSubmitting }) => {
                            createVendorSubmit(values, setSubmitting)
                        }}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ py: 2, px: 2 }}
                                >
                                    <Grid item sm={12} md={3}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Company Name"
                                            name="companyName"
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={4}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Company Address"
                                            name="primaryAddress"
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={3}>
                                        <FormikTextField
                                            formik={formik}
                                            label="City"
                                            name="city"
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2}>
                                        <FormikTextField
                                            formik={formik}
                                            label="State"
                                            name="state"
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Zip code"
                                            name="zipCode"
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Phone No."
                                            name="primaryContact"
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Fax"
                                            name="fax"
                                        />
                                    </Grid>

                                    <Grid item sm={12} md={3}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Terms For Payment"
                                            name="termsOfPayment"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Notes"
                                            name="notes"
                                            multiline
                                            rows="3"
                                        />
                                    </Grid>

                                    <SecondaryAddresses formik={formik} />

                                    <SecondaryContacts formik={formik} />

                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={6} sm={4} md={3}>
                                            <Button
                                                color="error"
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                variant="outlined"
                                                fullWidth
                                                onClick={() => {
                                                    formik.resetForm()
                                                    navigate(
                                                        '/dashboard/vendors'
                                                    )
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={3}>
                                            <LoadingButton
                                                color="success"
                                                loadingPosition="end"
                                                loading={formik.isSubmitting}
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                fullWidth
                                            >
                                                {formik.isSubmitting
                                                    ? 'Submitting'
                                                    : 'Submit'}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Card>
        </Box>
    )
}
