import { LoadingButton } from '@mui/lab'
import { Grid, Button, Box, Card, Typography, TextField } from '@mui/material'
import { Form, Formik, Field } from 'formik'
import { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import moment from 'moment-timezone'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useDebouncedCallback } from 'use-debounce'
import { FormikTextField } from '../../components/Form/FormikTextField'
import { IMessageContext, MessageContext } from '../../context/MessageContext'
import { queryGetVendorById } from '../../common/queries'
import { MutationUpdateInvoiceAP } from '../../common/Mutations'
import { NumberFormatter } from '../../utils/NumberFormatter'

const validationSchema = Yup.object({
    vendorId: Yup.string().required('required'),
})

export const EditAP = (): ReactNode => {
    const [updateInvoiceAP] = useMutation(MutationUpdateInvoiceAP)
    const { showSnackbar } = useContext(MessageContext) as IMessageContext
    const location = useLocation()
    const navigate = useNavigate()
    const [getVendor, { data: vendorData }] = useLazyQuery(queryGetVendorById)

    const { APItem } = location.state as {
        APItem: IAP
    }

    const handleInvoiceARUpdate = async (
        values,
        setSubmitting
    ): Promise<boolean> => {
        try {
            setSubmitting(true)
            await updateInvoiceAP({
                variables: values,
            })

            showSnackbar('Success !! Invoice AP Updated !! ')
            navigate('/dashboard/ap', {
                replace: true,
            })
            setSubmitting(false)

            return true
        } catch (error) {
            setSubmitting(false)
            showSnackbar(error.message, true)
            return false
        }
    }

    useEffect(() => {
        getVendor({
            variables: {
                id: APItem?.vendor.id,
            },
        })
    }, [getVendor, APItem])

    const formikConfig = {
        initialValues: {
            id: APItem?.id || '',
            vendorId: APItem?.vendor?.id || '',
            shippingCharges: APItem?.shippingCharges || 0,
            status: APItem?.status || '',
            invoiceTotal: APItem?.invoiceTotal || 0,
            createdAt:
                moment(Number(APItem?.createdAt)).format('DD-MM-YYYY') || '',
        },
        validationSchema,
    }

    const debounced = useDebouncedCallback((value) => {
        getVendor({
            variables: {
                id: value,
            },
        })
    }, 800)

    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                py: 8,
                px: 4,
            }}
        >
            <Card>
                <Typography variant="h4" sx={{ my: 2, mx: 2 }}>
                    Edit AP
                </Typography>
                <Box>
                    <Formik
                        {...formikConfig}
                        onSubmit={(values, { setSubmitting }) => {
                            handleInvoiceARUpdate(values, setSubmitting)
                        }}
                    >
                        {(formik) => (
                            <Form onSubmit={formik.handleSubmit}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ py: 2, px: 2 }}
                                >
                                    <Grid item xs={12} sm={3} md={1.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Invoice ID"
                                            name="id"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={2.5}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Date"
                                            name="createdAt"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={1.5}>
                                        <Field
                                            error={Boolean(
                                                formik.touched.vendorId &&
                                                    formik.errors.vendorId
                                            )}
                                            helperText={
                                                formik.touched.vendorId &&
                                                formik.errors.vendorId
                                            }
                                            as={TextField}
                                            label="Vendor ID"
                                            name="vendorId"
                                            fullWidth
                                            onChange={(event) => {
                                                formik.setFieldValue(
                                                    'vendorId',
                                                    event.target.value
                                                )
                                                debounced(event.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            fullWidth
                                            label="Vendor Name"
                                            value={
                                                vendorData?.getVendor
                                                    ?.companyName || ''
                                            }
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3.5}>
                                        <TextField
                                            fullWidth
                                            label="Company Address"
                                            value={
                                                vendorData?.getVendor
                                                    ?.primaryAddress || ''
                                            }
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Shipping Charges"
                                            name="shippingCharges"
                                            type="number"
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Invoice Total"
                                            name="invoiceTotal"
                                            type="number"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormikTextField
                                            formik={formik}
                                            label="Status"
                                            name="status"
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                fullWidth
                                                label="Grand Total"
                                                disabled
                                                value={NumberFormatter(
                                                    Number(
                                                        formik.values
                                                            .shippingCharges
                                                    ) +
                                                        Number(
                                                            formik.values
                                                                .invoiceTotal
                                                        ),
                                                    2
                                                )}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent="flex-end"
                                    >
                                        <Grid item xs={6} sm={4} md={3}>
                                            <Button
                                                color="error"
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                variant="outlined"
                                                fullWidth
                                                onClick={() => {
                                                    formik.resetForm()
                                                    navigate('/dashboard/ap')
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} sm={4} md={3}>
                                            <LoadingButton
                                                color="success"
                                                loadingPosition="end"
                                                loading={formik.isSubmitting}
                                                disabled={formik.isSubmitting}
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                fullWidth
                                            >
                                                {formik.isSubmitting
                                                    ? 'Submitting'
                                                    : 'Submit'}
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Card>
        </Box>
    )
}
