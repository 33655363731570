/* eslint-disable prettier/prettier */
import * as React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import { DashboardLayout } from '../Layouts/Dashboard/DashboardLayout'
import { CreateCustomer } from '../pages/Customer/CreateCustomer'
import Customers from '../pages/Customer/Customers'
import { EditCustomer } from '../pages/Customer/EditCustomer'
import Dashboard from '../pages/Dashboard/Dashbaord'
import { CreateEmployee } from '../pages/Employees/CreateEmployee'
import { EditEmployee } from '../pages/Employees/EditEmployee'
import { Employees } from '../pages/Employees/Employees'
import Login from '../pages/Login/Login'
import NotFound from '../pages/NotFound/NotFound'
import { CreatePurchaseOrder } from '../pages/PurchaseOrder/CreatePurchaseOrder'
import { EditPurchaseOrder } from '../pages/PurchaseOrder/EditPurchaseOrder'
import { PurchaseOrders } from '../pages/PurchaseOrder/PurchaseOrders'
import Vendors from '../pages/Vendors/Vendors'
import { CreateVendor } from '../pages/Vendors/CreateVendor'
import { EditVendor } from '../pages/Vendors/EditVendor'
import PrivateRoute,{ShopRoutes} from './PrivateRoute'
import Users from '../pages/Users/Users'
import { CreateUser } from '../pages/Users/CreateUser'
import { EditUser } from '../pages/Users/EditUsers'
import { CreateProduct } from '../pages/Products/CreateProduct'
import { Products } from '../pages/Products/Products'
import { EditProduct } from '../pages/Products/EditProduct'
import { CreateInventory } from '../pages/Inventory/CreateInventory'
import { Inventory } from '../pages/Inventory/Inventory'
import { EditInventory } from '../pages/Inventory/EditInventory'
import { Quotations } from '../pages/Quotation/Quotations'
import { CreateQuotation } from '../pages/Quotation/CreateQuotation'
import { EditQuotation } from '../pages/Quotation/EditQuotation'
import { CreateOrderAdmin } from '../pages/OrderAdmin/CreateOrderAdmin'
import { EditOrderAdmin } from '../pages/OrderAdmin/EditOrderAdmin'
import { OrdersAdmin } from '../pages/OrderAdmin/OrdersAdmin'
import { EditWorkOrder } from '../pages/WorkOrder/EditWorkOrder'
import { WorkOrders } from '../pages/WorkOrder/WorkOrders'
import { AR } from '../pages/AR/AR'
import {CreateInvoiceAR} from '../pages/AR/CreateAR'
import {EditAR} from '../pages/AR/EditAr'
import {Shipping} from '../pages/Shipping/Shipping'
import {AddShipping} from '../pages/Shipping/CreateShipping'
import {EditShipping} from '../pages/Shipping/EditShipping'
import {NonConformance} from '../pages/NonConformance/Conformance'
import {AddConformance} from '../pages/NonConformance/AddConformance'
import {EditConformance} from '../pages/NonConformance/EditConformance'
import { AP } from '../pages/AP/AP'
import { CreateInvoiceAP } from '../pages/AP/CreateAp'
import { EditAP } from '../pages/AP/EditAP'
import { Labels } from '../pages/Labels/Labels'
import {AddAttendance} from '../pages/Attendance/AddAttendance'
import {CheckAttendance} from '../pages/Attendance/CheckAttendance'
import { PackingSlip } from '../pages/PackingSlip/PackingSlip'

export default function Routes(): ReactNode {
    return useRoutes([
        {
            path: '/dashboard',
            element: <PrivateRoute navLink="/" component={DashboardLayout} />,
            children: [
                {
                    path: '',
                    element: <Dashboard />,
                },
                {
                    path: 'employee',
                    element: <CreateEmployee />,
                },
                {
                    path: 'employee/:id',
                    element: <EditEmployee />,
                },
                {
                    path: 'employees',
                    element: <Employees />,
                },
                {
                    path: 'customer',
                    element: <CreateCustomer />,
                },
                {
                    path: 'customer/:id',
                    element: <EditCustomer />,
                },
                {
                    path: 'customers',
                    element: <Customers />,
                },
                {
                    path: 'vendors',
                    element: <Vendors />,
                },
                {
                    path: 'vendor',
                    element: <CreateVendor />,
                },
                {
                    path: 'vendor/:id',
                    element: <EditVendor />,
                },
                {
                    path: 'users',
                    element: <Users />,
                },
                {
                    path: 'user',
                    element: <CreateUser />,
                },
                {
                    path: 'user/:id',
                    element: <EditUser />,
                },
                {
                    path: 'purchase-order',
                    element: <CreatePurchaseOrder />,
                },
                {
                    path: 'purchase-order/:id',
                    element: <EditPurchaseOrder />,
                },
                {
                    path: 'purchase-orders',
                    element: <PurchaseOrders />,
                },
                {
                    path: 'product',
                    element: <CreateProduct />,
                },
                {
                    path: 'products',
                    element: <Products />,
                },
                {
                    path: 'product/:id',
                    element: <EditProduct />,
                },
                {
                    path: 'inventory',
                    element: <CreateInventory />,
                },
                {
                    path: 'all-inventory',
                    element: <Inventory />,
                },
                {
                    path: 'inventory/:id',
                    element: <EditInventory />,
                },
                {
                    path: 'quotations',
                    element: <Quotations />,
                },
                {
                    path: 'quotation',
                    element: <CreateQuotation />,
                },
                {
                    path: 'quotation/:id',
                    element: <EditQuotation />,
                },
                {
                    path: 'orders-admin',
                    element: <OrdersAdmin />,
                },
                {
                    path: 'order-admin',
                    element: <CreateOrderAdmin />,
                },
                {
                    path: 'orders-admin/:id',
                    element: <EditOrderAdmin />,
                },
                {
                    path:'ar',
                    element:<AR/>
                },
                {
                    path:'new-ar',
                    element:<CreateInvoiceAR/>
                },
                {
                    path:'edit-ar/:id',
                    element:<EditAR/>
                },
                {
                    path : 'shipping',
                    element:<Shipping/>
                },
                {
                    path:'add-shipping',
                    element:<AddShipping/>
                },
                {
                    path:'edit-shipping/:id',
                    element:<EditShipping/>
                },
                {
                    path:'nonConformance',
                    element:<NonConformance/>
                },
                {
                    path:'add-nonConformance',
                    element:<AddConformance/>
                },
                {
                    path:'edit-nonConformance/:id',
                    element:<EditConformance/>
                },
                {
                    path:'AP',
                    element:<AP/>
                },
                {
                    path : 'new-ap',
                    element:<CreateInvoiceAP/>
                },
                {
                    path : 'edit-ap/:id',
                    element:<EditAP/>
                },
				{
                    path : 'labels',
                    element:<Labels />
				},
				{
                    path : 'packing-slip',
                    element:<PackingSlip />
				},
                {
                    path:'check-attendance',
                    element:<CheckAttendance/>
                }
            ],
        },
        {
            path: '/shop',
            element: <ShopRoutes navLink="/" component={DashboardLayout} />,
            children:[
                {
                    path: 'work-orders',
                    element: <WorkOrders />,
                },
                {
                    path:'add-attendance',
                    element:<AddAttendance/>
                },
                {
                    path: 'work-order/:id',
                    element: <EditWorkOrder />,
                },
                
            ]
        },
        { path: '404', element: <NotFound /> },
        {
            path: '/',
            element: <Login />,
        },
        {
            path: '*',
            element: <Navigate to="/404" />,
        },
    ])
}
