export const ChipColor = {
    OPEN: { backgroundColor: 'orange', color: 'white' },
    'IN PROGRESS': { backgroundColor: '#2196F3', color: 'white' },
    'READY TO SHIP': { backgroundColor: 'green', color: 'white' },
    'IN SHIPPING': { backgroundColor: '#1E5F74', color: 'white' },
    COMPLETED: { backgroundColor: '#C84B31', color: 'white' },
    PAUSED: { backgroundColor: '#eeee44', color: 'black' },
}
export const GreenChip = {
    backgroundColor: 'green',
    color: 'white',
}
