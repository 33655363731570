import { useQuery } from '@apollo/client'
import { Remove, Add, Search } from '@mui/icons-material'
import {
    Grid,
    IconButton,
    Button,
    Card,
    MenuItem,
    Autocomplete,
    TextField,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material'
import { FieldArray } from 'formik'
import _ from 'lodash'
import { useState, useEffect } from 'react'
import {
    queryGetAllProducts,
    queryGetInventoryByGrade,
} from '../../common/queries'
import { FormikDateField } from '../../components/Form/FormikDateField'
import { FormikArrayField } from '../../components/Form/FormikArrayFeild'
import { Modal } from '../../components/Modal/Modal'
import { getValue } from '../../components/Table/DynamicTable'
import { NumberFormatter } from '../../utils/NumberFormatter'

const columns = [
    { name: 'Inventory ID', key: 'id' },
    { name: 'Start Size', key: 'size' },
    { name: 'Heat Number', key: 'heatNo' },
    { name: 'Vendor Name', key: 'vendor.companyName' },
    { name: 'Product Name', key: 'product.name' },
]

const InventoryModal = ({
    orderAdminItem,
    inventoryData,
    formik,
    isModalOpen,
    setModalOpen,
    index,
}: any): ReactNode => {
    return (
        <Modal
            dialogTitle="Inventory"
            open={isModalOpen}
            maxWidth="sm"
            closeIcon
            onClose={() => {
                formik.setFieldValue(`orderAdminChs[${index}].startSize`, '')
                formik.setFieldValue(`orderAdminChs[${index}].heatNo`, '')
                setModalOpen(false)
            }}
        >
            <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={6}>
                    <FormikArrayField
                        formik={formik}
                        label="Start Size"
                        name={`orderAdminChs[${index}].startSize`}
                        value={orderAdminItem.startSize}
                        type="number"
                    />
                </Grid>

                <Grid item xs={6}>
                    <FormikArrayField
                        formik={formik}
                        label="Heat No"
                        name={`orderAdminChs[${index}].heatNo`}
                        value={orderAdminItem.heatNo}
                        type="string"
                    />
                </Grid>

                <Grid item container xs={12}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.key} align="left">
                                        {column.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {inventoryData &&
                                _.filter(inventoryData, (object) => {
                                    return (
                                        object.size
                                            .toString()
                                            .includes(
                                                orderAdminItem.startSize || ''
                                            ) &&
                                        object.heatNo
                                            .toUpperCase()
                                            .includes(
                                                orderAdminItem.heatNo || ''
                                            )
                                    )
                                }).map((item) => (
                                    <TableRow
                                        hover
                                        key={item.id as string}
                                        onClick={() => {
                                            formik.setFieldValue(
                                                `orderAdminChs[${index}].inventory`,
                                                item.id
                                            )
                                            formik.setFieldValue(
                                                `orderAdminChs[${index}].heatNo`,
                                                item.heatNo
                                            )
                                            formik.setFieldValue(
                                                `orderAdminChs[${index}].startSize`,
                                                item.size
                                            )
                                            setModalOpen(false)
                                        }}
                                    >
                                        {columns.map((column) => (
                                            <TableCell key={column.key}>
                                                {getValue(
                                                    item,
                                                    column.key.split('.')
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Modal>
    )
}

const OrderAdminRow = ({
    formik,
    orderAdminItem,
    index,
    remove,
    productData,
    productLoading,
}: {
    formik: any
    orderAdminItem: IOrderAdminChs
    index: number
    remove: any
    productData: any[]
    productLoading: boolean
}): ReactNode => {
    // const [selectedGrade, setSelectedGrade] = useState<any>({})

    // productData.forEach((itemGrade: any) => {
    //     if (orderAdminItem.grade === itemGrade.label) {
    //         setSelectedGrade(itemGrade)
    //     }
    // })
    const {
        data: inventoryData,
        // loading: inventoryLoading,
        refetch,
    } = useQuery(queryGetInventoryByGrade, {
        variables: {
            grade:
                (orderAdminItem.grade as any)?.label ||
                orderAdminItem.grade ||
                '',
        },
    })

    const [isModalOpen, setModalOpen] = useState<boolean>(false)

    const isGradeSelected =
        orderAdminItem.grade !== '' && orderAdminItem.grade !== undefined
    return (
        <Grid item container xs={12} alignItems="center">
            <Grid item xs={0.75}>
                <IconButton color="error" onClick={() => remove(index)}>
                    <Remove />
                </IconButton>
            </Grid>
            <Grid item key={orderAdminItem.id?.toString() || index} xs>
                <Card variant="outlined">
                    <Grid
                        item
                        container
                        alignItems="start"
                        spacing={1.5}
                        sx={{ py: 2, px: 1 }}
                    >
                        <Grid item sm={12} md={1}>
                            <FormikArrayField
                                formik={formik}
                                label="A or C"
                                name={`orderAdminChs[${index}].typeAorC`}
                                value={orderAdminItem.typeAorC || ''}
                                select
                            >
                                <MenuItem value="A">A</MenuItem>
                                <MenuItem value="C">C</MenuItem>
                            </FormikArrayField>
                        </Grid>
                        <Grid item sm={12} md={1}>
                            <FormikArrayField
                                formik={formik}
                                label="L or B"
                                name={`orderAdminChs[${index}].typeLorB`}
                                value={orderAdminItem.typeLorB || ''}
                                select
                            >
                                <MenuItem value="L">L</MenuItem>
                                <MenuItem value="B">B</MenuItem>
                            </FormikArrayField>
                        </Grid>
                        <Grid item sm={12} md={1.5}>
                            <FormikArrayField
                                formik={formik}
                                label="Quantity"
                                name={`orderAdminChs[${index}].quantity`}
                                type="number"
                                value={orderAdminItem.quantity}
                                onChange={(e) => {
                                    formik.handleChange(e)
                                    formik.setFieldValue(
                                        `orderAdminChs[${index}].total`,
                                        orderAdminItem.unitPrice *
                                            e.target.value
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item sm={12} md={1.5}>
                            <Autocomplete
                                options={productData}
                                autoSelect
                                disableClearable
                                value={orderAdminItem.grade}
                                loading={productLoading}
                                isOptionEqualToValue={(option, value) =>
                                    option.label === value
                                }
                                onChange={(event, newValue) => {
                                    formik.setFieldValue(
                                        `orderAdminChs[${index}].grade`,
                                        newValue.label
                                    )
                                    // setSelectedGrade(newValue)
                                    refetch({ grade: newValue.label })
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Grade" />
                                )}
                            />
                        </Grid>
                        {orderAdminItem.typeAorC !== 'C' && (
                            <Grid item sm={12} md={2}>
                                <InventoryModal
                                    formik={formik}
                                    isModalOpen={isModalOpen}
                                    setModalOpen={setModalOpen}
                                    inventoryData={
                                        inventoryData?.getInventoryByGrade
                                    }
                                    orderAdminItem={orderAdminItem}
                                    index={index}
                                />

                                <FormikArrayField
                                    formik={formik}
                                    label="Inventory ID"
                                    name={`orderAdminChs[${index}].inventory`}
                                    value={orderAdminItem.inventory as any}
                                    disabled
                                    shrinkLabel
                                    useSlowField
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment
                                                onClick={() =>
                                                    setModalOpen(true)
                                                }
                                                position="start"
                                            >
                                                <Tooltip
                                                    title={
                                                        isGradeSelected
                                                            ? 'Search Inventory'
                                                            : 'Select Grade First'
                                                    }
                                                >
                                                    <Search
                                                        color={
                                                            isGradeSelected
                                                                ? 'primary'
                                                                : 'disabled'
                                                        }
                                                    />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        )}

                        {orderAdminItem.typeAorC === 'C' && (
                            <Grid item sm={12} md={2}>
                                <FormikArrayField
                                    formik={formik}
                                    label="Inventory No"
                                    name={`orderAdminChs[${index}].inventoryNo`}
                                    value={orderAdminItem.inventoryNo}
                                />
                            </Grid>
                        )}

                        <Grid item sm={12} md={1.5}>
                            <FormikArrayField
                                formik={formik}
                                label="Heat No"
                                name={`orderAdminChs[${index}].heatNo`}
                                value={orderAdminItem.heatNo}
                                type="string"
                                disabled={orderAdminItem.typeAorC === 'A'}
                                shrinkLabel
                                useSlowField
                            />
                        </Grid>
                        <Grid item sm={12} md={1.5}>
                            <FormikArrayField
                                formik={formik}
                                label="Start Size"
                                name={`orderAdminChs[${index}].startSize`}
                                value={orderAdminItem.startSize}
                                type="number"
                                disabled={orderAdminItem.typeAorC === 'A'}
                                shrinkLabel
                                useSlowField
                            />
                        </Grid>

                        <Grid item sm={12} md={1.5}>
                            <FormikArrayField
                                formik={formik}
                                label="F.S High"
                                name={`orderAdminChs[${index}].finishSizeHigh`}
                                value={orderAdminItem.finishSizeHigh}
                                type="number"
                            />
                        </Grid>
                        <Grid item sm={12} md={1.5}>
                            <FormikArrayField
                                formik={formik}
                                label="F.S Low"
                                name={`orderAdminChs[${index}].finishSizeLow`}
                                value={orderAdminItem.finishSizeLow}
                                type="number"
                                step={0.01}
                            />
                        </Grid>
                        <Grid item md={1.5}>
                            <FormikArrayField
                                formik={formik}
                                label="Feet"
                                name={`orderAdminChs[${index}].feet`}
                                value={orderAdminItem.feet}
                                type="number"
                            />
                        </Grid>
                        <Grid item md={1}>
                            <FormikArrayField
                                formik={formik}
                                label="Inches"
                                name={`orderAdminChs[${index}].inches`}
                                value={orderAdminItem.inches}
                                type="number"
                            />
                        </Grid>

                        <Grid item sm={12} md={1.5}>
                            <FormikArrayField
                                formik={formik}
                                label="RMS"
                                name={`orderAdminChs[${index}].rms`}
                                value={orderAdminItem.rms}
                                type="number"
                            />
                        </Grid>

                        <Grid item sm={12} md={1.5}>
                            <FormikArrayField
                                formik={formik}
                                label="UM"
                                name={`orderAdminChs[${index}].um`}
                                value={orderAdminItem.um}
                                type="string"
                            />
                        </Grid>

                        <Grid item sm={12} md={1.5}>
                            <FormikArrayField
                                formik={formik}
                                label="Unit Price"
                                name={`orderAdminChs[${index}].unitPrice`}
                                value={orderAdminItem.unitPrice}
                                type="number"
                                onChange={(e) => {
                                    formik.handleChange(e)

                                    formik.setFieldValue(
                                        `orderAdminChs[${index}].total`,
                                        orderAdminItem.quantity * e.target.value
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item sm={12} md={1.5}>
                            <FormikArrayField
                                formik={formik}
                                label="Part No"
                                name={`orderAdminChs[${index}].partNumber`}
                                value={orderAdminItem.partNumber}
                            />
                        </Grid>
                        <Grid item sm={12} md={1.5}>
                            <FormikDateField
                                formik={formik}
                                label="Due Date"
                                name={`orderAdminChs[${index}].dueDate`}
                                value={orderAdminItem.dueDate}
                            />
                        </Grid>
                        <Grid item sm={12} md={2.5}>
                            <FormikArrayField
                                formik={formik}
                                label="Packaging"
                                name={`orderAdminChs[${index}].packaging`}
                                value={orderAdminItem.packaging}
                            />
                        </Grid>
                        <Grid item sm={12} md={7}>
                            <FormikArrayField
                                formik={formik}
                                label="Notes"
                                multiline
                                name={`orderAdminChs[${index}].notes`}
                                value={orderAdminItem.notes}
                            />
                        </Grid>
                        <Grid item sm={12} md={2.5}>
                            <FormikArrayField
                                formik={formik}
                                label="Total"
                                name={`orderAdminChs[${index}].total`}
                                value={NumberFormatter(orderAdminItem.total, 2)}
                                type="text"
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    )
}

export const OrderAdminItems = ({ formik }: { formik: any }): ReactNode => {
    const { data: allProducts, loading } = useQuery(queryGetAllProducts)
    const [data, setData] = useState<any>([])

    useEffect(() => {
        if (!loading && allProducts && allProducts?.getAllProducts) {
            const temp = []
            // eslint-disable-next-line array-callback-return
            allProducts?.getAllProducts.map((prod): void => {
                // eslint-disable-next-line array-callback-return
                prod?.grades.map((grade): void => {
                    temp.push({
                        label: grade.name,
                        product: prod.name,
                        unitWeight: prod.unitWeight,
                    })
                })
            })
            setData(temp)
        }
    }, [loading, allProducts])

    return (
        <Grid container item spacing={2}>
            <FieldArray name="orderAdminChs">
                {({ remove, push }) => (
                    <Grid container item spacing={1}>
                        {formik.values?.orderAdminChs?.map(
                            (orderAdminItem: IOrderAdminChs, index: number) => (
                                <OrderAdminRow
                                    formik={formik}
                                    orderAdminItem={orderAdminItem}
                                    index={index}
                                    remove={remove}
                                    productData={data}
                                    productLoading={loading}
                                />
                            )
                        )}
                        <Grid item xs={12}>
                            <Button
                                startIcon={<Add />}
                                disabled={formik.isSubmitting}
                                onClick={() =>
                                    push({
                                        typeAorC: '',
                                        typeLorB: '',
                                        quantity: '',
                                        grade: '',
                                        heatNo: '',
                                        dueDate: '',
                                        startSize: '',
                                        finishSizeHigh: '',
                                        finishSizeLow: '',
                                        rms: '',
                                        feet: '',
                                        inches: '',
                                        um: '',
                                        unitPrice: '',
                                        partNumber: '',
                                        total: '',
                                        packaging: '',
                                        notes: '',
                                    })
                                }
                            >
                                Add Item
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </FieldArray>
        </Grid>
    )
}
